import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility';
import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';


const AddYoga = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [audio, setAudio] = useState(null);
    const [video, setVideo] = useState(null);
    const [files, setFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const imageInput = useRef();
    const stepImageInput = useRef();
    const cropper = useRef();
    const [cropperModal, setCropperModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [steps, setSteps] = useState([{}]);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentFileType, setCurrentFileType] = useState("file");
    const [yogaType, setYogaType] = useState("");
    const [selectedLang, setSelectedLang] = useState([]);

    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values, audio);
            if (data?._id) {
                setLoading(true);
                let body = {
                    ...values,
                    communityId: data?._id,
                    alterLang: selectedLang,
                    token: user?.token
                }
                console.log('image file', file);
                if (file.substring(0, 4) == 'data') {
                    const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                    const temp = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", temp);
                    const imageRes = await upload("/community/image_upload", form);
                    body.image = imageRes?.data;
                }
                let url = "/community/";
                console.log('body after upload images', body);
                put(url, body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                if (!file) {
                    toast.error("Placeholder image is required!");
                    return;
                }
                setLoading(true);
                let image = "";
                const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const imageRes = await upload("/community/image_upload", form);
                image = imageRes?.data;
                console.log('url after upload images', image);
                let body = {
                    ...values,
                    token: user?.token,
                    image,
                    alterLang: selectedLang
                }
                console.log('body before adding wellness', body);
                let url = "/community/";
                console.log('body after upload images', body);
                post(url, body)
                    .then((json) => {
                        console.log('response from adding product', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('product added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        console.log("data ", data);
        if (data) {
            if (data?.image)
                setFile(data?.image);
            if (data?.alterLang?.length)
                setSelectedLang(data?.alterLang);
        }
    }, [data, tags]);

    const onChangeFile = async (e, type) => {
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const removeImage = (to, i) => {
        setFile(null);
        setCurrentFile(null);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        setFile(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleSelectLang = (code, lang) => {
        if (code != "en") {
            let temp = Object.assign([], selectedLang);
            let already = temp.findIndex(x => x.code == code);
            if (already == -1) {
                temp.push({ code: code, title: "", desc: "", lang });
            } else {
                temp.splice(already, 1);
            }
            setSelectedLang(temp);
        }
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={3} md={3} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 448, height: 252 }}>
                                                        <img
                                                            src={file ? file : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage()}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={3} md={3}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 216 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add Banner</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput}
                                                                // accept='image/*'
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>

                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={16 / 9}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>
                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter the community title"
                                            value={data?.title}
                                            className="form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="url"
                                            label="URL *"
                                            placeholder="Enter the community url"
                                            value={data?.url}
                                            className="form-control"
                                            type="url"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="desc"
                                            label="Description *"
                                            placeholder="Enter detailed description about this community"
                                            value={data?.desc}
                                            className="form-control"
                                            type="textarea"
                                            rows={5}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Label>Alternative Languages</Label>
                            <Row>
                                <div className='lang-checks mb-3'>
                                    {user?.languages?.map((item) => (
                                        <div className='check-group'>
                                            <label for={item?.title} class="container">
                                                {item?.title}
                                                <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {selectedLang?.map((item, index) => (
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"title" + item?.lang}
                                                    label={"Title in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter title in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                    required
                                                    value={item?.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name={"desc" + item?.lang}
                                                    label={"Description in " + item?.lang}
                                                    placeholder={"Enter detailed description about this community in " + item?.lang}
                                                    value={item?.desc}
                                                    className="form-control"
                                                    type="textarea"
                                                    rows={5}
                                                    onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <input type="file" id="file"
                                ref={stepImageInput}
                                // accept='image/*'
                                onChange={(e) => onChangeFile(e, 'step')}
                                style={{ display: "none" }} />
                            <hr></hr>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                <Button type="reset" style={{ border: 'none', backgroundColor: Utils.themeColor, marginRight: 20 }}
                                    onClick={() => { props.setCurrentData(null); props?.close(false) }}
                                >
                                    Cancel
                                </Button>
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddYoga.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddYoga)
)
