import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify'

const Tags = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);

  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    get("/quote/tag/list", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          setData(res?.data);
        } else
          toast.error(res?.error);
      })
      .catch(err => {
        toast.error('Something Went Wrong!');
        setLoading(false);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        name: 'Quotes',
        cell: (row) => <>
          <span title={row?.quote?.length}>{row?.quote?.length}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleValidSubmit = (e, v) => {
    let body = {
      ...v,
      alterTitle: selectedLang,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, tagId: currentData?._id }
      put("/quote/tag/update", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/quote/tag/add", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    if (row?.alterTitle?.length) {
      let temp = [];
      row?.alterTitle?.forEach(element => {
        user?.languages?.forEach((item) => {
          if (element?.code == item?.code)
            temp.push({ ...element, lang: item?.title });
        })
      });
      setSelectedLang(temp);
    }
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      tagId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/quote/tag/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      tagId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `/quote/tag/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index].title = val;
    setSelectedLang(temp);
  }

  console.log("selected Lang", selectedLang);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Quotes Tags" />
        <Modal isOpen={isAddForm} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData ? `Update Tag!` : `Add New Tag!`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v, 'bank')
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label="Title"
                        className="form-control"
                        placeholder="Enter tag title"
                        type="text"
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                </Row>
                <Label>Alternative Languages</Label>
                <Row>
                  <div className='lang-checks'>
                    {user?.languages?.map((item) => (
                      <div className='check-group'>
                        <label for={item?.title} class="container">
                          {item?.title}
                          <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      // <AvField type="checkbox" name={item?.title} value={selectedLang.findIndex(x => x.code == item?.code) != -1} label={item?.title} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                    ))}
                  </div>
                  {selectedLang?.map((item, index) => (
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name={"title" + item?.lang}
                          label={"Title in " + item?.lang}
                          className="form-control"
                          placeholder={"Enter tag title in " + item?.lang}
                          type="text"
                          onChange={(e) => handleAlterTitle(index, e.target.value)}
                          required
                          value={item?.title}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setIsAddForm(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                      <div>
                        <CardTitle className="h4">Tags</CardTitle>
                        <CardSubtitle className="mb-3">
                          All Tags you have added before.
                        </CardSubtitle>
                      </div>
                      {user?.role == 'Admin' &&
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                      }
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                      conditionalRowStyles={[{
                        when: row => row?.seen?.length < 1,
                        style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                      }]}
                    // onRowClicked={(r, e) => seenNoti(r)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Tags)
)