import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label } from 'reactstrap';
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import moment from 'moment/moment';

const EarningChart = ({ data, title, image }) => {
    const [seriesData, setSeriesData] = useState([]);

    const typeColorMapping = {
        Video: '#34c38f',
        Audio: '#556ee6',
        Text: '#f46a6a',
        Info: '#a6c1d2'
    };
    useEffect(() => {
        if (data?.length) {
            const tempSeries = {
                Video: [],
                Audio: [],
                Text: [],
                Info: []
            };

            const categories = [];

            data.forEach((element) => {
                const { type, title, views } = element;

                if (!categories.includes(title)) {
                    categories.push(title);
                }

                if (tempSeries[type]) {
                    tempSeries[type].push({
                        x: title,
                        y: views
                    });
                }
            });

            // Formatting the series data for the chart
            const formattedSeriesData = Object.keys(tempSeries).map(key => ({
                name: key,
                data: tempSeries[key]
            }));

            setSeriesData(formattedSeriesData);
        }
    }, [data]);

    const options = {
        chart: {
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                barHeight: '24%',
                endingShape: 'rounded',
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: Object.values(typeColorMapping), // Array of colors for each series
        xaxis: {
            categories: seriesData.length ? seriesData[0].data.map(item => item.x) : [], // x-axis categories based on the first series
            labels: {
                formatter: function (value) {
                    // Find the series with the corresponding x value and return its y value
                    // const seriesIndex = seriesData.findIndex(series => series.data.some(item => item.x === value));
                    // const dataIndex = seriesData[seriesIndex]?.data.findIndex(item => item.x === value);
                    // return seriesData[seriesIndex]?.data[dataIndex]?.y || 0;
                    return ""
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return `${val} Users`;
                }
            },
            x: {
                formatter: function (value) {
                    return value;
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Card className='card-section-dashboard'>
                <CardBody>
                    <CardTitle className="h4 mb-4">
                        <div className="col-12">
                            <div className="cat_icon">
                                <img src={image} alt="Content Icon" />
                            </div>
                            <p className="mb-2">{title} ({Number(data?.length)?.toLocaleString()})</p>
                        </div>
                    </CardTitle>
                    <ReactApexChart
                        options={options}
                        series={seriesData}
                        type="bar"
                        height="350"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default EarningChart;