import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Card, Alert, CardBody, Button, Modal, ModalBody, Label } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// import Utils from '../../Utility';
import Utils from '../Utility';

// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';



const AddProperty = props => {
    const { user, data, setLoading, loading, role, getData, setIsAdd, currentData, setCurrentData, thumb, setThumb, file, setFile, setBanners, banners, setTag, tag } = props;
    // const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const imageInput = useRef();
    const cropper = useRef();
    const [descContent, setDescContent] = useState(null);
    // const [banners, setBanners] = useState([]);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [fileType, setFileType] = useState('thumb');
    const cropperRef = useRef();
    const imagePickerRef = useRef();
    const [catSet, setCatSet] = useState(undefined);
    const [cats, setCats] = useState([]);
    // const [select, setSelect] = useState([]);
    // const []

    // console.log("banner", banners);

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            // if (banners?.length < 1) {
            //     toast.error(`Please select atleast one banner Image!`);
            //     return;
            // }
            if (!thumb) {
                toast.error(`Please select a Thumb Image!`);
                return;
            }
            // if (selectedTags.length < 1) {
            //     toast.error('Select at least one Tags');
            //     return;
            // }
            let arr = catSet?.map((item) => {
                return item?.id
            })
            setLoading(true);
            let body = {
                ...v,
                tagId: arr,
                desc,
                isNewscheck: checkbox,
                token: user?.token
            }
            let temp = [];
            await Promise.all(banners.map(async (item) => {
                if (item?.substring(0, 4) == 'data') {
                    const obj = await urltoFile(item, new Date().getTime() + '.webp', 'image/webp');
                    const tempObj = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", tempObj);
                    const uploadRes = await upload("/blog/image_upload", form);
                    if (uploadRes?.statusCode == 200)
                        temp.push(uploadRes.data);
                }
                else {
                    temp.push(item);
                }
            }));
            if (thumb?.substring(0, 4) == 'data') {
                const obj = await urltoFile(thumb, new Date().getTime() + '.webp', 'image/webp');
                const form = new FormData();
                form.append("image", obj);
                const uploadRes = await upload("/blog/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    body = { ...body, thumb: uploadRes?.data };
            }
            body = {
                ...body, images: temp, ogImage: temp?.length > 0 ? temp[0] : "", seoImage: temp?.length > 0 ? temp[0] : ""
            };
            console.log("check body=====>", body);
            // return
            if (currentData?._id) {
                body = {
                    ...body,
                    blogId: currentData?._id
                }
                put("/blog", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            closeTab()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {

                post("/blog", body)
                    .then(res => {
                        console.log("check blog data submit res=====>", res);
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            closeTab()
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding blog", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const closeTab = () => {
        props.setCurrentData(null);
        changeImage();
        setBanners([]);
        setThumb(null);
        props?.close(false)
    }

    useEffect(() => {
        // console.log("current datassss=====>", currentData);
        if (currentData?.images?.length)
            setBanners(currentData?.images)
    }, [])

    useEffect(() => {
        // console.log("checkbox data =====>", currentData);

        if (currentData) {
            // if (currentData?.allTags) {
            //     let temp = [];
            //     currentData?.allTags?.map((item) => (
            //         temp?.push({ name: item?.title, id: item?._id })
            //     ))
            //     setTag(temp);
            // }
            setSelectedTags(currentData?.allTags)
            if (currentData?.desc) {
                const contentBlock = htmlToDraft(currentData?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(currentData?.desc);
                }
            }
            if (currentData?.images) {
                setFiles(currentData?.images);
            }
            if (currentData?.thumb) {
                setThumb(currentData?.thumb);
            }
            if (currentData?.checkbox) {
                setCheckbox(currentData?.checkbox)
            }
        }
    }, [currentData]);

    useEffect(() => {
        getTag();
    }, [user])

    const getTag = () => {
        get("/blog/cat/drop", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200) {
                    setCats(res?.data);
                }
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
            });
        get("/tags", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200) {
                    let temp = [];
                    res?.data?.map((item) => (
                        temp?.push({ name: item?.title, id: item?._id })
                    ))
                    setTag(temp);
                }
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
            });
    }

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const removeImage = (i) => {
        let data = Object.assign([], files);
        data.splice(i, 1);
        setFiles(data);
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const cropImage = () => {
        setLoading(true);
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (fileType == "banner") {
            let temp = Object.assign([], banners);
            temp.push(fileData);
            setBanners(temp);
        } else {
            setThumb(fileData);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const handleRemoveBanner = (i) => {
        let temp = Object.assign([], banners);
        temp.splice(i, 1);
        setBanners(temp);
    }

    const handleChangeInput = (list) => {
        let temp = []
        list?.map((item) => (
            temp.push({ id: item?.id, name: item?.name })
        ))
        setCatSet(temp)
        console.log("ddad tags", list);
        setSelectedTags(temp);
    }

    function changeImage() {
        setThumb(null);
        if (currentData) {
            props.setCurrentData({ ...currentData, thumb: undefined })
        }
    }

    const toolbarOptions = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        inline: {
            inDropdown: true,
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Courier New', 'Comic Sans MS', 'Lucida Console', 'Montserrat'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
    };

    return (
        <React.Fragment>
            <div>
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody>
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 200, width: 400 }}
                                        // aspectRatio={fileType == 'banner' ? 2 / 1 : 'auto' / 'auto'}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropperRef}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                style={{ border: 'none', backgroundColor: Utils.themeColor, color: Utils.textColor }}
                                                className="btn w-100 waves-effect waves-light"
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor, color: Utils.textColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <input
                    name="images"
                    label="image"
                    className="d-none"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imagePickerRef}
                />
                <h4 className="card-title mb-4">{currentData ? 'Update ' + role : 'Add a ' + role}</h4>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={12} md={12}>
                                <div className="text-center close_button" style={{ marginLeft: 'auto', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                    <button color="primary" className='btn btn' onClick={() => { closeTab() }} style={{ backgroundColor: Utils.themeColor, border: 'none', color: Utils.textColor }}>
                                        Close
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                            <Row>

                                <Label>Thumb</Label><br />
                                <Col md={2} className='mb-4'>
                                    {thumb || currentData?.thumb ?
                                        <div className='img-sqr-100'>
                                            <img src={currentData?.thumb ? currentData?.thumb : thumb} style={{ width: '100%', height: '100%' }} />
                                            <div className='btn_cross btn_cross_btn'>
                                                <button type="button" style={{ backgroundColor: Utils.themeColor, color: Utils.textColor }} className="btn btn-add" onClick={() => { setCurrentData({ ...currentData, thumb: null }); setThumb(null); setFile(null); }}><i className="mdi mdi-close" /></button>
                                            </div>
                                        </div>
                                        :
                                        <Col md={2}>
                                            <div className='img-place-sqr-100'>
                                                <div className='action-btn'>
                                                    <button type="button" className="btn btn-add" style={{ backgroundColor: Utils.themeColor, color: Utils.textColor }} onClick={() => { setFileType("thumb"); imagePickerRef.current.click() }}><i className={'mdi mdi-plus'} /></button>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Col>
                                <Col md={4}>
                                    <Label className='image-lebel'>Image</Label>
                                    {banners?.map((item, index) => (

                                        <div className='img-banner' style={{ border: '1px solid red', position: 'relative' }}>
                                            <img src={item} style={{ width: '100%', height: '100%' }} />
                                            <div className='btn_cross_banner' style={{ position: 'absolute', top: -20, right: -20 }}>
                                                <button type="button" className="btn btn-add" style={{
                                                    backgroundColor: Utils.themeColor, color: Utils.textColor, fontSize: 20,
                                                }} onClick={() => { handleRemoveBanner(index); }}><i className="mdi mdi-close" /></button>
                                            </div>
                                        </div>
                                    ))}
                                    {banners?.length < 1 &&
                                        <div className='img-place-banner'>
                                            <div className='action-btn'>
                                                <button type="button" className="btn btn-add" style={{ backgroundColor: Utils.themeColor, color: Utils.textColor }} onClick={() => { setFileType("banner"); setFile(null); imagePickerRef.current.click() }}><i className={'mdi mdi-plus'} /></button>
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={6}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="title"
                                                    label="Title *"
                                                    value={currentData?.title}
                                                    className="form-control"
                                                    placeholder="Enter blog title."
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="url"
                                                    label="Url *"
                                                    value={currentData?.url ? String(currentData?.url).toLowerCase() : ""}
                                                    className="form-control"
                                                    placeholder="Enter blog url."
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        < Col md={12}>
                                            <div className="mb-3">
                                                <label>Select Tags *</label>
                                                <Multiselect
                                                    options={tag}
                                                    onSelect={(selectedList, selectedItem) => handleChangeInput(selectedList)}
                                                    onRemove={(selectedList, removedItem) => handleChangeInput(selectedList)}
                                                    displayValue="name"
                                                    // selectionLimit={4}
                                                    placeholder={"Select tags"}
                                                    selectedValues={selectedTags}
                                                    showCheckbox
                                                // name="Opportunities"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="catId"
                                                    label="Category *"
                                                    value={currentData?.catId}
                                                    className="form-control"
                                                    type="select"
                                                    required
                                                >
                                                    <option value="">Select Category</option>
                                                    {cats?.map((item) => (
                                                        <option value={item?._id}>{item?.title}</option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="titlesort"
                                            label="Sort Description*"
                                            value={currentData?.titlesort}
                                            className="form-control"
                                            placeholder="Enter sort description."
                                            type="textarea"
                                            rows={2}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="form-group mb-4">
                                    {/* How it works */}
                                    <label>Description*</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => onEditorStateChange(e)}
                                        editorState={descContent}
                                        toolbar={toolbarOptions}
                                    />
                                </div>

                            </Row>

                            <div className='divider'></div>
                            <h4 style={{ textAlign: 'center' }}><br /> SEO Purpose</h4>

                            <Row>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name='seoTitle'
                                            label='Title'
                                            value={currentData?.seoTitle}
                                            placeholder='Enter Title'
                                            className="form-control"
                                            type='text'
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='metaKey'
                                            label='Meta Keyword'
                                            value={currentData?.metaKey}
                                            placeholder='Enter meta keyword'
                                            className="form-control"
                                            // required
                                            type='text'
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="ogurl"
                                            label={"Robots URL"}
                                            value={currentData?.ogurl}
                                            className="form-control"
                                            placeholder={`Enter Url`}
                                            type="url"
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='metaDesc'
                                            label='Meta Description'
                                            value={currentData?.metaDesc}
                                            placeholder='Enter meta description'
                                            className='form-control'
                                            type='textarea'
                                            row={2}
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='ogType'
                                            label='OG Type'
                                            value={currentData?.ogType}
                                            placeholder='Enter type'
                                            className='form-control'
                                            type='text'
                                        />
                                    </div>
                                </Col>
                            </Row>

                            {/* Geograph */}
                            {/* <h4 style={{ textAlign: 'center' }}>Geograph</h4> */}
                            <Row>
                                {/* <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='ogTitle'
                                            label='OG Title'
                                            value={currentData?.ogTitle}
                                            placeholder='Enter title'
                                            className='form-control'
                                            type='text'
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="ogurl"
                                            label={"OG URL"}
                                            value={currentData?.ogurl}
                                            className="form-control"
                                            placeholder={`Enter url`}
                                            type="url"
                                        />
                                    </div>
                                </Col> */}

                                {/* <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='ogLocation'
                                            label='OG Location'
                                            value={currentData?.ogLocation}
                                            placeholder='Enter location'
                                            className='form-control'
                                            type='text'
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='ogDesc'
                                            label='OG Description'
                                            value={currentData?.ogDesc}
                                            placeholder='Enter description'
                                            className='form-control'
                                            type='textarea'
                                            row={2}
                                            required
                                        />
                                    </div>
                                </Col> */}
                            </Row>

                            {/* <h4 style={{ textAlign: 'center' }}>Truth Used</h4> */}

                            <Row>
                                {/* <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='seoTitle'
                                            label='Title'
                                            value={currentData?.seoTitle}
                                            placeholder='Enter title'
                                            className='form-control'
                                            type='text'
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='seoCondTag'
                                            label='Conditional Tag'
                                            value={currentData?.seoCondTag}
                                            placeholder='Enter conditional tag'
                                            className='form-control'
                                            type='text'
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='form-group mb-4'>
                                        <AvField
                                            name='seoDesc'
                                            label='Description'
                                            value={currentData?.seoDesc}
                                            placeholder='Enter description'
                                            className='form-control'
                                            type='textarea'
                                            row={2}
                                        // required
                                        />
                                    </div>
                                </Col> */}
                            </Row>

                            <div className="text-center mt-4">
                                {currentData ?
                                    <button className='btn' type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor, color: Utils.textColor }}>
                                        Update {role}
                                    </button>
                                    :
                                    <button className='btn' type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor, color: Utils.textColor }}>
                                        Add {role}
                                    </button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
