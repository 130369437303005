import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

//import images
import avatar1 from '../../assets/images/users/avatar-3.jpg'
import avatar2 from '../../assets/images/users/avatar-4.jpg'
import avatar3 from '../../assets/images/users/avatar-5.jpg'
import avatar4 from '../../assets/images/users/avatar-6.jpg'
import moment from 'moment';

const Overview = (props) => {
    const { data } = props;
    return (
        <React.Fragment>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Recent Notifications</h4>
                        <ul className="inbox-wid list-unstyled" style={{ paddingRight: 10 }}>
                            {data?.map((item, key) => (
                                <li className="inbox-list-item" key={key}>
                                    <Link to="#">
                                        <div className="d-flex align-items-start">
                                            <div className="me-3 align-self-center">
                                                <img src={item?.image ? item?.image : require("../../assets/images/logo-sm.png")} alt=""
                                                    className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-1 overflow-hidden">
                                                <h5 className="font-size-16 mb-1">{item.title}</h5>
                                                <p className="text-truncate mb-0"><span style={{ color: 'black', fontWeight: 'bold' }}>{item?.body}</span></p>
                                            </div>
                                            <div className="font-size-12 ms-auto">
                                                {moment(item?.createdAt).format("DD MMM, YYYY hh:mm a")}<br></br>
                                                <span style={{ float: 'right', fontWeight: "bold" }}>{item?.type} / {item?.status}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        {/* <div className="text-center">
                            <Link to="#" className="btn btn-primary btn-sm">Load more</Link>
                        </div> */}
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    )
}

export default Overview;