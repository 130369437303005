import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import UiDropdown from "../Ui/UiDropdown";
import SearchableDropdown from "../Ui/UiSearchableDropdown";

// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, setIsTable, singleId, setSingleId, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [proData, setProData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const imageInput = useRef();
  const [logo, setLogo] = useState(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [selectedPros, setSelectedPros] = useState([]);
  const logoInput = useRef();
  const cropper = useRef();
  const logoCropper = useRef();

  useEffect(() => {
    if (user) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    post("/screen/analytic", { token: user?.token, screenId: singleId })
      .then(res => {
        console.log('response from get properties', res);
        if (res?.statusCode == 200) {
          setData(res?.data[0]?.events);
          setCurrentUser(res?.data[0]);
        }
      })
      .catch(error => {
        console.log('error while getting properties on hotels', error);
      });
  }

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      setSelectedPros([]);
      setLogo(null);
      setFile(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    const data = Object.assign([], proData);
    let selected = [];
    data?.map((item) => {
      proj?.propertyId?.map((pro) => {
        if (pro == item?._id)
          selected.push({ _id: item?._id, name: item?.name });
      })
    });
    setSelectedPros(selected);
    setCurrentModalData(proj);
    setFile(proj?.banner);
    setLogo(proj?.logo);
    setImageChanged(false);
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentModalData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      hotelId: currentModalData?._id,
      token: user?.token
    }
    post(`/hotel/delete`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      hotelId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/hotel/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "png",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      if (selectedPros?.length < 1) {
        toast.error('Select atleast one Destination');
        return;
      }
      let properties = [];
      selectedPros.map((item) => {
        properties.push(item?._id);
      });
      let body = {
        ...values,
        propertyId: properties,
        // isLux: values?.isLux ? false : true,
        hotelId: currentModalData?._id,
        token: user?.token
      }
      if (logoChanged) {
        const logoData = logoCropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const logoObj = await urltoFile(logoData, new Date().getTime() + '.png', 'image/png')
        console.log('values', logoObj);
        const image = await resizeFile(logoObj);
        const form = new FormData();
        form.append("image", image);
        const uploadedLogo = await upload("/hotel/image_upload", form);
        console.log('response of logo', uploadedLogo);
        if (uploadedLogo?.statusCode == 200)
          body.logo = uploadedLogo?.data;
      }
      if (imageChanged) {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
        console.log('values 2', fileObj);
        const image2 = await resizeFile(fileObj);
        console.log('values 2 after resize', image2);
        const form2 = new FormData();
        form2.append("image", image2);
        const uploadedBanner = await upload("/hotel/image_upload", form2)
        console.log('response from image upload', uploadedBanner);
        if (uploadedBanner?.statusCode == 200)
          body.banner = uploadedBanner?.data;
      }
      put("/hotel/update", body)
        .then(json => {
          console.log('response from add month', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setLogo(null);
            setLogoChanged(false);
            setImageChanged(false);
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      if (selectedPros?.length < 1) {
        toast.error('Select atleast one Destination');
        return;
      }
      let properties = [];
      selectedPros.map((item) => {
        properties.push(item?._id);
      });
      let logo = "", banner = "";
      const logoData = logoCropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const logoObj = await urltoFile(logoData, new Date().getTime() + '.png', 'image/png')
      console.log('values', logoObj);
      const image = await resizeFile(logoObj);
      const form = new FormData();
      form.append("image", image);
      const uploadedLogo = await upload("/hotel/image_upload", form);
      console.log('response of logo', uploadedLogo);
      if (uploadedLogo?.statusCode == 200)
        logo = uploadedLogo?.data;

      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      console.log('values 2', fileObj);
      const image2 = await resizeFile(fileObj);
      console.log('values 2 after resize', image2);
      const form2 = new FormData();
      form2.append("image", image2);
      const uploadedBanner = await upload("/hotel/image_upload", form2)
      console.log('response from image upload', uploadedBanner);
      if (uploadedBanner?.statusCode == 200)
        banner = uploadedBanner?.data;
      const body = {
        ...values,
        propertyId: properties,
        logo: logo,
        banner: banner,
        token: user?.token
      }
      post("/hotel/add", body)
        .then(json => {
          console.log('response from add hotel', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setLogo(null);
            setLogoChanged(false);
            setImageChanged(false);
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImageChanged(true);
  }

  const onChangeLogo = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setLogo(URL.createObjectURL(e.target.files[0]));
    setLogoChanged(true);
  }

  const handlePropertyChange = (name) => {
    let temp = Object.assign([], proData);
    let selected = Object.assign([], selectedPros);
    const data = temp.find(x => x.name == name);
    const found = selected?.findIndex(x => x?._id == data?._id);
    console.log('found property', found);
    if (found == -1) {
      selected.push({ _id: data?._id, name: data?.name });
      setSelectedPros(selected);
    }
  }

  const handleRemoveProp = (index) => {
    let selected = Object.assign([], selectedPros);
    selected.splice(index, 1);
    setSelectedPros(selected);
  }

  console.log('selected pros', new Date(), Date.now());

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Modal className="modal_form" isOpen={isAddForm} toggle={() => { setIsAddForm(false); setFile(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? 'Update Hotel!' : 'Add New Hotel!'}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  {/* <div className="mb-3">
                    <AvField
                      name="propertyId"
                      label="Property"
                      className="form-control"
                      type="select"
                      required
                      value={currentModalData?.propertyId}
                    >
                      <option value="">Select Property</option>
                      {proData?.map((item) => (
                        <option value={item?._id}>{item?.name}</option>
                      ))}
                    </AvField>
                  </div> */}
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="mb-3">
                        <label>Destination</label>
                        <SearchableDropdown
                          className="form-control"
                          style={{ width: '100%' }}
                          options={proData}
                          label="name"
                          id="_id"
                          // selectedVal={value}
                          handleChange={(val) => handlePropertyChange(val)}
                        />
                      </div>
                      {selectedPros?.length > 0 &&
                        <div className="d-flex mb-4 flex-wrap">
                          {selectedPros.map((item, index) => {
                            return (
                              <div style={{ border: `1px solid ${Utils.themeColor}`, marginRight: 10, display: 'flex', marginBottom: 15 }}>
                                <span style={{ padding: 5 }}>{item?.name}</span>
                                <div style={{ backgroundColor: Utils.themeColor, height: '100%', width: 20, textAlign: 'center', cursor: 'pointer' }}
                                  onClick={() => handleRemoveProp(index)}
                                >
                                  <span style={{ color: 'white', verticalAlign: 'sub' }} >X</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      }
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form_group_box">
                        <AvField
                          name="isLux"
                          label="Is Luxury"
                          className="form-control"
                          type="checkbox"
                          value={currentModalData?.isLux}
                          onChange={(e) => console.log(e.target.value)}
                          style={{ width: '20px', height: '25px', marginRight: '10px' }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Name"
                          className="form-control"
                          placeholder="Enter name"
                          type="text"
                          required
                          value={currentModalData?.name}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-3">
                        <AvField
                          name="city"
                          label="City"
                          className="form-control"
                          placeholder="Enter city name"
                          type="text"
                          required
                          value={currentModalData?.city}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        {logo ?
                          <>
                            <CardTitle >Logo</CardTitle>
                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                              {currentModalData?.logo ?
                                <img src={logo} style={{ width: 600, height: 'auto' }} />
                                :
                                <Cropper
                                  style={{ height: 'auto', width: 600 }}
                                  // aspectRatio={1 / 1}
                                  preview=".img-preview"
                                  guides={true}
                                  src={logo}
                                  ref={logoCropper}
                                />
                              }
                              <i className="mdi mdi-close" style={{
                                color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                              }}
                                onClick={() => { setLogo(null); if (currentModalData) setCurrentModalData({ ...currentModalData, logo: undefined }) }}
                              />
                            </div>
                          </>
                          :
                          <AvField
                            name="logoInput"
                            label="Logo"
                            className="form-control"
                            placeholder="upload bank logo"
                            type="file"
                            onChange={e => onChangeLogo(e)}
                            required
                            multiple={false}
                            ref={logoInput}
                            accept="image/png, image/jpeg"
                          // value={currentModalData?.logo}
                          />
                        }
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-3 mt-2">
                        {file ?
                          <>
                            <CardTitle >Image</CardTitle>
                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                              {currentModalData?.banner ?
                                <img src={file} style={{ width: 400, height: 'auto' }} />
                                :
                                <Cropper
                                  style={{ height: 'auto', width: 400 }}
                                  aspectRatio={16 / 5}
                                  preview=".img-preview"
                                  guides={true}
                                  src={file}
                                  ref={cropper}
                                />
                              }
                              <i className="mdi mdi-close" style={{
                                color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                              }}
                                onClick={() => { setFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, banner: undefined }) }}
                              />
                            </div>
                          </>
                          :
                          <AvField
                            name="fileInput"
                            label="Image"
                            className="form-control"
                            placeholder="upload bank logo"
                            type="file"
                            onChange={e => onChangeFile(e)}
                            required
                            multiple={false}
                            ref={imageInput}
                            accept="image/png, image/jpeg"
                          // value={currentModalData?.logo}
                          />
                        }
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-3">
                        <AvField
                          name="utm"
                          label="Booking UTM"
                          className="form-control"
                          placeholder="Enter Booking UTM URL"
                          type="url"
                          value={currentModalData?.utm}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-3">
                        <AvField
                          name="desc"
                          label="Overview"
                          className="form-control"
                          placeholder="Enter overview"
                          type="textarea"
                          row={2}
                          value={currentModalData?.desc}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor }}
                              onClick={() => { setIsAddForm(false); setFile(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor }}
                            >
                              {currentModalData ? 'Update' : 'Add'}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>


                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardSubtitle className="">
                  <Row lg={12}>
                    <Col lg={2} style={{ width: 120 }}>
                      <img src={currentUser?.profile_picture ? currentUser?.profile_picture : require("../../assets/images/om.png")} style={{ width: 100, height: 100, borderRadius: 50 }} />
                    </Col>
                    <Col lg={4} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 0 }}>{currentUser?.title}</p>
                      <p style={{}}>( Visitors: {currentUser?.navigators[0]?.count} )</p>
                    </Col>
                  </Row>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
          {data?.map((item) => (
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    {currentUser?.for == 'wallpaper' ?
                      <img src={"https://eeasy.s3.ap-south-1.amazonaws.com/balaji/app" + item?._id} style={{width:'100%'}} />
                      :
                      <h6>{item?._id}</h6>
                    }
                    <div style={{ marginTop: 20 }}>
                      {item?.registered > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Registered : {item?.registered}</p>
                        </div>
                      }
                      {item?.unregistered > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#c94c4c', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Unregistered : {item?.unregistered}</p>
                        </div>
                      }
                      {item?.unregistered + item?.registered > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#36486b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Total : {item?.unregistered + item?.registered}</p>
                        </div>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

      </div>

    </React.Fragment >
  )
}

export default Table;
