import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import './Card.scss';
import { toast } from "react-toastify";

const NotificationTable = (props) => {
  const { user, role, setLoading, loading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);

  useEffect(() => {
    if (user)
      getData();
  }, [user]);

  const getData = () => {
    console.log('calling get user list get method');
    get("/screen/list", { token: user?.token })
      .then(json => {
        console.log('response from get user list', json);
        if (json.statusCode == 200) {
          setData(json?.data);
          // getDataSuccess([{ count: json?.unseen }]);
        }
      })
      .catch(error => {
        console.log('error while getting user list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Title',
        selector: row => row.title,
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button className="table_action_btn"
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-finance" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      const body = {
        ...values,
        screenId: currentModalData?._id,
        token: user?.token
      }
      put("/screen/update", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      const body = {
        ...values,
        token: user?.token
      }
      post("/screen/add", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setIsAddForm(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('deleting proj', proj);
    setCurrentModalData(proj);
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    post("/screen/delete", { screenId: currentModalData?._id, token: user?.token })
      .then(json => {
        console.log('response from delete noti', json);
        if (json.statusCode == 200) {
          getData();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting noti', error);
      })
  }


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter screen name"
                          type="text"
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="for"
                          label="For"
                          className="form-control"
                          placeholder="Enter internal screen name"
                          type="text"
                          required
                          value={currentModalData?.for}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      All {role} you have added before. <code>Analytics Data</code> will work according to these screens.
                    </CardSubtitle>
                  </div>
                  {user?.role == 'Admin' &&
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                  }
                </div>
                {/* <div className="d-flex">

                  {data?.map((item, index) => {
                    return (
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>{item?.name}</p>
                            <span>{item?.intro}</span>
                          </div>
                          <div class="flip-card-back" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>Leads</p>
                            <span>Open : 10</span>
                            <span>Close : 5</span>
                            <span>Rejected : 2</span>
                            <div className="action">
                              <Button onClick={()=>props?.history.push("/lead/project/"+item?._id)}><i className="mdi mdi-eye"></i></Button>
                              <Button onClick={()=>handleDeleteProj(item)}><i className="mdi mdi-trash-can"></i></Button>
                              <Button onClick={()=>handleUpdateProj(item)}><i className="mdi mdi-pencil"></i></Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div> */}
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationPerPage={100}
                  paginationRowsPerPageOptions={[100, 200, 300, 400]}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                // conditionalRowStyles={[{
                //   when: row => row?.seen?.length < 1,
                //   style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                // }]}
                // onRowClicked={(r, e) => seenNoti(r)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

// export default NotificationTable;

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

export default withRouter(connect(mapStateToProps, {})(NotificationTable));
