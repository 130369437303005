import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Progress, Col } from 'reactstrap';

const MiniWidget = ({ users }) => {

    return (
        <React.Fragment>
            <Row className='dashboard-card-box-section'>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">All Paid Users</p>
                                    <h4 className="mb-0">{Number(users?.allUser).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value="100"
                                            color="success"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/users" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">Renewed</p>
                                    <h4 className="mb-0">{Number(users?.renewed).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.renewed) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="primary"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/unregistered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">15 Days To Renew</p>
                                    <h4 className="mb-0">{Number(users?.renewed15).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.renewed15) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="warning"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/unregistered-to-registered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">5 Days To Renew</p>
                                    <h4 className="mb-0">{Number(users?.renewed5).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.renewed5) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="success"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/registered" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">5 Days did not Renewed</p>
                                    <h4 className="mb-0">{Number(users?.renewedDue5).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.renewedDue5) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="info"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/registered-to-paid" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={2}>
                    <Card className='card-section-dashboard' style={{ height: '100%' }}>
                        <CardBody>
                            <Row className="align-items-center">
                                <div className="col-12">
                                    <p className="mb-2">15 Days did not Renewed</p>
                                    <h4 className="mb-0">{Number(users?.renewedDue15).toLocaleString()}</h4>
                                </div>
                                <div>
                                    <div className="mt-1 mb-3">
                                        <Progress
                                            value={String(((Number(users?.renewedDue15) * 100) / Number(users?.allUser)).toFixed(0))}
                                            color="secondary"
                                            className="bg-transparent progress-sm"
                                            size="sm"
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Link to="/user/paid" className="btn btn_bg btn-sm">View <i className="mdi mdi-arrow-right ms-1" /></Link>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MiniWidget;