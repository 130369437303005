import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const RadialChart = ({ title, data }) => {

  const [counts, setCounts] = useState([]);
  const [labels, setLabels] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data?.length) {
      let video = 0, audio = 0, text = 0, info = 0, total = 0;
      data?.forEach(element => {
        total += element?.views;
        if (element?.type == "Video")
          video += element?.views;
        if (element?.type == "Audio")
          audio += element?.views;
        if (element?.type == "Text")
          text += element?.views;
        if (element?.type == "Info")
          info += element?.views;
      });
      setLabels(["Video", "Audio", "Text", "Info"]);
      setCounts([video, audio, text, info]);
      setTotal(total);
    }
  }, [data])

  const series = [44, 55, 67, 83]
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
            formatter: function (val) {
              // console.log("value ", val)
              return Number((val / 100) * total).toFixed(0);
            }
          },
          total: {
            show: true,
            label: title,
            formatter: function (w) {
              // console.log("watch total", w);
              return total
            }
          }
        }
      }
    },
    tooltip: {
      x: {
        formatter: function (value) {
          console.log("tooltip formatter", value)
          return `${value}`;
        }
      }
    },
    series: counts,
    labels: labels,
    colors: ['#3b5de7', '#45cb85', '#eeb902', '#ff715b'],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="370"
      className="apex-charts"
    />
  )
}

export default RadialChart
