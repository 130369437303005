import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { useHistory } from "react-router-dom";

const Breadcrumb = props => {

  const history = useHistory();

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box py-3 d-flex align-items-start align-items-center justify-content-between">
          {/* <h4 className="page-title mb-0 font-size-18">{props.breadcrumbItem}</h4> */}
          <div className="btn_back" onClick={() => history.goBack()}>
            <i className="mdi mdi-arrow-left" />
            <span>Back</span>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
          {/* <div className="right-section-breadcrumb">
            <button
              type="button"
              onClick={() => {
                toggleFullscreen();
              }}
              className="btn noti-icon waves-effect"
              data-toggle="fullscreen"
            >
              <i className="mdi mdi-fullscreen" style={{ color: 'white' }}></i>
            </button>
            <Link to="/logout" className="dropdown-item text-white">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-white"></i>{" "}
              <span>{'Logout'}</span>
            </Link>
          </div> */}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
