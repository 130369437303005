import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//page
import Users from "../pages/Users";
import UnregisteredUsers from "../pages/Users/Unregistered";
import UnregisteredToRegistered from "../pages/Users/UnregisteredToRegistered";
import RegisteredUsers from "../pages/Users/Registered";
import RegisteredToPaid from "../pages/Users/RegisteredToPaid";
import Paid from "../pages/Users/Paid";

import Weather from "../pages/Weather";
import Theme from "../pages/Theme";
import QuoteTag from "../pages/Quotes/tags";
import QuoteUniversal from "../pages/Quotes/universal";
import QuoteCountry from "../pages/Quotes/country";
import QuoteGeneral from "../pages/Quotes/general";
import Setting from "../pages/Setting";
import Screen from "../pages/Screen";
import Notification from "../pages/Notification";
import Wellness from "../pages/Wellness";
import HarmonyMood from "../pages/Harmony/Mood";
import SoundCat from "../pages/Harmony/Category";
import Sound from "../pages/Harmony/Sounds";
import Tags from "../pages/Tags";
import Category from "../pages/Category";
import Content from "../pages/Content";
import Yoga from "../pages/Yoga";
import Language from "../pages/Language";
import KnowYourselfCat from "../pages/KnowYourself/Category";
import KnowYourself from "../pages/KnowYourself";
import KnowYourselfIntro from "../pages/KnowYourself/Intro";
import Games from "../pages/Games";
import Community from "../pages/Community";
import TalkIntro from "../pages/Talk/Intro";
import TalkCate from "../pages/Talk/Category";
import TalkQues from "../pages/Talk/Ques";
import Talk from "../pages/Talk";
import Plans from "../pages/Plans";
import Store from "../pages/ImageStore";
import LiveSession from "../pages/LiveSession";
import Podcast from "../pages/Podcast";
import PodcastRequest from "../pages/Podcast/Request";
import Coupon from "../pages/Coupon";
import SocialMedia from "../pages/SocialMedia";
import SubAdmin from "../pages/SubAdmin";
import Mailer from "../pages/Mails";
import Anayltics from "../pages/Anayltics";
import Blog from "../pages/Blog";
import BlogCat from "../pages/Blog/Category";
import Sitemap from "../pages/Sitemap";
import Popup from "../pages/Popup";
import Renewed from "../pages/Users/Renewed";
import RenewIn15 from "../pages/Users/RenewIn15";
import RenewIn5 from "../pages/Users/RenewIn5";
import RenewDue5 from "../pages/Users/RenewDue5";
import RenewDue15 from "../pages/Users/RenewDue15";
import Dues from "../pages/Users/Dues";
import Guide from "../pages/Guide/Guide";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: '/dashboard2', component: Dashboard2 },
  { path: "/users", component: Users },
  { path: "/user/unregistered", component: UnregisteredUsers },
  { path: "/user/unregistered-to-registered", component: UnregisteredToRegistered },
  { path: "/user/registered", component: RegisteredUsers },
  { path: "/user/registered-to-paid", component: RegisteredToPaid },
  { path: "/user/paid", component: Paid },
  { path: "/user/renewed", component: Renewed },
  { path: "/user/renew-in-15", component: RenewIn15 },
  { path: "/user/renew-in-5", component: RenewIn5 },
  { path: "/user/renew-due-5", component: RenewDue5 },
  { path: "/user/renew-due-15", component: RenewDue15 },
  { path: "/user/all-paid", component: Dues },
  { path: "/weather", component: Weather },
  { path: "/theme", component: Theme },
  { path: "/wellness", component: Wellness },
  { path: "/quotes/tags", component: QuoteTag },
  { path: "/quotes/universal", component: QuoteUniversal },
  { path: "/quotes/country", component: QuoteCountry },
  { path: "/quotes/general", component: QuoteGeneral },
  { path: "/harmony_mood", component: HarmonyMood },
  { path: "/harmony_category", component: SoundCat },
  { path: "/harmony_sound", component: Sound },
  { path: "/tags", component: Tags },
  { path: "/popup", component: Popup },
  { path: "/know-yourself-category", component: KnowYourselfCat },
  { path: "/know-yourself-intro", component: KnowYourselfIntro },
  { path: "/know-yourself-question", component: KnowYourself },
  { path: "/category", component: Category },
  { path: "/content", component: Content },
  { path: "/yoga", component: Yoga },
  { path: "/games", component: Games },
  { path: "/communities", component: Community },
  { path: "/talk-intro", component: TalkIntro },
  { path: "/talk-category", component: TalkCate },
  { path: "/talk-ques", component: TalkQues },
  { path: "/talks", component: Talk },
  { path: "/live_session", component: LiveSession },
  { path: "/podcast", component: Podcast },
  { path: "/podcast_request", component: PodcastRequest },
  { path: "/language", component: Language },
  { path: '/plans', component: Plans },
  { path: '/coupon', component: Coupon },
  { path: '/store', component: Store },
  { path: "/screen", component: Screen },
  // { path: "/ram-naam", component: RamNaam },
  // { path: "/banner", component: Banner },
  // { path: "/notification-data", component: NotificationData },
  { path: "/setting", component: Setting },
  { path: "/sitemap", component: Sitemap },
  { path: "/social-media", component: SocialMedia },
  // { path: "/brand", component: Brand },
  // { path: "/category", component: Category },
  // { path: "/product", component: Product },
  // { path: "/property", component: Property },
  // { path: "/property/:id", component: Property },
  { path: "/anayltic", component: Anayltics },
  { path: "/anayltic/user/:id", component: Anayltics },
  { path: "/anayltic/screen/:id", component: Anayltics },
  // { path: "/ads", component: Ads },
  { path: "/blog", component: Blog },
  { path: "/blog-category", component: BlogCat },


  //Notification
  { path: "/notification", component: Notification },
  { path: "/sub-admin", component: SubAdmin },
  { path: "/mailer", component: Mailer },
  // //profile
  { path: "/profile", component: UserProfile },
  //guide
  { path: "/guide", component: Guide },

  // { path: "/panchang", component: Panchang },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }