import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import AddMood from './AddMood';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment'

const Moods = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const imageInput = useRef();

  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    if (!isAddForm)
      setCurrentData(null);
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/harmony/mood/list", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200)
            setData(res?.data);
          else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 15, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          {row?.image ?
            <img src={row?.image} style={{ width: 25, height: 25 }} alt={row?.title} />
            :
            <span></span>
          }
        </>,
        maxWidth: '100px'
      },
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[6].harmony?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[6].harmony?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleValidSubmit = (e, v) => {
    let body = {
      ...v,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, tagId: currentData?._id }
      put("/quote/tag/update", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/quote/tag/add", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...row, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      moodId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/harmony/mood/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      moodId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `/harmony/mood/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/harmony/mood/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Harmony Moods" />
        {isAddForm ?
          <AddMood
            role={"Mood"}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            getData={getData}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
          />
          :
          <Card>
            <CardBody>
              <Row>
                <DeleteModal
                  show={deleteModal}
                  onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                  onCloseClick={() => setDeleteModal(false)}
                  actionType={currentData?.actionType}
                  data={currentData}
                />
                <Col className="col-12">
                  
                      <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                        <div>
                          <CardTitle className="h4">Moods</CardTitle>
                          <CardSubtitle className="mb-3">
                            All Moods you have added before.
                          </CardSubtitle>
                        </div>
                        <div className="group_btn_box">
                          {user?.role == 'Admin' ?
                            <div className="text-center" style={{ marginLeft: 'auto' }}>
                              <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                                Add
                              </Button>
                            </div>
                            :
                            <div className="text-center" style={{ marginLeft: 'auto' }}>
                              {user?.permissions?.length && user?.permissions[6]?.harmony?.write &&
                                <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                                  Add
                                </Button>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="table">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={100}
                                paginationRowsPerPageOptions={[100, 200, 300, 400]}
                                customStyles={{
                                  headCells: {
                                    style: {
                                      color: 'black',
                                      fontWeight: 'bold',
                                      fontSize: 15,
                                      maxWidth: 80
                                    },
                                  },
                                  cells: {
                                    style: {
                                      maxWidth: 50
                                    }
                                  }
                                }}
                                conditionalRowStyles={[{
                                  when: row => row?.seen?.length < 1,
                                  style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                                }]}
                              // onRowClicked={(r, e) => seenNoti(r)}
                              />
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    
                </Col>
              </Row>
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Moods)
)