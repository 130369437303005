import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import AddQuote from "../Forms/AddQuote";
import moment from "moment";
import { AvField, AvForm } from "availity-reactstrap-validation";

const Tags = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");


  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    if (!isAddForm)
      setCurrentData(null);
    getData();
  }, [isAddForm]);

  const getData = () => {
    get("/quote/list?type=General", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200)
          setData(res?.data);
        else
          toast.error(res?.error);
      })
      .catch(err => {
        toast.error('Something Went Wrong!');
        setLoading(false);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          {row?.image ?
            <img src={row?.image} style={{ width: 60, height: 100 }} alt={row?.title} />
            :
            <span></span>
          }
        </>,
        maxWidth: "100px"
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Author',
        cell: (row) => <>
          <span title={row?.author}>{row.author ? row.author : "Unknown"}</span>
        </>,
        maxWidth: "170px"
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Quote',
        cell: (row) => <>
          <span title={row?.quote}>{row.quote}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        name: 'Likes',
        selector: row => row?.likes?.length,
        maxWidth: "100px"
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          {user?.role == 'Admin' ?
            <>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[2]?.quote?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[2]?.quote?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "130px"
      }
    ])
  }, [data]);

  const handleValidSubmit = (e, v) => {
    let body = {
      ...v,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, tagId: currentData?._id }
      put("/quote/tag/update", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/quote/tag/add", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...row, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      quoteId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/quote/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      quoteId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `/quote/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleSearch = (e, v, type) => {
    // console.log("handle Apply button", v);
    if (!loading) {
      setResultType(type);
      setLoading(true);
      let url = `/user/rtp?dateFrom=${v?.dateFrom}&dateTo=${v?.dateTo}&currentPage=0&type=Paid`;
      get(url, { token: user?.token })
        .then(json => {
          setLoading(false);
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setTotalPage(json?.totalPage);
            setSearchApplied(false);
          } else {
            setData([]);
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setData([]);
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="General Quotes" />
        {isAddForm ?
          <AddQuote
            role={"General"}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
          />
          :
          <Card>
            <CardBody>
              <Row>
                <DeleteModal
                  show={deleteModal}
                  onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                  onCloseClick={() => setDeleteModal(false)}
                  actionType={currentData?.actionType}
                  data={currentData}
                />
                <Col className="col-12">
                 
                      <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 10 }}>
                        <div>
                          <CardTitle className="h4">General Quotes</CardTitle>
                          <CardSubtitle className="mb-3">
                            All General Quotes you have added before. will be show randomaly after country quote.
                          </CardSubtitle>
                        </div>
                        <div className="group_btn_box">
                          {user?.role == 'Admin' ?
                            <div className="text-center" style={{ marginLeft: 'auto' }}>
                              <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                                Add
                              </Button>
                            </div>
                            :
                            <div className="text-center" style={{ marginLeft: 'auto' }}>
                              {user?.permissions?.length && user?.permissions[2]?.quote?.write &&
                                <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                                  Add
                                </Button>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      {/* <Row className="mt-2 mb-4">
                        <Col md={6}>
                          <AvForm onValidSubmit={handleSearch}>
                            <Row>
                              <Col md={5}>
                                <AvField
                                  name="dateFrom"
                                  label="Date From"
                                  placeholder="Search by name, email, mobile"
                                  type="date"
                                  value={dateFrom}
                                  onChange={(e) => setDateFrom(e.target.value)}
                                  validate={{ max: { value: new Date() } }}
                                  required
                                >
                                </AvField>
                              </Col>
                              <Col md={5}>
                                <AvField
                                  name="dateTo"
                                  label="Date To"
                                  placeholder="Search by name, email, mobile"
                                  type="date"
                                  value={dateTo}
                                  onChange={(e) => setDateTo(e.target.value)}
                                  validate={{ max: { value: new Date() } }}
                                  required
                                >
                                </AvField>
                              </Col>
                              <Col md={2}>
                                <div style={{ marginTop: 30 }}>
                                  <Button style={{ backgroundColor: Utils.themeColor, border: 'none', width: '100%' }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row> */}
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationPerPage={100}
                        paginationRowsPerPageOptions={[100, 200, 300, 400]}
                        customStyles={{
                          headCells: {
                            style: {
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 15,
                              maxWidth: 80
                            },
                          },
                          cells: {
                            style: {
                              maxWidth: 50
                            }
                          }
                        }}
                        conditionalRowStyles={[{
                          when: row => row?.seen?.length < 1,
                          style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                        }]}
                      // onRowClicked={(r, e) => seenNoti(r)}
                      />
                    
                </Col>
              </Row>
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Tags)
)