import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Multiselect from 'multiselect-react-dropdown'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

const Category = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [cropperModal, setCropperModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [cats, setCats] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const imageInput = useRef();
  const cropper = useRef();


  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/talk/intro", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
            setCurrentData(res?.data);
            setFile(res?.data?.image);
            if (res?.data?.alterLang)
              setSelectedLang(res?.data?.alterLang);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  const handleValidSubmit = async (e, v) => {
    console.log('values', v);
    if (!file) {
      toast.error("Please select the intro image!");
      return;
    }
    let body = {
      ...v,
      image: file,
      alterLang: selectedLang,
      token: user?.token
    }
    if (file.substring(0, 4) == 'data') {
      const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
      const temp = await resizeFile(obj);
      const form = new FormData();
      form.append("image", temp);
      const imageRes = await upload("/talk/image_upload", form);
      body.image = imageRes?.data;
    }
    setLoading(true);
    put("/talk/intro", body)
      .then((res) => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getData();
          setIsAddForm(false);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        toast.error(err);
        setLoading(false);
      })
  }

  const cropImage = (e, v) => {
    setLoading(true);
    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    setFile(fileData);
    setCropperModal(false);
    setLoading(false);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1920,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }

  const onChangeFile = async (e, type) => {
    setCurrentFile(URL.createObjectURL(e.target.files[0]));
    setCropperModal(true);
  }

  const removeImage = (to, i) => {
    setFile(null);
    setCurrentFile(null);
  }
  // console.log('image changed', image);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="talk yourself Introduction" />
        {/* Image cropper modal */}
        <Modal isOpen={cropperModal} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">Crop Image</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  cropImage()
                }}
              >
                <div className="mb-3 mt-2">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: "none" }}
                        onClick={() => { setCropperModal(false) }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: "none" }}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4">
                  <Cropper
                    style={{ height: 'auto', width: 400 }}
                    aspectRatio={1 / 1}
                    preview=".img-preview"
                    guides={true}
                    src={currentFile}
                    ref={cropper}
                  />
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              /> */}
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                      <div>
                        <CardTitle className="h4">Introduction</CardTitle>
                        <CardSubtitle className="mb-3">
                          Introduction you have added before. will show up whenever a test start.
                        </CardSubtitle>
                      </div>
                      {/* {user?.role == 'Admin' &&
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                      } */}
                    </div>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v, 'bank')
                      }}
                    >
                      <Row>
                        <Col md={3} className="mb-4">
                          {file ?
                            <Col md={12} style={{ marginRight: '2rem' }}>
                              <div className="me-3 position-relative" style={{ width: 216, height: 216 }}>
                                <img
                                  src={file ? file : avatar}
                                  alt=""
                                  style={{ width: '100%', height: '100%' }}
                                />
                                {user?.role == "Admin" || user?.permissions?.length && user?.permissions[13]?.talk_to_me?.update ?
                                  <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                    onClick={(e) => removeImage()}
                                  >
                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                  </div>
                                  : null}
                              </div>
                            </Col>
                            :
                            <Col md={12}>
                              <div className="me-3 position-relative" style={{ width: 216, height: 216 }}>
                                <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                  onClick={(e) => imageInput.current.click(e)}
                                >
                                  <button type="button" style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add Banner</button>
                                  <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                  >
                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                  </div>
                                  <input type="file" id="file"
                                    ref={imageInput}
                                    // accept='image/*'
                                    onChange={(e) => onChangeFile(e, 'file')}
                                    style={{ display: "none" }} />
                                </div>
                              </div>
                            </Col>
                          }
                        </Col>
                        <Col lg={9} md={9}>
                          <div className="mb-3">
                            <AvField
                              name="desc"
                              label="Description *"
                              placeholder="Enter introduction for the talk yourself"
                              className="form-control"
                              type="textarea"
                              rows={8}
                              value={currentData?.desc}
                              required
                              disabled={
                                user?.role !== "Admin" && !(user?.permissions?.length && user?.permissions[13]?.talk_to_me?.update)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Label>Alternative Languages</Label>
                      <Row>
                        <div className='lang-checks mb-3'>
                          {user?.languages?.map((item) => (
                            <div className='check-group'>
                              <label for={item?.title} class="container">
                                {item?.title}
                                <input type="checkbox"
                                  disabled={
                                    user?.role !== "Admin" && !(user?.permissions?.length && user?.permissions[13]?.talk_to_me?.update)
                                  }
                                  name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          ))}
                        </div>
                        {selectedLang?.map((item, index) => (
                          <Row>
                            <Col md={12}>
                              <div className="mb-3">
                                <AvField
                                  name={"desc" + item?.lang}
                                  label={"Description in " + item?.lang}
                                  className="form-control"
                                  placeholder={"Enter description in " + item?.lang}
                                  type="textarea"
                                  rows={4}
                                  onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                                  required
                                  value={item?.desc}
                                />
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Row>
                      {user?.role == "Admin" || user?.permissions?.length && user?.permissions[13]?.talk_to_me?.update ?
                        <div className="mt-4 w-100 d-flex justify-content-end">
                          <button
                            className="btn btn-primary w-25 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            {'Update'}
                          </button>
                        </div>
                        : null}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <audio id="audio" src={currentAudio} />
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Category)
)