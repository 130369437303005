import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label } from 'reactstrap';
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import moment from 'moment/moment';

const EarningChart = ({ data, total }) => {
    const [values, setValues] = useState([]);
    const [cats, setCats] = useState([]);

    useEffect(() => {
        if (data?.length) {
            let tempVal = [], tempCat = [];
            data?.forEach((element, index) => {
                if (index < data?.length - 1) {
                    tempCat.push(moment(element?._id, "YYYY-MM").format("MMM"));
                    tempVal.push(element?.count);
                }
            });
            setCats(tempCat);
            setValues(tempVal);
        }
    }, [data]);

    // console.log("data on earning chart", data);

    const options = {
        chart: {
            toolbar: {
                show: !1
            },
        }
        ,
        plotOptions: {
            bar: {
                horizontal: !0, barHeight: '24%', endingShape: 'rounded',
            }
        }
        ,
        dataLabels: {
            enabled: !1
        }
        ,
        colors: ['#556ee6'],
        xaxis: {
            categories: cats,
            title: {
                text: 'Users'
            }
            ,
        },
        // tooltip: {
        //     y: {
        //         formatter: function (val) {
        //             return `${val} Users`; // Customize this line to change the hover text
        //         }
        //     }
        // }
    }

    const series = [{
        name: "Users",
        data: values
    }
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Paid Users</CardTitle>
                    <Row>
                        <Col lg={6}>
                            <div>
                                <p>{moment().startOf('month').format('D MMM')} - {moment().format('D MMM YYYY')}</p>
                                <p className="mb-2">Total Paid Users</p>
                                <h4>{Number(total).toLocaleString()}</h4>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <div className="mt-3">
                                        <p className="mb-2 text-truncate">This Month {data?.length ? `(${moment(data[data?.length - 1]?._id, "YYYY-MM").format("MMM")})` : ""}</p>
                                        <h5 className="d-inline-block align-middle mb-0">{data?.length ? Number(data[data?.length - 1]?.count).toLocaleString() : "-"}</h5>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="mt-3">
                                        <p className="mb-2 text-truncate">Last Month {data?.length ? `(${moment(data[data?.length - 2]?._id, "YYYY-MM").format("MMM")})` : ""}</p>
                                        <h5>{data?.length ? Number(data[data?.length - 2]?.count).toLocaleString() : "-"}</h5>
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-4">
                                <Link to="/user/paid" className="btn btn-primary btn-sm">View more</Link>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    height="250"
                                    className="apex-charts"
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default EarningChart;