import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Label
} from "reactstrap";
import './style.scss';

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import Loader from "../../components/Loader";
import { get, put } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { AvField, AvForm } from 'availity-reactstrap-validation';

const Setting = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [isQuoteNoti, setIsQuoteNoti] = useState(false);
  const [isBreathNoti, setIsBreathNoti] = useState(false);
  const [isYogaNoti, setIsYogaNoti] = useState(false);
  const [isMaintain, setIsMaintain] = useState(false);
  const [isWell, setIsWell] = useState(false);
  const [isHarmony, setIsHarmony] = useState(false);
  const [isKnow, setIsKnow] = useState(false);
  const [isMedi, setIsMedi] = useState(false);
  const [isBreathe, setIsBreathe] = useState(false);
  const [isMyDairy, setIsMyDairy] = useState(false);
  const [isGames, setIsGames] = useState(false);
  const [isPod, setIsPod] = useState(false);
  const [isCom, setIsCom] = useState(false);
  const [isTTM, setIsTTM] = useState(false);
  const [coupons, setCoupons] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isPaidIOS, setIsPaidIOS] = useState(false);
  const [splashAuthor, setSplashAuthor] = useState("");
  const [splashQuote, setSplashQuote] = useState("");
  const [notiConfigModal, setNotiConfigModal] = useState(false);
  const [quoteNotiData, setQuoteNotiData] = useState({ title: "🌟 Discover Daily Quotes 🌟", body: "Embrace a healthier lifestyle! 🌿 Check your daily quotes in the Beyond Health app for inspiration and motivation. Start your day with positivity and wellness. 💪 #BeyondHealth #HealthyLiving", time: "10:00" });
  const [breathNotiData, setBreathNotiData] = useState({ title: "Breathe In, Breathe Beyond 🌬️", body: "Elevate your day with our daily breathing exercise. Take a moment to breathe deeply, center yourself, and embrace a renewed sense of well-being. Click now to start your mindful breath session. #BeyondWellness #MindfulLiving", time: "06:30" });
  const [yogaNotiData, setYogaNotiData] = useState({ title: "Morning Yoga Bliss 🧘‍♀️", body: "Rise and shine with our daily morning yoga session. Embrace the day with gentle stretches and mindful movements. Click to start your yoga practice and infuse your morning with positive energy. #YogaJourney #BeyondWellness", time: "06:00" });
  const [currentData, setCurrentData] = useState(undefined);
  const [currentFormType, setCurrentFormType] = useState("");

  useEffect(() => {
    if (user)
      getSettings();
  }, [user]);

  const getSettings = () => {
    get('/setting', { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setIsMaintain(res?.data?.maintinance);
          setIsQuoteNoti(res?.data?.isQuoteNoti);
          setQuoteNotiData(res?.data?.quoteNotiData);
          setIsBreathNoti(res?.data?.isBreathNoti);
          setBreathNotiData(res?.data?.breathNotiData);
          setIsYogaNoti(res?.data?.isYogaNoti);
          setYogaNotiData(res?.data?.yogaNotiData);
          setIsWell(res?.data?.wellness);
          setIsHarmony(res?.data?.harmony);
          setIsKnow(res?.data?.knowYourself);
          setIsMedi(res?.data?.meditation);
          setIsBreathe(res?.data?.breathe);
          setIsMyDairy(res?.data?.myDairy);
          setIsGames(res?.data?.games);
          setIsPod(res?.data?.podcast);
          setIsCom(res?.data?.community);
          setIsTTM(res?.data?.ttm);
          setCoupons(res?.data?.coupons);
          setIsPaid(res?.data?.isPaid);
          setIsPaidIOS(res?.data?.isPaidIOS);
          setSplashQuote(res?.data?.splashQuote);
          setSplashAuthor(res?.data?.splashAuthor);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        console.log('error while getting setting', err);
        toast.error("" + err);
      });
  }

  const handleChangeToggle = (val, to) => {
    console.log('changing toggle', val, typeof val)
    if (user?.role != "Admin")
      if (user?.permissions?.length)
        if (!user?.permissions[19].settings?.update) {
          toast.error("You're not authorized for this action!");
          return;
        }
    const body = {
      [to]: !val,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsMaintain(res?.data?.maintinance);
          setIsQuoteNoti(res?.data?.isQuoteNoti);
          setIsBreathNoti(res?.data?.isBreathNoti);
          setIsYogaNoti(res?.data?.isYogaNoti);
          setIsWell(res?.data?.wellness);
          setIsHarmony(res?.data?.harmony);
          setIsKnow(res?.data?.knowYourself);
          setIsMedi(res?.data?.meditation);
          setIsBreathe(res?.data?.breathe);
          setIsMyDairy(res?.data?.myDairy);
          setIsGames(res?.data?.games);
          setIsPod(res?.data?.podcast);
          setIsCom(res?.data?.community);
          setIsTTM(res?.data?.ttm);
          setCoupons(res?.data?.coupons);
          setIsPaid(res?.data?.isPaid);
          setIsPaidIOS(res?.data?.isPaidIOS);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  // console.log('getting setting', isStory);

  const handleValidSubmit = (e, v) => {
    const body = {
      token: user?.token
    }
    if (currentFormType == "quote")
      body.quoteNotiData = v;
    if (currentFormType == "breath")
      body.breathNotiData = v;
    if (currentFormType == "yoga")
      body.yogaNotiData = v;
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getSettings();
          setNotiConfigModal(false);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  const saveQuote = (e, v) => {
    setLoading(true);
    const body = {
      ...v,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          setSplashQuote(res?.data?.splashQuote);
          setSplashAuthor(res?.data?.splashAuthor);
        }
      })
      .catch(err => {
        console.log("Error while updating splahs meta", err);
        setLoading(false);
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Settings" />
        <Modal className="modal_form" isOpen={notiConfigModal} centered={true}>
          <ModalBody className="py-3 px-5">
            {currentFormType == "quote" &&
              <h5 className="text-black font-size-20">{'Configure Daily Quotes Notification'}</h5>
            }
            {currentFormType == "breath" &&
              <h5 className="text-black font-size-20">{'Configure Breath Notification'}</h5>
            }
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="title"
                        label="Notification Title"
                        className="form-control"
                        placeholder="Enter something about your notification"
                        type="text"
                        required
                        value={currentData?.title}
                      // validate={{min:{value:1}}}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="body"
                        label="Notification Description"
                        className="form-control"
                        placeholder="Enter detailed notification"
                        type="textarea"
                        rows={4}
                        required
                        value={currentData?.body}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="time"
                        label="Time"
                        className="form-control"
                        placeholder="Select the trigger time"
                        type="time"
                        required
                        value={currentData?.time}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            onClick={() => { setNotiConfigModal(false); }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            Update
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Row>
          {/* maintinance */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-cogs' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Maintinance</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="maintinance"
                      onChange={(e) => handleChangeToggle(isMaintain, 'maintinance')}
                      checked={isMaintain}
                    />
                    <label className="toggle-switch-label" htmlFor="maintinance">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* wellness */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-meditation' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Wellness</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="wellness"
                      onChange={(e) => handleChangeToggle(isWell, 'wellness')}
                      checked={isWell}
                    />
                    <label className="toggle-switch-label" htmlFor="wellness">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* harmony */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-soundcloud' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Harmony</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="harmony"
                      onChange={(e) => handleChangeToggle(isHarmony, 'harmony')}
                      checked={isHarmony}
                    />
                    <label className="toggle-switch-label" htmlFor="harmony">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* knowYourself */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-account-star' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Know Yourself</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="knowYourself"
                      onChange={(e) => handleChangeToggle(isKnow, 'knowYourself')}
                      checked={isKnow}
                    />
                    <label className="toggle-switch-label" htmlFor="knowYourself">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* meditation */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-yoga' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Meditation</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="meditation"
                      onChange={(e) => handleChangeToggle(isMedi, 'meditation')}
                      checked={isMedi}
                    />
                    <label className="toggle-switch-label" htmlFor="meditation">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* breathe */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-alpha-b-box' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Breathe</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="breathe"
                      onChange={(e) => handleChangeToggle(isBreathe, 'breathe')}
                      checked={isBreathe}
                    />
                    <label className="toggle-switch-label" htmlFor="breathe">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* myDairy */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-book-open-page-variant' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>My Dairy</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="myDairy"
                      onChange={(e) => handleChangeToggle(isMyDairy, 'myDairy')}
                      checked={isMyDairy}
                    />
                    <label className="toggle-switch-label" htmlFor="myDairy">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* games */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-gamepad' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Games</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="games"
                      onChange={(e) => handleChangeToggle(isGames, 'games')}
                      checked={isGames}
                    />
                    <label className="toggle-switch-label" htmlFor="games">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* podcast */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-podcast' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Podcast</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="podcast"
                      onChange={(e) => handleChangeToggle(isPod, 'podcast')}
                      checked={isPod}
                    />
                    <label className="toggle-switch-label" htmlFor="podcast">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* community */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-home-group' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Community</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="community"
                      onChange={(e) => handleChangeToggle(isCom, 'community')}
                      checked={isCom}
                    />
                    <label className="toggle-switch-label" htmlFor="community">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* TTM */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-account-star' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Talk To Me</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="ttm"
                      onChange={(e) => handleChangeToggle(isTTM, 'ttm')}
                      checked={isTTM}
                    />
                    <label className="toggle-switch-label" htmlFor="ttm">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* Coupons */}
          {/* <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-sale' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Coupons</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="coupons"
                      onChange={(e) => handleChangeToggle(coupons, 'coupons')}
                      checked={coupons}
                    />
                    <label className="toggle-switch-label" htmlFor="coupons">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* Android Web Payment */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <i className='mdi mdi-android' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 0 }} />
                    <i className='mdi mdi-currency-usd' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 0 }} />
                    <i className='mdi mdi-web' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  </div>
                  <span style={{ fontWeight: 'bolder' }}>Android Web Payment</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="isPaid"
                      onChange={(e) => handleChangeToggle(isPaid, 'isPaid')}
                      checked={isPaid}
                    />
                    <label className="toggle-switch-label" htmlFor="isPaid">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* IOS Web Payment */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <div>
                    <i className='mdi mdi-apple-ios' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 0 }} />
                    <i className='mdi mdi-currency-usd' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 0 }} />
                    <i className='mdi mdi-web' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  </div>
                  <span style={{ fontWeight: 'bolder' }}>IOS Web Payment</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="isPaidIOS"
                      onChange={(e) => handleChangeToggle(isPaidIOS, 'isPaidIOS')}
                      checked={isPaidIOS}
                    />
                    <label className="toggle-switch-label" htmlFor="isPaidIOS">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* quote notification */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-post' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>New Quote Notification</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="isQuoteNoti"
                      onChange={(e) => handleChangeToggle(isQuoteNoti, 'isQuoteNoti')}
                      checked={isQuoteNoti}
                    />
                    <label className="toggle-switch-label" htmlFor="isQuoteNoti">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" style={{ height: 24 }} />
                    </label>
                    <Button onClick={() => { setCurrentFormType("quote"); setCurrentData(quoteNotiData); setNotiConfigModal(true) }}
                      title={"Edit"}
                      style={{ backgroundColor: Utils.themeColor, float: 'right', width: 40, height: 40, marginTop: 3, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span>
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* breath nnotification */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-post' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Breath Notification</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="isBreathNoti"
                      onChange={(e) => handleChangeToggle(isBreathNoti, 'isBreathNoti')}
                      checked={isBreathNoti}
                    />
                    <label className="toggle-switch-label" htmlFor="isBreathNoti">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" style={{ height: 24 }} />
                    </label>
                    <Button onClick={() => { setCurrentFormType("breath"); setCurrentData(breathNotiData); setNotiConfigModal(true) }}
                      title={"Edit"}
                      style={{ backgroundColor: Utils.themeColor, float: 'right', width: 40, height: 40, marginTop: 3, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span>
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* yoga nnotification */}
          <Col lg={4} md={4}>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='mdi mdi-post' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Yoga Notification</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="isYogaNoti"
                      onChange={(e) => handleChangeToggle(isYogaNoti, 'isYogaNoti')}
                      checked={isYogaNoti}
                    />
                    <label className="toggle-switch-label" htmlFor="isYogaNoti">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" style={{ height: 24 }} />
                    </label>
                    <Button onClick={() => { setCurrentFormType("yoga"); setCurrentData(yogaNotiData); setNotiConfigModal(true) }}
                      title={"Edit"}
                      style={{ backgroundColor: Utils.themeColor, float: 'right', width: 40, height: 40, marginTop: 3, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span>
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <hr></hr>
          <Col md={12}>
            <AvForm onValidSubmit={saveQuote}>
              {/* <Row> */}
              <Col md={8} className='mt-2 mb-2'>
                <AvField
                  name="splashQuote"
                  placeholder="Enter Splash Screen Quote"
                  value={splashQuote}
                  type="textarea"
                  rows={4}
                  label="Splash Quote"
                  className="mb-2"
                />
                <AvField
                  name="splashAuthor"
                  placeholder="Enter Splash Screen Quote Author"
                  value={splashAuthor}
                  label="Splash Quote Author"
                />
              </Col>
              <Col md={4} className='mt-4 mb-4'>
                <Button type="submit" style={{ minWidth: 300, backgroundColor: Utils.themeColor, border: 'none' }}>Save</Button>
              </Col>
              {/* </Row> */}
            </AvForm>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Setting)
)
