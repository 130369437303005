import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const Overview = ({ devices, allUser }) => {

    return (
        <React.Fragment>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">By Devices</h4>

                        <div>
                            <div className="pb-3 border-bottom">
                                <Row className="align-items-center">
                                    <Col xs={8}>
                                        <p className="mb-2">Android</p>
                                        <h4 className="mb-0">{Number(devices?.androidUser).toLocaleString()}</h4>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="text-end">

                                            <div className="progress progress-sm mt-3">
                                                <div className="progress-bar" role="progressbar"
                                                    style={{ width: `${String(((Number(devices?.androidUser) * 100) / Number(allUser)).toFixed(0))}%` }}
                                                    aria-valuenow="62" aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="py-3 border-bottom">
                                <Row className="align-items-center">
                                    <Col xs={8}>
                                        <p className="mb-2">iOS</p>
                                        <h4 className="mb-0">{Number(devices?.iosUser).toLocaleString()}</h4>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="text-end">

                                            <div className="progress progress-sm mt-3">
                                                <div className="progress-bar bg-warning" role="progressbar"
                                                    style={{ width: `${String(((Number(devices?.iosUser) * 100) / Number(allUser)).toFixed(0))}%` }} aria-valuenow="48" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="pt-3">
                                <Row className="align-items-center">
                                    <Col xs={8}>
                                        <p className="mb-2">Web</p>
                                        <h4 className="mb-0">{Number(devices?.webUser).toLocaleString()}</h4>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="text-end">

                                            <div className="progress progress-sm mt-3">
                                                <div className="progress-bar bg-success" role="progressbar"
                                                    style={{ width: `${String(((Number(devices?.webUser) * 100) / Number(allUser)).toFixed(0))}%` }} aria-valuenow="78" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Overview;