import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import UserProfile from "../../components/UserProfile";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import moment from "moment";

const Table = (props) => {
  const { user, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(undefined);
  const [selectedLang, setSelectedLang] = useState([]);
  const [file, setFile] = useState(null);
  const [audio, setAudio] = useState(null);
  const imageInput = useRef();
  const cropper = useRef();

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/podcast/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 25, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        selector: row => row._id,
        maxWidth: '200px'
      },
      {
        name: 'Audio',
        cell: (row, index) => <>
          <span style={{ border: "1px solid " + Utils.themeColor, borderRadius: 70, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
            onClick={() => handleToggleAudio(row, index)}
          >
            {row?.isPlaying ?
              <i className="mdi mdi-pause" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 3 }}></i>
              :
              <i className="mdi mdi-play" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 3 }}></i>
            }
          </span>
          <span style={{ border: "1px solid " + Utils.themeColor, borderRadius: 70, marginLeft: 5, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
            onClick={() => window.open(row.audio)}
          >
            <i className="mdi mdi-download" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 5 }}></i>
          </span>
        </>,
        selector: row => row.audio,
        maxWidth: '100px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
        cell: (row) => <>
          <span title={row?.title}>{row?.title}</span>
        </>,
        sortable: true
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateLive(row)}
            title={row?.isLive ? "Stop Live" : "Go Live"}
            style={{ backgroundColor: row.isLive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.isLive ? 'Stop Live' : 'Go Live'}
            </span>
          </Button>
        </>,
        name: 'Live',
        selector: row => row?.isLive,
        sortable: true,
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        selector: row => row?.isActive,
        ignoreRowClick: true,
        sortable: true,
        maxWidth: '110px'
      },
      {
        name: 'Created At',
        selector: row => row?.createdAt,
        cell: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px",
        sortable: true,
      },
      {
        name: 'Scheduled At',
        selector: row => row?.scheduleAt,
        selector: row => moment(row?.scheduleAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px",
        sortable: true
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handlePaid(row)}
                title={"Paid"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[15].podcast?.update &&
                <Button onClick={() => handlePaid(row)}
                  title={"Paid"}
                  className="table_action_btn"
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[15].podcast?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  className="table_action_btn"
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[15].podcast?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  className="table_action_btn"
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
        // allowOverflow: true,
        // button: true,
      }
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setAudio(null);
      setCurrentAudio(null);
      setSelectedLang([]);
    }
  }, [isAddForm])

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setAudio(proj?.audio);
    if (proj?.alterLang?.length) {
      proj?.alterLang?.forEach((lang) => {
        user?.languages?.forEach((allLang) => {
          if (allLang?.code == lang?.code)
            lang.lang = allLang?.title;
        });
      });
      setSelectedLang(proj?.alterLang);
    }
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...item, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleUpdateLive = (row) => {
    if (user?.role == "Admin" || user?.permissions?.length && user?.permissions[15].podcast?.update) {
      setCurrentData({ ...row, actionType: 'Live' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      podId: currentData?._id,
      token: user?.token
    }
    let url = "/podcast";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == 'Live') {
      body = { ...body, isLive: !currentData?.isLive };
    } else if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/podcast/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const onChangeFile = async (e) => {
    // console.log('on change file', e.target);
    setFile(URL.createObjectURL(e.target.files[0]));
    // setCropperModal(true);
  };

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    console.log('values', v);
    let body = {
      ...v,
      alterLang: selectedLang,
      token: user?.token,
    }
    setLoading(true);
    if (file.substring(0, 4) == 'blob') {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
      const temp = await resizeFile(obj);
      const form = new FormData();
      form.append("image", temp);
      const imageRes = await upload("/podcast/image_upload", form);
      body = { ...body, image: imageRes?.data };
    }
    if (audio.substring(0, 4) == 'blob') {
      const obj = await urltoFile(audio, new Date().getTime() + '.mp3', 'audio/mpeg');
      // const temp = await resizeFile(obj);
      const form = new FormData();
      form.append("image", obj);
      const imageRes = await upload("/podcast/image_upload", form);
      body = { ...body, audio: imageRes?.data };
    }
    if (currentData) {
      body = { ...body, podId: currentData?._id }
      put("/podcast", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/podcast", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleToggleAudio = (row, index) => {
    setCurrentAudio(row?.audio);
    console.log('playing', row, index);
    const audioElement = document.getElementById('audio');
    if (!audioElement) return;
    if (row?.isPlaying) {
      audioElement.pause();
      if (currentData) {
        setCurrentData({ ...currentData, isPlaying: false });
      } else {
        let dat = Object.assign([], data);
        dat[index].isPlaying = false;
        setData(dat);
      }
    } else {
      audioElement.pause();
      setTimeout(() => {
        audioElement.play();
        if (currentData) {
          setCurrentData({ ...currentData, isPlaying: true });
        } else {
          let dat = Object.assign([], data);
          dat.map((item, inde) => {
            if (inde == index)
              item.isPlaying = true;
            else
              item.isPlaying = false;
          })
          setData(dat);
        }
      }, 500);
    }
  }

  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }

  const parseDate = (str) => {
    if (str) {
      let date = str?.split(":");
      let modify = date[0] + ":" + date[1];
      // console.log("date", str, date[0] + ":" + date[1]);
      return modify;
    } else
      return "";
  }


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <UserProfile
            userId={currentUserId}
            show={userModal}
            onCloseClick={() => setUserModal(false)}
            user={user}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentData ? `Update Podcast!` : `Add New Podcast!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        {file ?
                          <>
                            <CardTitle >Image</CardTitle>
                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                              {currentData?.image ?
                                <img src={file} style={{ width: 584, height: 'auto' }} />
                                :
                                <Cropper
                                  style={{ height: 'auto', width: 584 }}
                                  aspectRatio={1 / 1}
                                  preview=".img-preview"
                                  guides={true}
                                  src={file}
                                  ref={cropper}
                                />
                              }
                              <i className="mdi mdi-close" style={{
                                color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                position: 'absolute', top: -16, right: -12, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                              }}
                                onClick={() => { setFile(null); if (currentData) setCurrentData({ ...currentData, image: undefined }) }}
                              />
                            </div>
                          </>
                          :
                          <AvField
                            name="fileInput"
                            label="Image"
                            className="form-control"
                            placeholder="upload bank logo"
                            type="file"
                            onChange={e => onChangeFile(e)}
                            required
                            multiple={false}
                            ref={imageInput}
                            accept="image/png, image/jpeg"
                          // value={currentData?.logo}
                          />
                        }
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      {audio ?
                        <div className="mb-3">
                          <Label>Audio</Label>
                          <div className="d-flex align-items-center">
                            <span style={{ border: "1px solid " + Utils.themeColor, borderRadius: 70, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
                              onClick={() => handleToggleAudio(currentData, 0)}
                            >
                              {currentData?.isPlaying ?
                                <i className="mdi mdi-pause" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 3 }}></i>
                                :
                                <i className="mdi mdi-play" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 3 }}></i>
                              }
                            </span>
                            <span style={{ border: "1px solid " + Utils.themeColor, borderRadius: 70, marginLeft: 5, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
                              onClick={() => { document.getElementById('audio').pause(); setAudio(null); setCurrentData({ ...currentData, isPlaying: false, audio: undefined }) }}
                            >
                              <i className="mdi mdi-close" style={{ fontSize: 20, color: Utils.themeColor, marginTop: 5 }}></i>
                            </span>
                          </div>
                        </div>
                        :
                        <div className="mb-3">
                          <AvField
                            name="file_audio"
                            label="Audio *"
                            className="form-control"
                            type="file"
                            accept="audio/mpeg"
                            onChange={(e) => { setCurrentAudio(URL.createObjectURL(e.target.files[0])); setAudio(URL.createObjectURL(e.target.files[0])) }}
                            required={data ? false : true}
                          />
                        </div>
                      }
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="scheduleAt"
                          label="Schedule At *"
                          className="form-control"
                          placeholder="Enter live session url"
                          type="datetime-local"
                          required
                          value={parseDate(currentData?.scheduleAt)}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title *"
                          className="form-control"
                          placeholder="Enter podcast title"
                          type="text"
                          required
                          value={currentData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="desc"
                          label="Description *"
                          className="form-control"
                          placeholder="Enter description"
                          type="textarea"
                          rows={5}
                          required
                          value={currentData?.desc}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Label>Alternative Languages</Label>
                  <Row>
                    <div className='lang-checks mb-3'>
                      {user?.languages?.map((item) => (
                        <div className='check-group'>
                          <label for={item?.title} class="container">
                            {item?.title}
                            <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      ))}
                    </div>
                    {selectedLang?.map((item, index) => (
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name={"title" + item?.lang}
                              label={"Title in " + item?.lang}
                              className="form-control"
                              placeholder={"Enter title in " + item?.lang}
                              type="text"
                              onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                              required
                              value={item?.title}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name={"desc" + item?.lang}
                              label={"Description in " + item?.lang}
                              className="form-control"
                              placeholder={"Enter description in " + item?.lang}
                              type="textarea"
                              rows={5}
                              onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                              required
                              value={item?.desc}
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  <code> Anybody</code> can see the all <code> posdcasts</code> if status is <code>Active.</code> Also those are not live will appear in <code>Scheduled</code> list.
                </CardSubtitle>
              </div>
              <div className="group_btn_box">
                {user?.role == "Admin" ?
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                      Add
                    </Button>
                  </div>
                  :
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {user?.permissions?.length && user?.permissions[15]?.podcast?.write &&
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    }
                  </div>
                }
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationPerPage={100}
                      paginationRowsPerPageOptions={[100, 200, 300, 400]}
                      conditionalRowStyles={[{
                        when: row => row?.style,
                        style: row => ({ width: row?.style?.width }),
                      },
                      ]}
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            width: 0
                          },
                        },
                        cells: {
                          style: {
                            width: 0
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <audio id="audio" src={currentAudio} />
          </Col>
          <audio id="audio" src={currentAudio} />
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
