import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility';

import { createCompany, companySuccess, companyError, updateCompany } from "../../../store/actions";
import { profileSuccess, getProfile } from '../../../store/auth/profile/actions';
import AddNotiForm from './AddQuestion';
import Projects from './Card';
import Loader from "../../../components/Loader";

const Project = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  // console.log('props on company', props);

  useEffect(()=>{
    if(!isAddForm)
        setCurrentData(null);
  }, [isAddForm]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Talk To Me Questions" />
        {isAddForm ?
          <AddNotiForm
            role={"Question"}
            user={user}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
          />
          : <Card>
            <CardBody>
              <Projects
                role={"Talk To Me"}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentData}
                setCurrentData={setCurrentData}
                setLoading={setLoading}
                history={props.history}
              />
            </CardBody>
          </Card>
        }

      </div>
    </React.Fragment>
  )
}

Project.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Project)
)