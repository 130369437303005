import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import './style.scss';
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import Loader from "../../components/Loader";
import { del, get, post, put } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import DeleteModal from "./DeleteModal";
const staticPages = [
  {
    url: "https://thebeyondlifestyle.com/",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/#about",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/#faq",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/contact",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/pricing",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/support",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/privacy-policy",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/terms-and-conditions",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/refunds-and-cancellation-policy",
    isActive: true
  },
  {
    url: "https://thebeyondlifestyle.com/blog",
    isActive: true
  },
]

const Sitemap = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    if (user?.token)
      getSitemap();
  }, [user]);

  useEffect(() => {
    setColumns([
      {
        name: 'URL',
        selector: row => row?.url,
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => window.open(row?.url, "_blank")}
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-eye" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  const getSitemap = () => {
    get('/sitemap', { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setData(res?.data);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        console.log('error while getting setting', err);
        toast.error("Something Went Wrong!");
      });
  }

  const generate = () => {
    setLoading(true);
    let temp = Object.assign([], staticPages);
    get("/blog/sitemap", { token: user?.token })
      .then(res => {

        if (res?.statusCode == 200) {
          res?.data?.forEach(element => {
            temp.push(
              {
                url: "https://thebeyondlifestyle.com/blog/" + element?.url,
                isActive: true
              }
            )
          });
          setData(temp);
          post("/sitemap", { token: user?.token, map: temp })
            .then(resp => {
              setLoading(false);
            })
            .catch(erro => {
              console.log("error while updating sitemap", erro);
              setLoading(false);
            })
        } else {
          setLoading(false);
          toast.error(res?.error);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log("error while getting blogs for sitemap", err);
        toast.error("Something Went Wrong!");
      })
  }

  const uploadSitemap = () => {
    setLoading(true);
    post("/sitemap/upload", { token: user?.token })
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200)
          toast.success(res?.message)
        else
          toast.error(res?.error);
      })
      .catch(err => {
        setLoading(false);
        console.log("error while uploading sitemap", err);
      })
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: "Status" });
    setConfirmModal(true);
  }

  const handleDeleteProj = (row) => {
    setCurrentData({ ...row, actionType: "Delete" });
    setConfirmModal(true);
  }

  const onConfirm = () => {
    let body = {
      sitemapId: currentData?._id,
      token: user?.token
    }
    setLoading(true);
    if (currentData?.actionType == "Status") {
      body.isActive = !currentData?.isActive;
      put("/sitemap", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setCurrentData(null);
            setConfirmModal(false);
            toast.success("Success");
            getSitemap();
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          console.log("error while update status", err);
          setLoading(false);
        })
    } else {
      del("/sitemap", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setCurrentData(null);
            setConfirmModal(false);
            toast.success("Success");
            getSitemap();
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          console.log("error while update status", err);
          setLoading(false);
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <DeleteModal
          visible={confirmModal}
          data={currentData}
          onConfirm={onConfirm}
          onCancel={() => { setCurrentData(null); setConfirmModal(false) }}
        />
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Sitemap" />
        <Row>
          <Col md={6}>
          </Col>
          <Col md={6} className='mt-4 mb-4'>
            <div className="group_btn_box">
              <Button type="button" onClick={generate} >Regenerate Sitemap</Button>
              <Button type="button" onClick={uploadSitemap} >Upload Sitemap</Button>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Sitemap)
)
