import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import './style.css';
import moment from "moment";
import { CSVLink } from 'react-csv';

const Table = (props) => {
  const { user, role, currentData, setCurrentData, path, loading, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [plans, setPlans] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");


  useEffect(() => {
    if (user?.token) {
      getData();
      getPlans();
    }
  }, [user]);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      let url = "/coupon/list?company=" + selectedCompany;
      get(url, { token: user?.token })
        .then(json => {
          console.log('response from get banner list', json);
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setCompanies(json?.companies);
          } else {
            toast.error(json?.error);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  const getPlans = () => {
    get("/plan", { token: user?.token })
      .then(json => {
        if (json?.statusCode == 200) {
          setPlans(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Company',
        selector: row => row?.company,
        sortable: true,
        maxWidth: "200px"
      },
      {
        name: 'Code',
        selector: row => row?.code,
        sortable: true,
      },
      {
        name: "Plan",
        selector: row => row?.planId,
        cell: row => <>
          <img src={row?.plan[0]?.image} style={{ width: 25, height: 25 }} />
        </>,
        sortable: true,
        maxWidth: '100px'
      },
      {
        name: 'Expiry',
        selector: row => moment(row?.expire).format("DD-MMM-YYYY"),
        sortable: true,
        maxWidth: '130px'
      },
      {
        name: 'Is Used',
        selector: row => row?.isUsed ? "Yes" : "No",
        sortable: true,
        maxWidth: '120px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Status")}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        selector: row => row?.isActive,
        sortable: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleCopy(row?.code)}
            title={"Copy Code"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[17]?.coupon?.update ?
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
                : null}
              {user?.permissions?.length && user?.permissions[17]?.coupon?.delete ?
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
                : null}
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
      },
    ])
  }, [data]);

  const handleCopy = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      toast.success('Code copied to clipboard!');
    } catch (err) {
      toast.error('Failed to copy code.');
      console.error('Failed to copy code:', err);
    }
  };

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item, type) => {
    // console.log('updating proj', item);
    if (user?.role == "Admin" || user?.permissions?.length && user?.permissions[17]?.coupon?.update) {
      setCurrentData({ ...item, actionType: type });
      setDeleteModal(true);
    }
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      couponId: currentData?._id,
      token: user?.token
    }
    let url = "/coupon";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == "Recomm") {
      body = { ...body, recomm: !currentData?.recomm };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      token: user?.token,
    }
    if (currentData?._id) {
      body = { ...body, couponId: currentData?._id }
      put("/coupon", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      post("/coupon", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.messgae);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error('Something Went Wrong!');
          console.log('error while adding banner', error);
        })
    }
  }

  const formatDateTime = (str) => {
    console.log("formatting date", str);
    return moment(str).format('YYYY-MM-DD');
    // return "2023-11-29T07:50";
  }

  const exportToCSV = () => {
    let csvData = [];
    csvData = data.map((row) => ({
      Company: row?.company,
      Code: row?.code,
      Expire: moment(row?.expire).format("DD-MMM-YYYY"),
      Plan: row?.plan[0]?.title
    }));
    return csvData;
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody>
              <h3>{currentData?._id ? "Update" : "Add"} Coupon</h3>
              <div className="me-3 position-relative">
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvField
                          name="planId"
                          label={"Plan"}
                          value={currentData?.planId}
                          className="form-control"
                          type="select"
                          required
                        >
                          <option value="">Select Plan</option>
                          {plans?.map((item) => (
                            <option value={item?._id}>{item?.title}</option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvField
                          name="company"
                          label={"Company Name"}
                          value={currentData?.company}
                          className="form-control"
                          placeholder="Enter Company Name"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    {currentData?._id ?
                      <Col md={12}>
                        <div className="form-group mb-4">
                          <AvField
                            name="code"
                            label={"Code"}
                            value={currentData?.code}
                            className="form-control"
                            placeholder="Enter Company Name"
                            type="text"
                            disabled
                            required
                          />
                        </div>
                      </Col>
                      :
                      <Col md={12}>
                        <div className="form-group mb-4">
                          <AvField
                            name="num"
                            label={"Number of coupons"}
                            value={currentData?.num}
                            className="form-control"
                            placeholder="Enter the number of coupons you need to generate"
                            type="number"
                            required
                            validate={{ min: { value: 1 }, max: { value: 100 } }}
                          />
                        </div>
                      </Col>
                    }
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvField
                          name="expire"
                          label={"Expire date"}
                          value={currentData?.expire ? formatDateTime(currentData?.expire) : null}
                          className="form-control"
                          type="date"
                          required
                        />
                      </div>
                    </Col>
                    <div className="text-center mt-4 d-flex justify-content-around">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Coupon
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Coupon
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  {role} you have added before, All the <code>{role} </code>can be used while purchasing any<code> plan.</code>
                </CardSubtitle>
              </div>
              {!error &&
                <div className="group_btn_box">
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button
                    >
                      <CSVLink
                        data={exportToCSV(data)}
                        filename={"coupons.csv"}
                        className="text-white"
                        target="_blank"
                      >
                        Export Coupons ({data?.length})
                      </CSVLink>
                    </Button>
                    {user?.role == "Admin" || user?.permissions?.length && user?.permissions[17]?.coupon?.write ?
                      <Button onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                      : null}
                  </div>
                </div>
              }
            </div>
            {companies?.length > 1 ?
              <Row>
                <Col md={3}>
                  <AvForm>
                    <div className="form-group mb-4">
                      <AvField
                        name="company"
                        label={"Company"}
                        value={currentData?.selectedCompany}
                        className="form-control"
                        type="select"
                        onChange={e => setSelectedCompany(e.target.value)}
                      >
                        <option value="">Select Company</option>
                        {companies?.map((item) => (
                          <option value={item?._id}>{item?._id}</option>
                        ))}
                      </AvField>
                    </div>
                  </AvForm>
                </Col>
              </Row>
              : null}
            <Row>
              <DataTable
                columns={columns}
                data={data}
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 200, 300, 400]}
                customStyles={{
                  headCells: {
                    style: {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 15,
                      maxWidth: 80
                    },
                  },
                  cells: {
                    style: {
                      maxWidth: 50
                    }
                  }
                }}
                conditionalRowStyles={[{
                  when: row => row?.seen?.length < 1,
                  style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                }]}
              />
            </Row>

          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default Table;
