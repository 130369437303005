import React, { useState } from "react"
import PropTypes from 'prop-types'

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/logo-sm.png"
import logoLight from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"

import Navbar from "./Navbar"

//i18n
import { withTranslation } from "react-i18next"

const Header = props => {
  const isMenuOpened = 'false'
  const [isSearch, setSearch] = useState(false);
  const { user } = props;

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  console.log("user on header", user);
  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logo} alt="" height="20" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="18" />
              </span>
            </Link>
            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="" height="20" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="18" />
              </span>
            </Link>
          </div>
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse"
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu)
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          <Navbar menuOpen={isMenuOpened} role={user?.role} />
        </div>
        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="mdi mdi-magnify"></i>
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Search") + "..."}
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* <LanguageDropdown /> */}

          <div className="dropdown d-none d-lg-inline-block ms-1">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              onClick={() => {
                toggleFullscreen()
              }}
              data-toggle="fullscreen"
            >
              <i className="mdi mdi-fullscreen"></i>
            </button>
          </div>

          {/* <NotificationDropdown user={user} /> */}

          {/* <ProfileMenu /> */}
          <div className="logout-button-text d-flex">
            <Link to="/logout" className="">
              <i className="mdi mdi-power"></i>{" "}
            </Link>
          </div>
          {/* 
          <div className="dropdown d-inline-block">
            <button
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="mdi mdi-settings-outline"></i>
            </button>
          </div> */}
        </div>
      </div>
      {user?.role == "Admin" ?
        <ul className='inner-menu-page'>
          <li><Link to={"/theme"}>Theme</Link></li>
          <li><Link to={"/popup"}>Popup</Link></li>
          <li><Link to={"/wellness"}>Wellness</Link></li>
          <li><Link to={"/yoga"}>Daily Yoga</Link></li>
          <li><Link to="/sub-admin">{props.t("Sub-Admin")}</Link></li>
          <li><Link to={"/communities"}>Communities</Link></li>
          <li><Link to="/language">{props.t("Languages")}</Link></li>
          <li><Link to="/social-media">{props.t("Social-Media")}</Link></li>
          <li><Link to="/store">{props.t("Image Store")}</Link></li>
          <li><Link to="/mailer">{props.t("Mails")}</Link></li>
          <li><Link to="/sitemap">{props.t("Sitemap")}</Link></li>
          <li><Link to="/screen">{props.t("App Screens")}</Link></li>
          <li><Link to={"/plans"}>Plans</Link></li>
          <li><Link to={"/games"}>Games</Link></li>
          <li><Link to={"/guide"}>Guide</Link></li>
          <li><Link to={"/live_session"}>Live Session</Link></li>
        </ul>
        :
        <ul className='inner-menu-page'>
          {user?.permissions?.length && user?.permissions[3]?.theme?.read ?
            <li><Link to={"/theme"}>Theme</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[23]?.popup?.read ?
            <li><Link to={"/popup"}>Popup</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[4]?.wellness?.read ?
            <li><Link to={"/wellness"}>Wellness</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[5]?.yoga?.read ?
            <li><Link to={"/yoga"}>Daily Yoga</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[12]?.communities?.read ?
            <li><Link to={"/communities"}>Communities</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[10]?.content?.read || user?.permissions?.length && user?.permissions[22]?.blog?.read ?
            <li><Link to="/store">{props.t("Image Store")}</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[11]?.games?.read ?
            <li><Link to={"/games"}>Games</Link></li>
            : null}
          <li><Link to={"/guide"}>Guide</Link></li>
          {user?.permissions?.length && user?.permissions[14]?.live_session?.read ?
            <li><Link to={"/live_session"}>Live Session</Link></li>
            : null}
          {user?.permissions?.length && user?.permissions[18]?.social_media?.read ?
            <li><Link to="/social-media">{props.t("Social-Media")}</Link></li>
            : null}
          {/*
          <li><Link to="/language">{props.t("Languages")}</Link></li>
          <li><Link to="/store">{props.t("Image Store")}</Link></li>
          <li><Link to="/mailer">{props.t("Mails")}</Link></li>
          <li><Link to="/sitemap">{props.t("Sitemap")}</Link></li>
          <li><Link to="/screen">{props.t("App Screens")}</Link></li>
          <li><Link to={"/plans"}>Plans</Link></li>
          <li><Link to={"/games"}>Games</Link></li>
          <li><Link to={"/guide"}>Guide</Link></li> */}
        </ul>
      }
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, user: state.Profile?.user }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
