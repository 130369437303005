import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const imageToCrop = useRef(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [bannerHeight, setBannerHeight] = useState(256);
  const [error, setError] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);


  useEffect(() => {
    // console.log('props on user table', props)
    if (user?.token) {
      getData();
    }
  }, [user, path]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setImg(null);
      setIsNavigate("");
      setNavigationType("")
    }
  }, [isAddForm]);

  const getData = () => {
    let url = "/plan/list";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 25, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: '120px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
      },
      {
        name: 'Duration',
        selector: row => row?.duration,
      },
      {
        name: 'Off Price',
        selector: row => row?.offPrice,
      },
      {
        name: 'Price',
        selector: row => row?.price,
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row, "Status")}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row, "Recomm")}
            title={row?.recomm ? "Yes" : "No"}
            style={{ backgroundColor: !row.recomm ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.recomm ? 'Yes' : 'No'}
            </span>
          </Button>
        </>,
        name: 'Recommand',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/user/detail/` + row?._id, { state: { type: path } })}
            title={"View in details"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item, type) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: type });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      planId: currentData?._id,
      token: user?.token
    }
    let url = "/plan";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == "Recomm") {
      body = { ...body, recomm: !currentData?.recomm };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
      let image = img;
      if (file.substring(0, 4) == "blob") {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", temp);
        const imageRes = await upload("/plan/image_upload", form);
        image = imageRes?.data;
      }
      const body = {
        ...v,
        token: user?.token,
        alterLang: selectedLang,
        image,
        planId: currentData?._id
      }
      put("/plan", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      if (file) {
        let image = "";
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", temp);
        const imageRes = await upload("/plan/image_upload", form);
        image = imageRes?.data;
        const body = {
          ...v,
          image,
          alterLang: selectedLang,
          token: user?.token
        }
        post("/plan", body)
          .then(res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.messgae);
              setIsAddForm(false);
              getData();
            } else {
              toast.error(res?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            toast.error('Something Went Wrong!');
            console.log('error while adding banner', error);
          })
      } else
        toast.error('Please choose a banner image!')
    }
  }

  const handleStatusProj = (item) => {
    props?.setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/banner/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };


  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                {file ?
                  <>
                    {img ?
                      <div style={{ width: 360, position: 'relative' }}>
                        <img
                          src={img}
                          alt=""
                          style={{ width: 360, height: 'auto' }}
                        // onClick={(e) => imageInput.current.click(e)}
                        />
                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                          onClick={() => { setFile(null); setImg("") }}
                        >
                          <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                        </div>
                      </div>
                      :
                      <div style={{ width: 500, position: 'relative' }}>
                        <Cropper
                          style={{ height: 'auto', width: 500 }}
                          aspectRatio={1 / 1}
                          preview=".img-preview"
                          guides={true}
                          src={file}
                          ref={cropper}
                        />
                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                          onClick={() => { setFile(null); setImg("") }}
                        >
                          <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                        </div>
                      </div>
                    }
                  </>
                  :
                  <div style={{
                    position: 'relative',
                    width: 500,
                    height: 320,
                    border: '1px dashed ' + Utils.themeColor,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Utils.themeColor,
                    fontSize: 22
                  }} onClick={(e) => imageInput.current.click(e)}>
                    <span
                      style={{
                        padding: 5,
                        paddingLeft: 15, paddingRight: 15,
                        border: '1px solid ' + Utils.themeColor,
                        borderRadius: 5
                      }}
                    >Choose Image</span>
                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                    >
                      <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                    </div>
                  </div>
                }
                <input type="file" id="file"
                  ref={imageInput}
                  onChange={onChangeFile}
                  style={{ display: "none" }} />
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="title"
                          label={"Title"}
                          value={currentData?.title}
                          className="form-control"
                          placeholder="Enter plan Title"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="duration"
                          label={"Duration (Days)"}
                          value={currentData?.duration}
                          className="form-control"
                          placeholder="Enter the duration for this plan in days"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <h5 style={{ textDecorationLine: 'underline' }}>INR</h5>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="offPrice"
                          label={"Off Price"}
                          value={currentData?.offPrice}
                          className="form-control"
                          placeholder="Enter the off price"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="price"
                          label={"Price"}
                          value={currentData?.price}
                          className="form-control"
                          placeholder="Enter the price"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="rzpPlanId"
                          label={"Razorpay Plan Id"}
                          value={currentData?.rzpPlanId}
                          className="form-control"
                          placeholder="Enter the Razorpay Plan Id (INR)"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <h5 style={{ textDecorationLine: 'underline' }}>USD</h5>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="offPriceUSD"
                          label={"Off Price"}
                          value={currentData?.offPriceUSD}
                          className="form-control"
                          placeholder="Enter the off price"
                          type="number"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="priceUSD"
                          label={"Price"}
                          value={currentData?.priceUSD}
                          className="form-control"
                          placeholder="Enter the price"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="rzpPlanIdUSD"
                          label={"Razorpay Plan Id (USD)"}
                          value={currentData?.rzpPlanIdUSD}
                          className="form-control"
                          placeholder="Enter the Razorpay Plan Id (USD)"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="productId"
                          label={"Apple Product Id"}
                          value={currentData?.productId}
                          className="form-control"
                          placeholder="Enter the apple product id"
                          type="text"
                        />
                      </div>
                    </Col>
                    <Label>Alternative Languages</Label>
                    <Row>
                      <div className='lang-checks mb-3'>
                        {user?.languages?.map((item) => (
                          <div className='check-group'>
                            <label for={item?.title} class="container">
                              {item?.title}
                              <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        ))}
                      </div>
                      {selectedLang?.map((item, index) => (
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <AvField
                                name={"title" + item?.lang}
                                label={"Title in " + item?.lang}
                                className="form-control"
                                placeholder={"Enter title in " + item?.lang}
                                type="text"
                                onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                required
                                value={item?.title}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </Row>
                    <div className="text-center mt-4 d-flex justify-content-around">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Plan
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Plan
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  {role} you have added before, All the <code>{role} </code>will reflect on your <code>Website and App.</code>
                </CardSubtitle>
              </div>
              {!error &&
                <div className="group_btn_box">
                  {/* <div style={{ marginLeft: 'auto' }}>
                        <AvForm style={{ border: 'none' }}
                        >
                          <AvField
                            name="bannerHeight"
                            placeholder={"Banner Height"}
                            style={{ width: 150, border: "1px solid " + Utils.themeColor }}
                            validate={{ min: { value: 256 }, max: { value: 700 } }}
                            type="number"
                            value={bannerHeight}
                            onChange={(e) => handleBannerHeight(e.target.value)}
                          />
                        </AvForm>
                      </div> */}
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                      Add
                    </Button>
                  </div>
                </div>
              }
            </div>
            <Row>
              {/* {data?.map((item, index) => (
                    <Col lg={6} className="banner">
                      <div style={{ width: 'auto', height: bannerHeight ? bannerHeight - 100 : 360, display: 'flex' }}>
                        <img src={item?.image} style={{ width: 'auto', height: '100%' }} />
                        <div style={{ padding: 20, flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <p>Is Navigate : {item?.isNavigate}</p>
                            {item?.navigationType &&
                              <p>Navigation Type : {item?.navigationType}</p>
                            }
                            {item?.screenName &&
                              <p>Screen Name : {item?.screenName}</p>
                            }
                            {item?.link &&
                              <p>Link : {item?.link}</p>
                            }
                          </div>
                          <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div>
                              <div className="toggle-switch" style={{ marginLeft: -20 }}>
                                <input
                                  type="checkbox"
                                  className="toggle-switch-checkbox"
                                  name={"toggleSwitch" + index}
                                  id={"banner" + index}
                                  onChange={(e) => handleStatusProj(item)}
                                  checked={item.isActive}
                                />
                                <label className="toggle-switch-label" htmlFor={"banner" + index}>
                                  <span className="toggle-switch-inner" />
                                  <span className="toggle-switch-switch" />
                                </label>
                              </div>
                            </div>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none'}} onClick={() => handleUpdateProj(item)}>
                              <i className="mdi mdi-pencil" style={{ color: 'white', fontSize: 20, cursor: 'pointer' }} />
                            </Button>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => handleDeleteProj(item)}>
                              <i className="mdi mdi-delete" style={{ color: 'white', fontSize: 20, marginTop: 5, cursor: 'pointer' }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))} */}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{
                          headCells: {
                            style: {
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 15,
                              maxWidth: 80
                            },
                          },
                          cells: {
                            style: {
                              maxWidth: 50
                            }
                          }
                        }}
                        conditionalRowStyles={[{
                          when: row => row?.seen?.length < 1,
                          style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                        }]}
                      // onRowClicked={(r, e) => seenNoti(r)}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>

          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
