import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/games/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          json?.data?.map((item) => {
            item.isPlaying = false;
          });
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }


  const handleToggleAudio = (row, index) => {
    setCurrentAudio(row?.audio);
    console.log('playing', row, index);
    const audioElement = document.getElementById('audio');
    if (!audioElement) return;
    if (row?.isPlaying) {
      audioElement.pause();
      let dat = Object.assign([], data);
      dat[index].isPlaying = false;
      setData(dat);
    } else {
      audioElement.pause();
      setTimeout(() => {
        audioElement.play();
        let dat = Object.assign([], data);
        dat.map((item, inde) => {
          if (inde == index)
            item.isPlaying = true;
          else
            item.isPlaying = false;
        })
        setData(dat);
      }, 500);
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 25, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        selector: row => row._id,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        cell: (row) => <>
          <span>{row?.title}</span>
        </>,
      },
      {
        name: 'No Of Clicks',
        selector: row => 0,
        maxWidth: '130px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => window.open(row?.url, { target: "_blank" })}
            title={"Play"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-play"} style={{ color: Utils.textColor }}></span></Button>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handlePaid(row)}
                title={"Paid"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[11].games?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[11].games?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '190px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...item, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      gameId: currentData?._id,
      token: user?.token
    }
    let url = "/games";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/games/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  {role} you have added before,<code> Anybody</code> can <code>check</code> and <code>visit</code> on these. if <code>Status</code> is Active.
                </CardSubtitle>
              </div>
              <div className="group_btn_box">
                {user?.role == "Admin" ?
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                      Add
                    </Button>
                  </div>
                  :
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {user?.permissions?.length && user?.permissions[11].games?.write &&
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    }
                  </div>
                }
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      conditionalRowStyles={[{
                        when: row => row?.style,
                        style: row => ({ width: row?.style?.width }),
                      },
                      ]}
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            width: 0
                          },
                        },
                        cells: {
                          style: {
                            width: 0
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <audio id="audio" src={currentAudio} />
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
