import React, { useEffect, useState } from "react"
import { Pie } from "react-chartjs-2"

const PieChart = ({ data }) => {

  const [series, setSeries] = useState({
    labels: ["Desktops", "Tablets"],
    datasets: [
      {
        data: [300, 180],
        backgroundColor: ["#34c38f", "#ebeff2"],
        hoverBackgroundColor: ["#34c38f", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  });

  useEffect(() => {
    if (data?.length) {
      let labels = [], values = [];
      data?.forEach(element => {
        labels.push(element?.plan?.title + ` (${element?.count})`);
        values.push(element?.count);
      });
      setSeries({
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: ["#34c38f", "#eeb902"],
            hoverBackgroundColor: ["#34c38f", "#eeb902"],
            hoverBorderColor: "#fff",
          },
        ]
      });
    }
  }, [data]);

  const options = {
    legend: {
      display: true,
      position: 'right', // Positioning the legend to the right
      labels: {
        boxWidth: 20,
        padding: 15,
      },
    },
  };

  return <Pie width={474} height={260} data={series} options={options} />
}

export default PieChart
