import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { VectorMap } from "react-jvectormap"

const map = React.createRef(null)

const Overview = ({ data }) => {
    const [series, setSeries] = useState(undefined);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, right: 0 });
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    useEffect(() => {
        if (data?.length) {
            let temp = {};
            data?.forEach(element => {
                temp = { ...temp, [element?.code]: element?.count };
            });
            setSeries(temp);
        }
    }, [data]);

    const handleRegionTipShow = (e, el, code) => {
        if (series[code]) {
            setTooltipContent(`Country: ${el.html()} | Count: ${series[code]}`);
            setTooltipPosition({ top: e.pageY - 50, right: e.pageX - 50 });
            setIsTooltipVisible(true);
        }
    };

    const handleRegionOut = () => {
        setIsTooltipVisible(false);
    };

    return (
        <React.Fragment>
            <Col lg={6}>
                <Card style={{ height: "100%" }}>
                    <CardBody style={{ height: "100%" }}>
                        <h4 className="card-title mb-4">Revenue by location</h4>
                        <Row style={{ height: "100%" }}>
                            <Col sm={12}>
                                <div id="usa-vectormap" style={{ height: '100%' }}>
                                    <VectorMap
                                        map='world_mill'
                                        backgroundColor="transparent"
                                        ref={map}
                                        handleWindowResize={true}
                                        containerStyle={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        regionStyle={{
                                            initial: {
                                                fill: '#b6b6b6'
                                            },
                                        }}
                                        series={{
                                            regions: [
                                                {
                                                    values: series,
                                                    scale: ["#556ee6", "#45cb85"],
                                                    normalizeFunction: "polynomial",
                                                },
                                            ],
                                        }}
                                        // onRegionTipShow={handleRegionTipShow}
                                        // onRegionOut={handleRegionOut}
                                        zoomMin={1}
                                        zoomMax={10}
                                        containerClassName="map"
                                    />
                                    {/* {isTooltipVisible && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: tooltipPosition.top,
                                                right: tooltipPosition.right,
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                color: '#fff',
                                                padding: '5px 10px',
                                                borderRadius: '5px',
                                                pointerEvents: 'none',
                                                zIndex: 10,
                                            }}
                                        >
                                            {tooltipContent}
                                        </div>
                                    )} */}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Overview;