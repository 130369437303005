import React from "react"
import { Container, Row, Col } from "reactstrap"
import Utils from '../../pages/Utility';
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
          <Col sm={6}>{new Date().getFullYear()} © {Utils.projectName}.</Col>
            {/* <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Themesbrand
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
