import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
import Utils from '../Utility';
import { get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [positionModal, setPositionModal] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    setLoading(true);
    get("/mail/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        setLoading(false);
        if (json?.statusCode == 200) {
          json?.data?.map((item) => {
            item.isPlaying = false;
          });
          setData(json?.data);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Event',
        selector: row => row?.event,
        maxWidth: '130px'
      },
      {
        name: 'Subject',
        cell: (row) => <>
          <span>{row?.subject}</span>
        </>,
        selector: row => row?.subject,
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '108px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[10]?.content?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[10]?.content?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '145px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...item, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      mailId: currentData?._id,
      token: user?.token
    }
    let url = "/mail";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/mail/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleValidSubmit = (e, v) => {
    let swapId = data?.find(x => x?.position == Number(v?.position));
    let ids = [currentData?._id, swapId?._id];
    let position = [Number(v?.position), currentData?.position];
    console.log("values", ids, position);
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/mail/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        getData();
        setPositionModal(false);
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before,<code> Everybody</code> will get these mail as per thier <code>event.</code>
                    </CardSubtitle>
                  </div>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      {user?.permissions?.length && user?.permissions[10]?.content?.write &&
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                          Add
                        </Button>
                      }
                    </div>
                  }
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={200}
                          paginationRowsPerPageOptions={[200, 300, 400, 500]}
                          conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                          },
                          ]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
            <audio id="audio" src={currentAudio} />
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
