import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import UiDropdown from "../Ui/UiDropdown";
import SearchableDropdown from "../Ui/UiSearchableDropdown";
import moment from "moment";
import Products from "../Dashboard/product";

// import './Card.scss';

const Table = (props) => {
  const { user, singleId, path } = props;
  const [data, setData] = useState(undefined);
  const [stacked, setStacked] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    post("/screen/analytic", { token: user?.token, userId: singleId })
      .then(res => {
        console.log('response from get properties', res);
        if (res?.statusCode == 200) {
          setData(res?.data);
          // setdata(res?.data?.user);
        }
      })
      .catch(error => {
        console.log('error while getting screen analytic', error);
      });
  }

  useEffect(() => {
    if (!stacked) {
      setCurrentModalData("");
      setMetaData([]);
    }
  }, [stacked])

  useEffect(() => {
    if (currentModalData == 'contentf') {
      setMetaData([]);
      if (data?.contents?.length)
        getContent();
    }
    else if (currentModalData == 'contentw') {
      setMetaData([]);
      if (data?.watched?.length)
        getWatchedContent();
    }
    else if (currentModalData == 'commf') {
      setMetaData([]);
      if (data?.communities?.length)
        getCommunities();
    }
    else if (currentModalData == 'knowf') {
      setMetaData([]);
      if (data?.knowYourself?.length)
        getKnow();
    }
    else if (currentModalData == 'podf') {
      setMetaData([]);
      if (data?.podcast?.length)
        getPodcast();
    }
    else if (currentModalData == 'quotef') {
      setMetaData([]);
      if (data?.quotes?.length)
        getQuotes();
    }
    else if (currentModalData == 'wellf') {
      setMetaData([]);
      if (data?.wellness?.length)
        getWell();
    }
    else if (currentModalData == 'yogaf') {
      setMetaData([]);
      if (data?.yoga?.length)
        getYoga();
    }
  }, [currentModalData]);

  const getContent = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/content/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const getWatchedContent = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/content/watch", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const openContent = (cat, title) => {
    let catTemp = cat.split("-").join("_");
    catTemp = catTemp.split(" ").join("-");
    let temp = title.split("-").join("_");
    temp = temp.split(" ").join("-");
    window.open(`https://thebeyondlifestyle.com/app/${catTemp}/${temp}`)
  }

  const getCommunities = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/community/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const openComm = (url) => {
    window.open(url);
  }

  const getKnow = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/know/stat", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const getPodcast = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/podcast/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const getQuotes = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/quote/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  function formatMonth(monthNumber) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return 'Invalid Month';
    }
  }

  const getWell = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/wellness/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  const getYoga = () => {
    let body = {
      token: user?.token,
      userId: singleId
    }
    post("/yoga/favr", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setMetaData(res?.data);
        }
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          actionType={currentModalData?.actionType}
          data={currentModalData}
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardSubtitle className="">
                  <Row lg={12}>
                    <Col lg={2} style={{ width: 120 }}>
                      <img src={data?.profile_picture ? data?.profile_picture : require("../../assets/images/logo-sm.png")} style={{ width: 100, height: 100, borderRadius: 50, objectFit: 'contain' }} />
                    </Col>
                    <Col lg={3} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                      <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 0 }}>{data?.name}</p>
                      {data?.country &&
                        <p style={{ marginBottom: 0 }}>({data?.country})</p>
                      }
                      {data?.lang &&
                        <p style={{ marginBottom: 0 }}>Language - ({data?.lang})</p>
                      }
                    </Col>
                    <Col lg={3} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                      <p style={{ marginBottom: 0 }}>Email - <b>{data?.email}</b></p>
                      <p style={{ marginBottom: 0 }}>Plan Expiry - <b>{moment(data?.expiry).format("DD-MMM-YYYY")}</b></p>
                      <p style={{ marginBottom: 0 }}>Last Active - <b>{moment(data?.lastSeen).format("DD-MMM-YYYY hh:mm a")}</b></p>
                    </Col>
                  </Row>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
          <Row>
            {/* {data?.contents?.length ? */}
            <Col md={3} style={{ transition: '0.5s', zIndex: currentModalData == 'contentf' || currentModalData == 'contentw' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Content</h6>
                    <div style={{ marginTop: 20, }}>
                      <div onClick={() => { setCurrentModalData('contentf'); setStacked(currentModalData == 'contentf' ? !stacked : true); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">{currentModalData == 'contentf' ? 'Close' : 'Favourite'} ({data?.contents?.length ? data?.contents?.length : 0})</p>
                      </div>
                      {data?.watched?.length && currentModalData == 'contentf' || currentModalData == 'contentw' || !stacked ?
                        <div
                          onClick={() => { setCurrentModalData('contentw'); setStacked(currentModalData == 'contentw' ? !stacked : true); }}
                          style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#36486b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                          <p className="mb-0 p-2">{currentModalData == 'contentw' ? 'Close' : 'Watched'} ({data?.watched?.length ? data?.watched?.length : 0})</p>
                        </div>
                        : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.communities?.length ? */}
            <Col md={3} style={{ transition: '0.5s', transform: stacked ? 'translateX(-100%)' : 'translateX(0)', zIndex: currentModalData == 'commf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Community</h6>
                    <div style={{ marginTop: 20 }}>
                      <div onClick={() => { setCurrentModalData('commf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">{currentModalData == 'commf' ? 'Close' : 'Favourite'} ({data?.communities?.length ? data?.communities?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.knowYourself?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translateX(-200%)' : 'translateX(0)', zIndex: currentModalData == 'knowf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Know Yourself</h6>
                    <div style={{ marginTop: 20 }}>
                      <div
                        onClick={() => { setCurrentModalData('knowf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Attempts ({data?.knowYourself?.length ? data?.knowYourself?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.myDairy?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translateX(-300%)' : 'translateX(0)', zIndex: currentModalData == 'dairyf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>My Dairy</h6>
                    <div style={{ marginTop: 20 }}>
                      <div
                        onClick={() => { setCurrentModalData('dairyf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Saved ({data?.myDairy?.length ? data?.myDairy?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.podcast?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translateY(-100%)' : 'translateX(0)', zIndex: currentModalData == 'podf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Podcast</h6>
                    <div style={{ marginTop: 20 }}>
                      <div
                        onClick={() => { setCurrentModalData('podf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Favourite ({data?.podcast?.length ? data?.podcast?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.quotes?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translate(-100%, -100%)' : 'translateX(0)', zIndex: currentModalData == 'quotef' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Quotes</h6>
                    <div style={{ marginTop: 20 }}>
                      <div
                        onClick={() => { setCurrentModalData('quotef'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Favourite ({data?.quotes?.length ? data?.quotes?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.wellness?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translate(-200%, -100%)' : 'translateX(0)', zIndex: currentModalData == 'wellf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Wellness</h6>
                    <div style={{ marginTop: 20 }}>
                      <div
                        onClick={() => { setCurrentModalData('wellf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Favourite ({data?.wellness?.length ? data?.wellness?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
            {/* {data?.yoga?.length ? */}
            <Col lg={3} style={{ transition: '0.5s', transform: stacked ? 'translate(-300%, -100%)' : 'translateX(0)', zIndex: currentModalData == 'yogaf' ? 1 : 0 }}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>Yoga</h6>
                    <div style={{ marginTop: 20 }}>
                      <div onClick={() => { setCurrentModalData('yogaf'); setStacked(!stacked); }}
                        style={{ cursor: 'pointer', paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5, display: 'flex', justifyContent: 'center' }}>
                        <p className="mb-0 p-2">Favourite ({data?.yoga?.length ? data?.yoga?.length : 0})</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* : null} */}
          </Row>
          {currentModalData == 'contentf' || currentModalData == 'contentw' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>{currentModalData == 'contentf' ? 'Favourite' : ''} Contents</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10 }}>
                          <img src={item?.image} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }} onClick={() => openContent(item?.category[0]?.title, item?.title)}><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5 }}>{item?.likes}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {item?.category?.length ?
                            <span>{item?.category[0]?.title}  ({item?.type})</span>
                            : null}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                          {item?.tags?.length ?
                            <>
                              {item?.tags?.map((item, index) => (
                                <div style={{ backgroundColor: "#dfdfdf", margin: 5, padding: '5px 10px', borderRadius: 10 }}>
                                  <span>{item?.title}</span>
                                </div>
                              ))}
                            </>
                            : null}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'commf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Favourite Communities</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10 }}>
                          <img src={item?.image} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }} onClick={() => openComm(item?.url)}><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5 }}>{item?.likes}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'knowf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Know Yourself</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        {item?.category?.length ?
                          <Products source={item?.category} dated={moment(item?._id).format("DD-MMM-YYYY hh:mm a")} />
                          : null}
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'dairyf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>My Dairy</b></h5>
              <Row>
                {data?.myDairy?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <h5><b>{item?.time}</b></h5>
                        <span><b>{item?.title}</b></span><br></br>
                        <span>{item?.desc}</span>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'podf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Favourite Podcasts</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10 }}>
                          <img src={item?.image} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }}
                          // onClick={() => openComm(item?.url)}
                          ><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-circle" style={{ color: item?.isLive ? 'green' : 'red', marginRight: 5 }} />
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5, marginRight: 5 }}>{item?.likes}</span>
                            <i className="mdi mdi-eye" />
                            <span style={{ marginLeft: 5 }}>{item?.views}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'quotef' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Favourite Quotes</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10, position: 'relative' }}>
                          <img src={item?.image} style={{ width: '100%', height: '100%' }} />
                          <div style={{ position: 'absolute', bottom: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: 15 }}>
                            <div style={{ backgroundColor: '#00000077', padding: 10, borderRadius: 10, width: 80, height: 80, marginBottom: 20, lineHeight: 1.2 }}>
                              <p style={{ color: '#fff', fontSize: 12, marginBottom: 0, marginLeft: 8, fontFamily: 'Aboreto' }}>{formatMonth(new Date(item?.date).getMonth() + 1)}</p>
                              <p style={{ color: '#fff', fontSize: 45, marginBottom: 0, textAlign: 'center', fontFamily: 'Aboreto' }}>{String(new Date(item?.date).getDate()).padStart(2, '0')}</p>
                            </div>
                            <div style={{ backgroundColor: '#00000077', padding: 10, borderRadius: 10, width: '100%' }}>
                              <p style={{ color: '#fff', fontSize: 18, fontFamily: 'Raleway' }}>"{item?.quote}"</p>
                              <p style={{ color: '#fff', fontSize: 12, marginBottom: 0, fontFamily: 'Raleway' }}>-{item?.author ? item?.author : 'Unknown'}</p>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }}
                          // onClick={() => openComm(item?.url)}
                          ><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5, marginRight: 5 }}>{item?.likes}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                          {item?.tags?.length ?
                            <>
                              {item?.tags?.map((item, index) => (
                                <div style={{ backgroundColor: "#dfdfdf", margin: 5, padding: '5px 10px', borderRadius: 10 }}>
                                  <span>{item?.title}</span>
                                </div>
                              ))}
                            </>
                            : null}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'wellf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Favourite Wellness</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10 }}>
                          <img src={item?.anim} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }}
                          // onClick={() => openComm(item?.url)}
                          ><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5 }}>{item?.likes}</span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {currentModalData == 'yogaf' && stacked ?
            <div style={{ marginTop: -120 }}>
              <h5><b>Favourite Yoga</b></h5>
              <Row>
                {metaData?.map((item, index) => (
                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <div style={{ borderRadius: 5, height: 'auto', width: '100%', marginBottom: 10 }}>
                          <img src={item?.image} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <h6 style={{ marginBottom: 0, cursor: 'pointer' }}
                          // onClick={() => openComm(item?.url)}
                          ><b>{item?.title}</b></h6>
                          <div style={{ display: 'flex' }}>
                            <i className="mdi mdi-heart" />
                            <span style={{ marginLeft: 5 }}>{item?.likes}</span>
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                          {item?.tags?.length ?
                            <>
                              {item?.tags?.map((item, index) => (
                                <div style={{ backgroundColor: "#dfdfdf", margin: 5, padding: '5px 10px', borderRadius: 10 }}>
                                  <span>{item?.title}</span>
                                </div>
                              ))}
                            </>
                            : null}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            : null}
          {stacked &&
            <>
              {metaData?.length < 1 &&
                <>
                  {currentModalData != 'dairyf' &&
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                      <h4><b>No Record!</b></h4>
                    </div>
                  }
                </>
              }
            </>
          }
        </Row>
      </div>
    </React.Fragment >
  )
}

export default Table;
