import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility'
import avatar from "../../assets/images/small/sample.png"
import { post, upload, put, get, uploadS3 } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ProgressBar from "../../components/ProgressBar";

const AddContent = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const imageInput = useRef();
    const imageInputStep = useRef();
    const cropper = useRef();
    const [tags, setTags] = useState([]);
    const [cat, setCat] = useState([]);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [contentType, setContentType] = useState("");
    const [video, setVideo] = useState(null);
    const [audio, setAudio] = useState(null);
    const [infoData, setInfoData] = useState([{}]);
    const [currenFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState("file");
    const [stepIndex, setStepIndex] = useState(0);
    const [selectedLang, setSelectedLang] = useState([]);
    const [progress, setProgress] = useState(0);
    // const [progress, setProgress] = useState(0);
    // const [progLoad, setProgLoad] = useState(false);

    async function handleValidSubmit(event, values) {
        try {
            if (data) {
                if (contentType == 'Info') {
                    let imageError = infoData.findIndex(x => !x.image);
                    if (imageError != -1) {
                        toast.error(`Please Select the Step${imageError + 1} Image!`);
                        return;
                    }
                }
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let lang = [];
                selectedLang?.forEach((item) => {
                    if (item?.descContent)
                        item.descContent = undefined;
                    lang.push(item);
                });
                let body = {
                    contentId: data?._id,
                    ...values,
                    isPaid: values?.isPaid == "true" ? true : false,
                    alterLang: lang,
                    html: desc,
                    tags: selectTags,
                    token: user?.token,
                    video: undefined,
                    audio: undefined
                }
                if (file && file.substring(0, 4) == "data") {
                    const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                    const temp = await resizeFile(obj);
                    const imgresp = await uploadS3("content", temp, "webp", onProgress);
                    setProgress(0);
                    body = { ...body, image: imgresp?.data };
                }
                if (video) {
                    const image = await uploadS3("content", video, "mp4", onProgress);
                    setProgress(0);
                    body = { ...body, video: image?.data };
                }
                if (audio) {
                    const image = await uploadS3("content", audio, "mp3", onProgress);
                    setProgress(0);
                    body = { ...body, audio: image?.data };
                }
                if (contentType == 'Info' && infoData?.length > 0) {
                    let tempInfo = [];
                    await Promise.all(infoData?.map(async (item) => {
                        // console.log("_id of infoData", item?._id, item?._id?.length);
                        if (item?._id?.length == 6)
                            item._id = undefined;
                        if (item?.image && item?.image.substring(0, 4) == "data") {
                            const obj = await urltoFile(item?.image, new Date().getTime() + '.png', 'image/png');
                            const temp = await resizeFile(obj);
                            const image = await uploadS3("content", temp, "webp", onProgress);
                            setProgress(0);
                            tempInfo.push({ ...item, image: image.data });
                        } else {
                            tempInfo.push(item);
                        }
                    }));
                    body = { ...body, infoData: tempInfo };
                }
                setLoading(true);
                console.log('body after image upload', body);
                put("/content", body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                if (!file) {
                    toast.error('Please select the Image!');
                    return;
                }
                if (selectedTags.length < 1) {
                    toast.error('Please enter atleast one tag!');
                    return;
                }
                if (contentType == 'Text' && !desc) {
                    toast.error('Please enter something in HTML Editor!');
                    return;
                }
                if (contentType == 'Info') {
                    let imageError = infoData.findIndex(x => !x.image);
                    if (imageError != -1) {
                        toast.error(`Please Select the Step${imageError + 1} Image!`);
                        return;
                    }
                }
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let lang = [];
                selectedLang?.forEach((item) => {
                    if (item?.descContent)
                        item.descContent = undefined;
                    lang.push(item);
                });
                let body = {
                    ...values,
                    isPaid: values?.isPaid == "true" ? true : false,
                    alterLang: lang,
                    html: desc,
                    tags: selectTags,
                    token: user?.token,
                }
                if (file && file.substring(0, 4) == "data") {
                    const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                    const temp = await resizeFile(obj);
                    const image = await uploadS3("content", temp, "webp", onProgress);
                    setProgress(0);
                    body = { ...body, image: image?.data };
                }
                if (video) {
                    const image = await uploadS3("content", video, "mp4", onProgress);
                    setProgress(0);
                    body = { ...body, video: image?.data };
                }
                if (audio) {
                    const image = await uploadS3("content", audio, "mp4", onProgress);
                    setProgress(0);
                    body = { ...body, audio: image?.data };
                }
                if (contentType == 'Info' && infoData?.length > 0) {
                    let tempInfo = [];
                    await Promise.all(infoData?.map(async (item) => {
                        if (item?._id?.length == 6)
                            item._id = undefined;
                        if (item?.image && item?.image.substring(0, 4) == "data") {
                            const obj = await urltoFile(item?.image, new Date().getTime() + '.png', 'image/png');
                            const temp = await resizeFile(obj);
                            const image = await uploadS3("content", temp, "image/webp", onProgress);
                            setProgress(0);
                            tempInfo.push({ ...item, image: image.data });
                        } else {
                            tempInfo.push(item);
                        }
                    }));
                    body = { ...body, infoData: tempInfo };
                }
                setLoading(true);
                post("/content", body)
                    .then((json) => {
                        console.log('response from adding product', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    })
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.html) {
                const contentBlock = htmlToDraft(data?.html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.html);
                }
            }
            if (data?.image)
                setFile(data?.image);
            if (data?.tags?.length) {
                let temp = [];
                data?.tags?.map((item) => {
                    let found = tags.find(x => x?._id == item);
                    if (found)
                        temp.push(found);
                });
                setSelectedTags(temp);
            }
            if (data?.type)
                setContentType(data?.type);
            if (data?.alterLang?.length) {
                data?.alterLang?.forEach((lang) => {
                    user?.languages?.forEach((allLang) => {
                        if (allLang?.code == lang?.code)
                            lang.lang = allLang?.title;
                    });
                    if (lang?.html) {
                        const contentBlock = htmlToDraft(lang?.html);
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            lang.descContent = editorState;
                        }
                    }
                });
                setSelectedLang(data?.alterLang);
            }
            if (data?.infoData?.length > 0) {
                // console.log("data infodata has length", user?.languages?.length);
                data?.infoData?.forEach((info) => {
                    info?.alterLang?.forEach((lang) => {
                        user?.languages?.forEach((allLang) => {
                            if (allLang?.code == lang?.code)
                                lang.lang = allLang?.title;
                        })
                    })
                })
                setInfoData(data?.infoData);
            }
        }
    }, [data, tags, user]);

    useEffect(() => {
        if (user) {
            get('/category', { token: user?.token })
                .then(res => {
                    setCat(res?.data);
                })
            get('/tags', { token: user?.token })
                .then(res => {
                    setTags(res?.data);
                })
        }
    }, [user]);

    useEffect(() => {
        if (contentType == "Info") {
            let tempInfo = Object.assign([], infoData);
            selectedLang?.map((lang) => {
                tempInfo.forEach(item => {
                    if (item.alterLang?.length) {
                        let found = item?.alterLang?.findIndex(x => x?.code == lang?.code);
                        if (found == -1)
                            item.alterLang.push({ code: lang?.code, lang: lang?.lang, desc: "" });
                    } else
                        item.alterLang = [{ code: lang?.code, lang: lang?.lang, desc: "" }];
                });
            });
            setInfoData(tempInfo);
        }
    }, [contentType])

    const onChangeFile = async (e, type, index) => {
        setCurrentFileType(type);
        setStepIndex(index);
        if (type == 'video') {
            if (e.target.files[0]?.size < 30 * 1024 * 1024) {
                setVideo(e.target.files[0]);
            } else
                toast.error("Video size can't be greater than 30 mb!");
        } else if (type == 'audio') {
            setAudio(e.target.files[0]);
        } else {
            console.log("File", e.target.files[0]);
            if (e.target.files[0]?.size < 15 * 1024 * 1024) {
                setCurrentFile(URL.createObjectURL(e.target.files[0]));
                setCropperModal(true);
            } else
                toast.error("Image size can't be greater than 15 mb!");
        }
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(`<span style="color: rgb(0,0,0);">${html}</span>`);
        setDescContent(editorState);
    }

    const onEditorStateChangeAlter = (editorState, i) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let alter = Object.assign([], selectedLang);
        alter[i].descContent = editorState;
        alter[i].html = html;
        setSelectedLang(alter);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentFileType == 'file')
            setFile(fileData);
        else {
            let temp = Object.assign([], infoData);
            temp[stepIndex].image = fileData;
            setInfoData(temp);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const onProgress = (p) => {
        setProgress(p);
    }

    const handleAddMoreStep = () => {
        let temp = Object.assign([], infoData);
        if (selectedLang?.length) {
            let alterLang = [];
            selectedLang.forEach((item) => {
                alterLang.push({ code: item?.code, lang: item?.lang, desc: "" });
            });
            temp.push({ alterLang });
        } else
            temp.push({ _id: String(Math.floor(100000 + Math.random() * 900000)) });
        setInfoData(temp);
    }

    const handleRemoveStep = (index) => {
        let temp = Object.assign([], infoData);
        temp.splice(index, 1);
        console.log('infoData after remove one', temp);
        setInfoData(temp);
    }

    const onChangeDesc = (val, index) => {
        let temp = Object.assign([], infoData);
        temp[index].desc = val;
        setInfoData(temp);
    }

    const onChangeDescAlter = (val, index, ind) => {
        let temp = Object.assign([], infoData);
        temp[index].alterLang[ind].desc = val;
        setInfoData(temp);
    }

    const handleSelectLang = (code, lang) => {
        if (code != "en") {
            let temp = Object.assign([], selectedLang);
            let already = temp.findIndex(x => x.code == code);
            if (already == -1) {
                temp.push({ code: code, title: "", lang });
                if (contentType == "Info") {
                    let tempInfo = Object.assign([], infoData);
                    tempInfo.forEach(item => {
                        if (item.alterLang?.length)
                            item.alterLang.push({ code, lang, desc: "" });
                        else
                            item.alterLang = [{ code, lang, desc: "" }];
                    });
                    setInfoData(tempInfo);
                }
            } else {
                temp.splice(already, 1);
                if (contentType == "Info") {
                    let tempInfo = Object.assign([], infoData);
                    tempInfo.forEach(item => {
                        let found = item.alterLang.findIndex(x => x.code == code);
                        if (found != -1)
                            item.alterLang.splice(found, 1);
                    });
                    setInfoData(tempInfo);
                }
            }
            // console.log("temp", temp);
            setSelectedLang(temp);
        }
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = [...infoData];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        let ids = [], position = [];
        items.forEach((item, index) => {
            ids.push(item?._id);
            position.push(index);
        })
        setInfoData(items);
    };


    // console.log("changing step desc", selectedLang);
    const toolbarOptions = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        inline: {
            inDropdown: true,
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Courier New', 'Comic Sans MS', 'Lucida Console', 'Montserrat'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
    };

    return (
        <React.Fragment>
            <div>
                <ProgressBar
                    visible={progress < 1 ? false : true}
                    progress={progress}
                />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <img
                                                            src={file ? file : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => setFile(null)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <>
                                                    <Col lg={2} md={2}>
                                                        <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                            <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                                onClick={(e) => imageInput.current.click(e)}
                                                            >
                                                                <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                >
                                                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                                <input type="file" id="file"
                                                                    ref={imageInput}
                                                                    onChange={(e) => onChangeFile(e, 'file')}
                                                                    style={{ display: "none" }} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <span>Max image size - 15mb</span>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currenFile}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setCurrentFile(null); setStepIndex(0); }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>
                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="catId"
                                            label="Category *"
                                            className="form-control"
                                            type="select"
                                            value={data?.catId}
                                            required
                                        >
                                            <option value={""} >Select Category</option>
                                            {cat?.map((item) => (
                                                <option value={item?._id} >{item?.title}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <Label>Tags *</Label>
                                        <Multiselect
                                            options={tags} // Options to display in the dropdown
                                            selectedValues={selectedTags} // Preselected value to persist in dropdown
                                            onSelect={(list, item) => setSelectedTags(list)} // Function will trigger on select event
                                            onRemove={(list, item) => setSelectedTags(list)} // Function will trigger on remove event
                                            displayValue="title" // Property name to display in the dropdown options
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="isPaid"
                                            label="Content Paid Type *"
                                            className="form-control"
                                            type="select"
                                            value={data ? String(data?.isPaid) : ""}
                                            required
                                        >
                                            <option value={""}>Select Paid Type</option>
                                            <option value={"false"} >Free</option>
                                            <option value={"true"} >Paid</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="type"
                                            label="Content Type *"
                                            className="form-control"
                                            type="select"
                                            value={contentType}
                                            onChange={(e) => setContentType(e.target.value)}
                                            required
                                        >
                                            <option value={""} >Select Content Type</option>
                                            <option value={"Info"}>Infographic</option>
                                            <option value={"Video"}>Video</option>
                                            <option value={"Text"}>Text</option>
                                            <option value={"Audio"}>Audio</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title*"
                                            value={data?.title}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="desc"
                                            label="Description*"
                                            className="form-control"
                                            type="textarea"
                                            value={data?.desc}
                                            required
                                            rows={3}
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                            </Row>
                            <Label>Alternative Languages</Label>
                            <Row>
                                <div className='lang-checks mb-3'>
                                    {user?.languages?.map((item) => (
                                        <div className='check-group'>
                                            <label for={item?.title} class="container">
                                                {item?.title}
                                                <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {selectedLang?.map((item, index) => (
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"title" + item?.lang}
                                                    label={"Title in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter title in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                    required
                                                    value={item?.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"desc" + item?.lang}
                                                    label={"Description in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter description in " + item?.lang}
                                                    type="textarea"
                                                    rows={3}
                                                    onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                                                    required
                                                    value={item?.desc}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            {contentType == 'Text' &&
                                <Row>
                                    <div className="form-group mb-4">
                                        <label>Description*</label>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(e) => onEditorStateChange(e)}
                                            editorState={descContent}
                                            toolbar={toolbarOptions}
                                        />
                                    </div>
                                    {selectedLang?.map((item, index) => (
                                        <div className="form-group mb-4">
                                            <label>Description in {item?.lang}*</label>
                                            <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={(e) => onEditorStateChangeAlter(e, index)}
                                                editorState={item.descContent}
                                                toolbar={toolbarOptions}
                                            />
                                        </div>
                                    ))}
                                </Row>
                            }
                            {contentType == 'Video' &&
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            className="form-control"
                                            type="file"
                                            name="video"
                                            label="Video File *"
                                            onChange={(e) => onChangeFile(e, 'video')}
                                            accept='video/mp4'
                                            style={{}}
                                            required={data ? false : true}
                                        />
                                    </div>
                                </Col>
                            }
                            {contentType == 'Audio' &&
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            className="form-control"
                                            type="file"
                                            name="audio"
                                            label="Audio File *"
                                            onChange={(e) => onChangeFile(e, 'audio')}
                                            accept='audio/mp3'
                                            style={{}}
                                            required={data ? false : true}
                                        />
                                    </div>
                                </Col>
                            }
                            {contentType == 'Info' &&
                                <div>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="table">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                        {infoData?.map((item, index) => (
                                                            <>
                                                                <div style={{ width: '100%', height: 1, backgroundColor: Utils.themeColor, marginBottom: 10 }}></div>
                                                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className='d-flex align-items-center'
                                                                        >
                                                                            <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
                                                                            <Label className="mb-0 mx-2">Step {index + 1}</Label>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                                {item?.image ?
                                                                    <Col lg={2} md={2} style={{}}>
                                                                        <div className="me-3 position-relative" style={{ width: 100, height: 100 }}>
                                                                            <img
                                                                                src={item?.image ? item?.image : avatar}
                                                                                alt=""
                                                                                style={{ width: '100%', height: '100%' }}
                                                                            />
                                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                                onClick={(e) => setFile(null)}
                                                                            >
                                                                                <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    <Col lg={2} md={2}>
                                                                        <div className="me-3 position-relative" style={{ width: 100, height: 100 }}>
                                                                            <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                                                onClick={(e) => imageInputStep.current.click(e)}
                                                                            >
                                                                                <button type="button" style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                                                <div style={{ position: 'absolute', height: 30, width: 30, right: -10, top: -10, paddingTop: 3, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                                >
                                                                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 16 }} />
                                                                                </div>
                                                                                <input type="file" id={"image" + index}
                                                                                    ref={imageInputStep}
                                                                                    onChange={(e) => onChangeFile(e, 'step', index)}
                                                                                    style={{ display: "none" }} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                <Col md={9}>
                                                                    <div className="form-group mb-4">
                                                                        <AvField
                                                                            className="form-control"
                                                                            type="textarea"
                                                                            name={"desc" + index}
                                                                            label="Description *"
                                                                            value={item?.desc}
                                                                            onChange={(e) => onChangeDesc(e.target.value, index)}
                                                                            style={{}}
                                                                            rows={4}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    {item?.alterLang?.map((lang, ind) => (
                                                                        <div className="form-group mb-4">
                                                                            <AvField
                                                                                className="form-control"
                                                                                type="textarea"
                                                                                name={"desc" + index + "-" + ind}
                                                                                label={`Description in ${lang?.lang}*`}
                                                                                value={lang?.desc}
                                                                                onChange={(e) => onChangeDescAlter(e.target.value, index, ind)}
                                                                                style={{}}
                                                                                rows={4}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Col>
                                                                {infoData?.length > 1 &&
                                                                    <Col md={1}>
                                                                        <Button type="button" style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                                                            onClick={() => handleRemoveStep(index)}
                                                                        >
                                                                            <i className='mdi mdi-delete' />
                                                                        </Button>
                                                                    </Col>
                                                                }
                                                            </>
                                                        ))}
                                                    </Row>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Button type="button" style={{ border: 'none', width: 130, float: 'right', backgroundColor: Utils.themeColor }}
                                        onClick={handleAddMoreStep}
                                    >
                                        + Add More Step
                                    </Button>
                                </div>
                            }

                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddContent.propTypes = {

}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddContent)
)
