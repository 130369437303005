import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility';
import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';


const AddWellness = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [animFile, setAnimFile] = useState(null);
    const [audio, setAudio] = useState(null);
    const [files, setFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const imageInput = useRef();
    const imageInputAnim = useRef();
    const cropper = useRef();
    const [cropperModal, setCropperModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [selectedLang, setSelectedLang] = useState([]);
    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values);
            if (data) {
                if (!audio) {
                    toast.error("Audio file is required!");
                    return;
                }
                if (selectedTags.length < 1) {
                    toast.error('Please enter atleast one tag!');
                    return;
                }
                setLoading(true);
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let body = {
                    ...values,
                    wellnessId: data?._id,
                    tags: selectTags,
                    alterLang: selectedLang,
                    token: user?.token,
                    duration: { hour: values?.hour, minute: values?.minute, second: values?.second }
                }
                console.log('image file', file);
                if (file.substring(0, 4) == 'data') {
                    const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                    const temp = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", temp);
                    const imageRes = await upload("/wellness/image_upload", form);
                    body.image = imageRes?.data;
                }
                if (animFile.substring(0, 4) == 'blob') {
                    const obj2 = await urltoFile(animFile, new Date().getTime() + '.gif', 'image/gif');
                    const form2 = new FormData();
                    form2.append("image", obj2);
                    const imageRes2 = await upload("/wellness/image_upload", form2);
                    body.anim = imageRes2?.data;
                }
                if (audio.substring(0, 4) == 'blob') {
                    const obj3 = await urltoFile(audio, new Date().getTime() + '.mp3', 'audio/mpeg');
                    const form3 = new FormData();
                    form3.append("image", obj3);
                    const audioRes = await upload("/wellness/image_upload", form3);
                    body.audio = audioRes?.data;
                }
                let url = "/wellness/update";
                console.log('body after upload images', body);
                put(url, body)
                    .then((json) => {
                        console.log('response from adding comp', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('property added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            } else {
                if (!file) {
                    toast.error("Placeholder image is required!");
                    return;
                }
                if (!animFile) {
                    toast.error("Gif image is required!");
                    return;
                }
                if (selectedTags.length < 1) {
                    toast.error('Please enter atleast one tag!');
                    return;
                }
                setLoading(true);
                let selectTags = [];
                selectedTags.forEach(item => {
                    selectTags.push(item?._id);
                });
                let image = "", anim = "", audioUrl = "";
                const obj = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(obj);
                const form = new FormData();
                form.append("image", temp);
                const imageRes = await upload("/wellness/image_upload", form);
                image = imageRes?.data;
                const obj2 = await urltoFile(animFile, new Date().getTime() + '.gif', 'image/gif');
                const form2 = new FormData();
                form2.append("image", obj2);
                const imageRes2 = await upload("/wellness/image_upload", form2);
                anim = imageRes2?.data;
                const obj3 = await urltoFile(audio, new Date().getTime() + '.mp3', 'audio/mpeg');
                const form3 = new FormData();
                form3.append("image", obj3);
                const audioRes = await upload("/wellness/image_upload", form3);
                audioUrl = audioRes?.data;
                console.log('url after upload images', image);
                let body = {
                    ...values,
                    token: user?.token,
                    image,
                    anim,
                    audio: audioUrl,
                    alterLang: selectedLang,
                    tags: selectTags,
                    duration: { hour: values?.hour, minute: values?.minute, second: values?.second }
                }
                console.log('body before adding wellness', body);
                let url = "/wellness/add";
                console.log('body after upload images', body);
                post(url, body)
                    .then((json) => {
                        console.log('response from adding product', json);
                        setLoading(false);
                        if (json.statusCode == 200) {
                            console.log('product added');
                            toast.success(json?.message);
                            props.close(false);
                        } else {
                            toast.error(json?.error);
                        }
                    })
                    .catch(error => {
                        console.error('error while adding oppor', error);
                        toast.error(JSON.stringify(error));
                        setLoading(false);
                    });
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.image)
                setFile(data?.image);
            if (data?.anim)
                setAnimFile(data?.anim);
            if (data?.audio)
                setAudio(data?.audio);
            if (data?.tags?.length) {
                let temp = [];
                data?.tags?.map((item) => {
                    let found = tags.find(x => x?._id == item);
                    if (found)
                        temp.push(found);
                });
                setSelectedTags(temp);
            }
            if (data?.alterLang?.length)
                setSelectedLang(data?.alterLang);
        }
    }, [data, tags]);

    useEffect(() => {
        get('/tags', { token: user?.token })
            .then(res => {
                setTags(res?.data);
            })
    }, []);

    const onChangeFile = async (e, type) => {
        if (type == 'file') {
            setCurrentFile(URL.createObjectURL(e.target.files[0]));
            setCropperModal(true);
        } else {
            setAnimFile(URL.createObjectURL(e.target.files[0]));
        }
    }

    const removeImage = () => {
        setFile(null);
        setCurrentFile(null);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        setFile(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleSelectLang = (code, lang) => {
        if (code != "en") {
            let temp = Object.assign([], selectedLang);
            let already = temp.findIndex(x => x.code == code);
            if (already == -1) {
                temp.push({ code: code, title: "", desc: "", lang });
            } else {
                temp.splice(already, 1);
            }
            // console.log("temp", temp);
            setSelectedLang(temp);
        }
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={3} md={3} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 384 }}>
                                                        <img
                                                            src={file ? file : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage()}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={3} md={3}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 384 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add placeholder</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput}
                                                                // accept='image/*'
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                            {animFile ?
                                                <Col lg={3} md={3} style={{ marginLeft: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 384 }}>
                                                        <img
                                                            src={animFile ? animFile : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => setAnimFile(null)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={3} md={3}>
                                                    <div className="me-3 position-relative" style={{ width: 216, height: 384 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => imageInputAnim.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add Gif</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="anim"
                                                                ref={imageInputAnim}
                                                                onChange={(e) => onChangeFile(e, 'anim')}
                                                                accept='image/gif'
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={9 / 16}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <Label>Tags *</Label>
                                        <Multiselect
                                            options={tags} // Options to display in the dropdown
                                            selectedValues={selectedTags} // Preselected value to persist in dropdown
                                            onSelect={(list, item) => setSelectedTags(list)} // Function will trigger on select event
                                            // onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="title" // Property name to display in the dropdown options
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="audio2"
                                            label="Audio *"
                                            // value={data?.audio}
                                            className="form-control"
                                            type="file"
                                            accept="audio/mpeg"
                                            onChange={(e) => setAudio(URL.createObjectURL(e.target.files[0]))}
                                            required={data ? false : true}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Row md={12}>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="hour"
                                                    label="Hours *"
                                                    className="form-control"
                                                    value={data?.duration?.hour}
                                                    type="number"
                                                    validate={{ min: { value: 0 }, max: { value: 12 } }}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="minute"
                                                    label="Minutes *"
                                                    className="form-control"
                                                    value={data?.duration?.minute}
                                                    type="number"
                                                    validate={{ min: { value: 0 }, max: { value: 59 } }}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="second"
                                                    label="Seconds *"
                                                    className="form-control"
                                                    value={data?.duration?.second}
                                                    validate={{ min: { value: 0 }, max: { value: 59 } }}
                                                    type="number"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            value={data?.title}
                                            className="form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="desc"
                                            label="Short Description *"
                                            value={data?.desc}
                                            className="form-control"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Label>Alternative Languages</Label>
                            <Row>
                                <div className='lang-checks mb-3'>
                                    {user?.languages?.map((item) => (
                                        <div className='check-group'>
                                            <label for={item?.title} class="container">
                                                {item?.title}
                                                <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {selectedLang?.map((item, index) => (
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"title" + item?.lang}
                                                    label={"Title in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter title in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                                                    required
                                                    value={item?.title}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name={"desc" + item?.lang}
                                                    label={"Short Description in " + item?.lang}
                                                    className="form-control"
                                                    placeholder={"Enter short description in " + item?.lang}
                                                    type="text"
                                                    onChange={(e) => handleAlterTitle(index, "desc", e.target.value)}
                                                    required
                                                    value={item?.desc}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddWellness.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddWellness)
)
