import React from "react"
import PropTypes from 'prop-types'
import { FormGroup } from "reactstrap"

import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link } from "react-router-dom";
import Utils from "../../pages/Utility"

import "./rightbar.scss"

//Import images
import layout1 from "../../assets/images/layouts/layout-1.jpg"
import layout2 from "../../assets/images/layouts/layout-2.jpg"
import layout3 from "../../assets/images/layouts/layout-3.jpg";

import { updateCompany } from '../../store/company/actions';

const RightSidebar = props => {

  const changeCompanyTheme = (color) => {
    // console.log('chaning color', color);
    if (color != props?.user?.company?.theme)
      props.updateCompany({ companyId: props.user?.companyId, theme: color, token: props?.user?.token, addedBy: props?.user?._id })
  }


  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
              <h5 className="m-0 me-2">Settings</h5>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showRightSidebarAction(false)
                }}
                className="right-bar-toggle ms-auto"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts</span>
                <input
                  type="radio"
                  id="radioVertical"
                  name="radioFruit"
                  value="detached"
                  checked={props.layoutType === "detached"}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayout(e.target.value)
                    }
                  }}
                />
                {props.layoutType == "detached" ?
                  <label htmlFor="radioVertical" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Vertical</label>
                  :
                  <label htmlFor="radioVertical">Vertical</label>
                }
                {" "}
                <input
                  type="radio"
                  id="radioHorizontal"
                  name="radioFruit"
                  value="horizontal"
                  checked={props.layoutType === "horizontal"}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayout(e.target.value)
                    }
                  }}
                />
                {props.layoutType === "horizontal" ?
                  <label htmlFor="radioHorizontal" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Horizontal</label>
                  :
                  <label htmlFor="radioHorizontal">Horizontal</label>
                }
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Layout Width
                </span>
                <input
                  type="radio"
                  id="radioFluid"
                  name="radioWidth"
                  value="fluid"
                  checked={props.layoutWidth === "fluid"}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutWidth(e.target.value)
                    }
                  }}
                />
                {props.layoutWidth == "fluid" ?
                  <label htmlFor="radioFluid" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Fluid</label>
                  :
                  <label htmlFor="radioFluid">Fluid</label>
                }
                {"   "}
                <input
                  type="radio"
                  id="radioBoxed"
                  name="radioWidth"
                  value="boxed"
                  checked={props.layoutWidth === "boxed"}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutWidth(e.target.value)
                    }
                  }}
                />
                {props.layoutWidth === "boxed" ?
                  <label htmlFor="radioBoxed" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Boxed</label>
                  :
                  <label htmlFor="radioBoxed">Boxed</label>
                }

              </div>

              {/* {props?.user?.role == 'Admin' &&
              <>
                <hr className="mt-1" />
                <div className="radio-toolbar">
                  <span className="mb-2 d-block" id="radio-title">
                    Topbar Theme
                  </span>
                  <input
                    type="color"
                    id="radioThemeDark"
                    name="radioTheme"
                    // defaultValue={props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor}
                    value={props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor}
                    // value={Utils.themeColor}
                    onChange={e => {
                      changeCompanyTheme(e.target.value);
                    }}
                  />
                </div>
                </>
              } */}

              {props.layoutType === "detached" ? (
                <React.Fragment>
                  <hr className="mt-1" />
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Type{" "}
                    </span>
                    <input
                      type="radio"
                      id="sidebarDefault"
                      name="sidebarType"
                      value="default"
                      checked={props.leftSideBarType === "default"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    {props.leftSideBarType === "default" ?
                      <label htmlFor="sidebarDefault" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Default</label>
                      :
                      <label htmlFor="sidebarDefault">Default</label>
                    }
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarCompact"
                      name="sidebarType"
                      value="compact"
                      checked={props.leftSideBarType === "compact"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    {props.leftSideBarType === "compact" ?
                      <label htmlFor="sidebarCompact" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Compact</label>
                      :
                      <label htmlFor="sidebarCompact">Compact</label>
                    }
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarIcon"
                      name="sidebarType"
                      value="icon"
                      checked={props.leftSideBarType === "icon"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarType(e.target.value)
                        }
                      }}
                    />
                    {props.leftSideBarType === "icon" ?
                      <label htmlFor="sidebarIcon" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Icon</label>
                      :
                      <label htmlFor="sidebarIcon">Icon</label>
                    }
                  </div>

                  <hr className="mt-1" />

                  {/* <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Color
                    </span>
                    <input
                      type="radio"
                      id="leftsidebarThemelight"
                      name="leftsidebarTheme"
                      value="light"
                      checked={props.leftSideBarTheme === "light"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value)
                        }
                      }}
                    />
                    {props.leftSideBarTheme === "light" ?
                      <label htmlFor="leftsidebarThemelight" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Light</label>
                      :
                      <label htmlFor="leftsidebarThemelight">Light</label>
                    }
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemedark"
                      name="leftsidebarTheme"
                      value="dark"
                      checked={props.leftSideBarTheme === "dark"}
                      onChange={e => {
                        if (e.target.checked) {
                          props.changeSidebarTheme(e.target.value)
                        }
                      }}
                    />
                    {props.leftSideBarTheme === "dark" ?
                      <label htmlFor="leftsidebarThemedark" style={{ backgroundColor: props?.user?.company?.theme ? props?.user?.company?.theme : Utils.themeColor }}>Dark</label>
                      :
                      <label htmlFor="leftsidebarThemedark">Dark</label>
                    }
                  </div>
                  <hr className="mt-1" /> */}
                </React.Fragment>
              ) : null}

              {/* <hr className="mt-1" /> */}

            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  updateCompany: PropTypes.func
}

const mapStateToProps = state => {
  return { ...state.Layout }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
  updateCompany
})(RightSidebar)
