import React from 'react'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Utils from '../Utility';
import { Card, CardBody, Row } from 'reactstrap';
export default function Guide() {
    return (
        <React.Fragment>
            <div className="page-content">

                {/* Render Breadcrumb */}
                <Breadcrumb title={Utils.projectName} breadcrumbItem="Guide" />
                <Card>
                    <CardBody>
                        <Row>
                            <div className='simple-content-design-page'>
                                <h4>Wellness</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1920 / Max Size: 2-3 MB</li>
                                    <li>GIF Size: W 1080* H 1920 / Max Size: 8-10 MB</li>
                                </ul>

                                <h4>Quotes</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1920 / Max Size: 2-3 MB</li>
                                </ul>

                                <h4>Popup</h4>
                                <ul>
                                    <li>Image Size: W 506* H 900 / Max Size: 1 MB</li>
                                </ul>

                                <h4>Yoga</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1080 / Max Size: 1 MB</li>
                                </ul>

                                <h4>Harmony Moods</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1920 / Max Size: 2-3 MB</li>
                                    <li>Thumb SVG Size: W 100* H 100 / Max Size: less than 1 MB</li>
                                    <li>Sound: max 1-5 MB</li>
                                </ul>

                                <h4>Know yourself Introduction</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1080 / Max Size: 1 MB</li>
                                </ul>

                                <h4>Category</h4>
                                <ul>
                                    <li>Thumb Size: W 100* H 100 / Max Size: less than 200 KB</li>
                                </ul>

                                <h4>Content</h4>
                                <ul>
                                    <li>Thumb Size: W 1080* H 1080 / Max Size: 1 MB</li>
                                    <li>Video Size: W 1920* H 1080 / Max Size: 10-25 MB</li>
                                    <li>Audio Size: 10-25 MB</li>
                                    <li>Image Size: W 1080* H 1920 / Max Size: 2-3 MB</li>
                                </ul>

                                <h4>Game</h4>
                                <ul>
                                    <li>Thumb Size: W 100* H 100 / Max Size: less than 1 MB</li>
                                </ul>

                                <h4>Community</h4>
                                <ul>
                                    <li>Thumb Size: W 1080* H 608 / Max Size: less than 1 MB</li>
                                </ul>

                                <h4>Live Session</h4>
                                <ul>
                                    <li>Thumb Size: W 1080* H 608 / Max Size: less than 1 MB</li>
                                </ul>

                                <h4>Podcast</h4>
                                <ul>
                                    <li>Image Size: W 1080* H 1080 / Max Size: 1 MB</li>
                                </ul>

                                <h4>Plan</h4>
                                <ul>
                                    <li>Thumb Size: W 100* H 100 / Max Size: less than 200 KB</li>
                                </ul>

                                <h4>Blog</h4>
                                <ul>
                                    <li>Thumb Size: W 400* H 400 / Max Size: 500 KB</li>
                                    <li>Image Size: W 444* H 595 / Max Size: 1 MB</li>
                                </ul>

                                <h4>Notifications</h4>
                                <ul>
                                    <li>Thumb Size: W 1296* H 639 / Max Size: less than 500 KB</li>
                                </ul>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}
