import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';

const AddProperty = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const formRef = useRef();
    const imageInput = useRef();
    const cropper = useRef();
    const [weather, setWeather] = useState([]);
    const [tags, setTags] = useState([]);
    const [cropperModal, setCropperModal] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [quote, setQuote] = useState("");
    const [author, setAuthor] = useState("");
    const [date, setDate] = useState(new Date());
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedLang, setSelectedLang] = useState([]);
    const [countries, setCountries] = useState([]);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', values);
            if (file) {
                setLoading(true);
                if (data?._id) {
                    let imageUrl = file;
                    if (file.substring(0, 4) == 'data') {
                        const obj = await urltoFile(file, new Date().getTime() + '.jpg', 'image/jpg');
                        const temp = await resizeFile(obj);
                        const form = new FormData();
                        form.append("image", temp);
                        const image = await upload("/quote/image_upload", form);
                        imageUrl = image?.data
                    }
                    let tempTags = [];
                    selectedTags.forEach((item) => {
                        tempTags.push(item?._id);
                    })
                    let body = {
                        quoteId: data?._id,
                        ...values,
                        date,
                        tags: tempTags,
                        alterLang: selectedLang,
                        image: imageUrl,
                        token: user?.token,
                    }
                    let url = "/quote/update";
                    console.log('body after upload images', body);
                    put(url, body)
                        .then((json) => {
                            console.log('response from adding comp', json);
                            setLoading(false);
                            if (json.statusCode == 200) {
                                console.log('property updated');
                                toast.success(json?.message);
                                props.close(false);
                            } else {
                                toast.error(json?.error);
                            }
                        })
                        .catch(error => {
                            console.error('error while adding oppor', error);
                            toast.error(JSON.stringify(error));
                            setLoading(false);
                        });
                } else {
                    const obj = await urltoFile(file, new Date().getTime() + '.jpg', 'image/jpg');
                    const temp = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", temp);
                    const image = await upload("/quote/image_upload", form);
                    let tempTags = [];
                    selectedTags.forEach((item) => {
                        tempTags.push(item?._id);
                    })
                    let body = {
                        ...values,
                        date,
                        tags: tempTags,
                        type: role,
                        alterLang: selectedLang,
                        token: user?.token,
                        image: image?.data
                    }
                    console.log('body after upload images', body);
                    post("/quote/add", body)
                        .then((json) => {
                            console.log('response from adding quote', json);
                            setLoading(false);
                            if (json.statusCode == 200) {
                                console.log('product added');
                                toast.success(json?.message);
                                props.close(false);
                            } else {
                                toast.error(json?.error);
                            }
                        })
                        .catch(error => {
                            console.error('error while adding oppor', error);
                            toast.error(JSON.stringify(error));
                            setLoading(false);
                        })

                }
            } else {
                toast.error('Image is required!');
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        if (data?.image)
            setFile(data?.image);
        if (data?.quote)
            setQuote(data?.quote);
        if (data?.author)
            setAuthor(data?.author);
        if (data?.date)
            setDate(new Date(data?.date));
        if (data?.alterLang?.length) {
            let temp = [];
            data?.alterLang?.forEach(element => {
                user?.languages?.forEach((item) => {
                    if (element?.code == item?.code)
                        temp.push({ ...element, lang: item?.title });
                })
            });
            setSelectedLang(temp);
        }
    }, [data, user]);

    useEffect(() => {
        if (user?.token) {
            get('/tags/list', { token: user?.token })
                .then(res => {
                    setTags(res?.data);
                });
            get('/category/country', { token: user?.token })
                .then(res => {
                    setCountries(res?.data);
                });
        }
    }, [user]);

    useEffect(() => {
        if (data && tags?.length) {
            if (data?.tags?.length) {
                let temp = [];
                data?.tags?.forEach((item) => {
                    let found = tags.find(x => x?._id == item);
                    if (found)
                        temp.push({ _id: item, title: found?.title })
                });
                setSelectedTags(temp);
            }
        }
    }, [data, tags])

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        setFile(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1080,
                1920,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    function formatMonth(monthNumber) {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return 'Invalid Month';
        }
    }

    const handleSelectLang = (code, lang) => {
        if (code != "en") {
            let temp = Object.assign([], selectedLang);
            let already = temp.findIndex(x => x.code == code);
            if (already == -1) {
                temp.push({ code: code, quote: "", author: "", lang });
            } else {
                temp.splice(already, 1);
            }
            // console.log("temp", temp);
            setSelectedLang(temp);
        }
    }

    const handleAlterTitle = (index, key, val) => {
        let temp = Object.assign([], selectedLang);
        temp[index][key] = val;
        setSelectedLang(temp);
    }

    // console.log('checking value', user?.languages, selectedLang);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <h4 className="card-title">{data ? 'Update ' + role : 'Add a ' + role} Quote</h4>
                                        <span>This quote will show all over the universe with high priority.</span>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={9 / 16}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <Card>
                    <CardBody>
                        <Row className='p-3'>
                            {file ?
                                <Col lg={6} md={6} style={{}}>
                                    <div className="me-3 position-relative" style={{ width: 315, height: 560 }}>
                                        <img
                                            src={file ? file : avatar}
                                            alt=""
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            onClick={(e) => setFile(null)}
                                        >
                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', padding: 15 }}>
                                            <div style={{ backgroundColor: '#00000077', padding: 10, borderRadius: 10, width: 80, height: 80, marginBottom: 20, lineHeight: 1.2 }}>
                                                <p style={{ color: '#fff', fontSize: 12, marginBottom: 0, marginLeft: 8, fontFamily: 'Aboreto' }}>{formatMonth(new Date(date).getMonth() + 1)}</p>
                                                <p style={{ color: '#fff', fontSize: 45, marginBottom: 0, textAlign: 'center', fontFamily: 'Aboreto' }}>{String(new Date(date).getDate()).padStart(2, '0')}</p>
                                            </div>
                                            <div style={{ backgroundColor: '#00000077', padding: 10, borderRadius: 10, width: '100%' }}>
                                                <p style={{ color: '#fff', fontSize: 18, fontFamily: 'Raleway' }}>"{quote}"</p>
                                                <p style={{ color: '#fff', fontSize: 12, marginBottom: 0, fontFamily: 'Raleway' }}>-{author ? author : 'Unknown'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                :
                                <Col lg={6} md={6}>
                                    <div className="me-3 position-relative" style={{ width: 315, height: 560 }}>
                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                            onClick={(e) => imageInput.current.click(e)}
                                        >
                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            >
                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                            <input type="file" id="file"
                                                ref={imageInput}
                                                onChange={(e) => onChangeFile(e, 'file')}
                                                style={{ display: "none" }} />
                                        </div>
                                    </div>
                                </Col>
                            }
                            <Col lg={6} md={6}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                    ref={formRef}
                                >
                                    {role !== "General" &&
                                        <Col md={12}>
                                            <div className="form-group mb-4">
                                                <Label>Date of Event *</Label><br></br>
                                                <DatePicker
                                                    className='form-control'
                                                    style={{ width: '100%' }}
                                                    selected={date}
                                                    onChange={(date) => setDate(date)}
                                                    dateFormat="dd/MMM"
                                                />
                                            </div>
                                        </Col>
                                    }
                                    {role == "Country" &&
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="country"
                                                label="Country *"
                                                type="select"
                                                className="form-control"
                                                value={data?.country}
                                            >
                                                <option value="">Select Country</option>
                                                {countries?.map((item) => (
                                                    <option value={item?.name}>{item?.name}</option>
                                                ))}
                                            </AvField>
                                        </div>
                                    }
                                    <Col md={12}>
                                        <div className="form-group mb-4">
                                            <Label>Tags *</Label>
                                            <Multiselect
                                                options={tags} // Options to display in the dropdown
                                                selectedValues={selectedTags} // Preselected value to persist in dropdown
                                                onSelect={(list, item) => setSelectedTags(list)} // Function will trigger on select event
                                                onRemove={(list, item) => setSelectedTags(list)} // Function will trigger on remove event
                                                displayValue="title" // Property name to display in the dropdown options
                                            />
                                            {/* <AvField
                                                name="tagId"
                                                label="Tag *"
                                                className="form-control"
                                                type="select"
                                                value={data?.tagId}
                                                required
                                            >
                                                <option value={""} >Select Tag</option>
                                                {tags?.map((item) => (
                                                    <option value={item?._id} >{item?.title}</option>
                                                ))}
                                            </AvField> */}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="author"
                                                label="Author *"
                                                value={author}
                                                onChange={(e) => setAuthor(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter author (default is unknown)"
                                                type="text"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="quote"
                                                label="Quote*"
                                                value={quote}
                                                onChange={(e) => setQuote(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter Quote"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    {/* <Label>Alternative Languages</Label> */}
                                    <Row>
                                        <div className='lang-checks mb-3'>
                                            {user?.languages?.map((item) => (
                                                <div className='check-group'>
                                                    <label for={item?.title} class="container">
                                                        {item?.title}
                                                        <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {selectedLang?.map((item, index) => (
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={"author" + item?.lang}
                                                            label={"Author in " + item?.lang}
                                                            className="form-control"
                                                            placeholder={"Enter author in " + item?.lang}
                                                            type="text"
                                                            onChange={(e) => handleAlterTitle(index, "author", e.target.value)}
                                                            required
                                                            value={item?.author}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name={"quote" + item?.lang}
                                                            label={"Quote in " + item?.lang}
                                                            className="form-control"
                                                            placeholder={"Enter quote in " + item?.lang}
                                                            type="textarea"
                                                            rows={4}
                                                            onChange={(e) => handleAlterTitle(index, "quote", e.target.value)}
                                                            required
                                                            value={item?.quote}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Row>
                                    <Col lg={12} md={12}>
                                        <div className="text-center mt-4">
                                            {data ?
                                                <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                    Update {role}
                                                </Button>
                                                :
                                                <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                                    Add {role}
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </AvForm>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
