import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
import Utils from '../../Utility';
import { post, get, put, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import UserProfile from "../../../components/UserProfile";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading, loading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [replyModal, setReplyModal] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(undefined);
  const [descContent, setDescContent] = useState(null);
  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/podcast/request/list", { token: user?.token })
        .then(json => {
          setLoading(false);
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setData(json?.data);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        })
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'User',
        cell: (row) => <>
          <div onClick={() => handleViewUser(row?.user[0]?._id)}
            style={{ cursor: "pointer", width: 42, height: 42, border: `2px solid ${Utils.themeColor}`, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 42, marginRight: 5 }}>
            <img title={'image'} src={row?.user[0]?.profile_picture} style={{ width: 38, borderRadius: 38, height: 40, marginTop: 5, marginBottom: 5 }} />
          </div>
          <span style={{ cursor: "pointer" }} onClick={() => handleViewUser(row?.user[0]?._id)}>{row?.user[0]?.name}</span>
        </>,
        selector: row => row._id,
        maxWidth: '200px'
      },
      {
        name: 'About Podcast',
        cell: (row) => <>
          <span title={row?.desc}>{row?.desc?.length < 200 ? row?.desc : row?.desc.substring(0, 410) + "..."}</span>
        </>,
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => handleReply(row)}
            title={"Reply"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={"mdi mdi-mail"} style={{ color: Utils.textColor }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
      }
    ])
  }, [data]);

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleViewUser = (id) => {
    setCurrentUserId(id);
    setUserModal(true);
  }

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleReply = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    setReplyModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      communityId: currentData?._id,
      token: user?.token
    }
    let url = "/community";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setDesc(html);
    setDescContent(editorState);
  }

  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    inline: {
      inDropdown: true,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Courier New', 'Comic Sans MS', 'Lucida Console', 'Montserrat'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  };

  const handleReplySubmit = () => {
    if (!loading) {
      setLoading(true);
      let body = {
        name: currentData?.user[0]?.name,
        email: currentData?.user[0]?.email,
        desc,
        token: user?.token
      }
      post("/podcast/request/reply", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setReplyModal(false);
            setDesc("");
            setDescContent(null);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.error("error while reply to podcast reply", err);
          setLoading(false);
          toast.error("Something Went Wrong!");
        })
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <UserProfile
            userId={currentUserId}
            show={userModal}
            onCloseClick={() => setUserModal(false)}
            user={user}
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              {replyModal ?
                <CardBody>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        Reply {role} from <code> {currentData?.user[0]?.name}</code>.
                      </CardSubtitle>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label>Description*</label>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(e) => onEditorStateChange(e)}
                      editorState={descContent}
                      toolbar={toolbarOptions}
                    />
                  </div>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setReplyModal(false)}>
                      Cancel
                    </Button>
                    <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none', marginLeft: 20 }} onClick={handleReplySubmit}>
                      Reply
                    </Button>
                  </div>
                </CardBody>
                :
                <CardBody>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {role},<code> Anybody</code> can <code>request</code> for their episod. Only if they don't have any <code>panding request.</code> here. So keep maintaining <code>Status</code> as Rejected or Uploaded.
                      </CardSubtitle>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[100, 200, 300, 400]}
                    conditionalRowStyles={[{
                      when: row => row?.style,
                      style: row => ({ width: row?.style?.width }),
                    },
                    ]}
                    customStyles={{
                      headCells: {
                        style: {
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: 15,
                          width: 0
                        },
                      },
                      cells: {
                        style: {
                          width: 0
                        }
                      }
                    }}
                  />
                </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
