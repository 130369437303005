import React, { useEffect, useState } from 'react';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import "./style.css";
import moment from 'moment';

function UserProfile(props) {
    console.log('props on profile', props);
    const { userId, show, onCloseClick, user } = props;
    const [userData, setUserData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId)
            getUserData();
    }, [userId]);

    const getUserData = () => {
        if (!loading) {
            setLoading(true);
            post("/user/detail", { userId, token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setUserData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.log('error while getting user', err);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody className="py-3 px-5">
                <h5 style={{ textAlign: 'center', marginBottom: 20, marginTop: 20 }}><b>User Details</b></h5>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col md={4}>
                        <div className='profile_container'>
                            <img className='profile_image' style={{ objectFit: 'cover' }} src={userData?.profile_picture ? userData?.profile_picture : require("../../assets/images/om.png")} />
                        </div>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <p className='value mb-0'>{userData?.name}</p>
                        <p className='value mb-0'>{userData?.email}</p>
                        <p className='value'>{userData?.mobile}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className='mt-5'>
                        <span className='key'>Device : </span>
                    </Col>
                    <Col md={6} className='mt-5'>
                        <span className='value' style={{ textTransform: 'capitalize' }}>{userData?.deviceType}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Country : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.country}</span>
                    </Col>
                    {userData?.trans?.length ?
                        <>
                            <Col md={6} className='mt-1'>
                                <span className='key'>Plan Expiry : </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{moment(userData?.expiry).format("DD-MMM-YYYY")}</span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='key'>Current Plan : </span>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <span className='value'>{userData?.trans[0]?.title}</span>
                            </Col>
                        </>
                        : null}
                </Row>
                {userData?.trans?.length ?
                    <>
                        <h5 style={{ textAlign: 'center', marginBottom: 20, marginTop: 40 }}><b>Transactions</b></h5>
                        <Row>
                            {userData?.trans?.map((item) => (
                                <>
                                    <Col md={6}>
                                        <span className='key'>Plan : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.title}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Duration : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.duration} Days</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Currency : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.currency}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Price : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.currency == "USD" ? item?.priceUSD : item?.price}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Amount Paid : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.amountPaid}</span>
                                    </Col>
                                    {item?.couponId ?
                                        <>
                                            <Col md={6}>
                                                <span className='key'>Coupon : </span>
                                            </Col>
                                            <Col md={6} className='mt-1'>
                                                <span className='value'>{item?.txnId}</span>
                                            </Col>
                                        </>
                                        : null}
                                    <Col md={6}>
                                        <span className='key'>Status : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{item?.status}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Expiry : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{moment(item?.expiry).format("DD-MMM-YYYY")}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className='key'>Transaction Date : </span>
                                    </Col>
                                    <Col md={6} className='mt-1'>
                                        <span className='value'>{moment(item?.createdAt).format("DD-MMM-YYYY")}</span>
                                    </Col>
                                    {item?.txnId ?
                                        <>
                                            <Col md={6}>
                                                <span className='key'>Txn ID: : </span>
                                            </Col>
                                            <Col md={6} className='mt-1'>
                                                <span className='value'>{item?.txnId}</span>
                                            </Col>
                                        </>
                                        : null}
                                    <hr className='mb-3 mt-3'></hr>
                                </>
                            ))}
                        </Row>
                    </>
                    : null}
            </ModalBody>
        </Modal>
    )
}
const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(UserProfile)
);