import moment from "moment";
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

const LineColumnArea = ({ data }) => {
  // console.log("data on subsChart", data);
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Renewed",
      type: "area",
      data: [],
    },
    {
      name: "Expired",
      type: "area",
      data: [],
    },
  ]);

  useEffect(() => {
    if (data?.length) {
      let tempRenewed = [], tempExpired = [], tempLabel = [];
      data?.forEach((item, index) => {
        tempRenewed.push(item?.renewed);
        tempExpired.push(item?.notRenewed);
        tempLabel.push(moment(`${item?.month}/01/${item?.year}`).format("MMM"))
      });
      setSeries([
        {
          name: "Renewed",
          type: "area",
          data: tempRenewed
        },
        {
          name: "Expired",
          type: "area",
          data: tempExpired
        },
      ]);
      setLabels(tempLabel);
    }
  }, [data]);

  const options = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    colors: ['#45cb85', '#eeb902', '#3b5de7'],
    series: [
      {
        name: 'Renewed',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
      }, {
        name: 'Expired',
        type: 'area',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
      }],
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: labels,
    markers: {
      size: 0
    },

    xaxis: {
      // type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Subscribers',
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Users";
          }
          return y;

        }
      }
    },
    grid: {
      borderColor: '#f1f1f1'
    }
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      className="apex-charts"
    />
  )
}

export default LineColumnArea
