// import axios from "axios";
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken
import Utils from "../pages/Utility";
import AWS from "aws-sdk";
const API_URL = Utils.API_URL;

export async function get(url, data) {
  // return await axiosApi.get(url, { ...config }).then(response => response.data)
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())

}

export async function download(url, data) {
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.blob())
}

export async function post(url, data) {
  console.log('post method req', url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
  // .post(API_URL+url, { ...data }, { ...config })
  // .then(response => response.data)
}

export async function put(url, data) {
  console.log('put method req', url, data)
  return fetch(API_URL + url, {
    method: 'PUT',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function upload(url, data) {
  console.log('upload method req', API_URL + url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    body: data
  }).then(res => res.json())
  // const response = await axios.post(API_URL + url, data, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   },
  //   onUploadProgress: (progressEvent) => {
  //     console.log("uploading file", progressEvent);
  //     // Update progress state based on progressEvent.loaded and progressEvent.total
  //     const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //     if (setProgress)
  //       setProgress(progress);
  //   },
  // });
  // console.log("response from upload", response);
  // return response.data;
  // return new Promise((resolve, reject) => {
  //   const xhr = new XMLHttpRequest();
  //   // xhr.config({"Content-Type":"multipart/form-data"})
  //   xhr.open('POST', API_URL + url, false);
  //   xhr.upload.addEventListener('progress', (event) => {
  //     console.log("progress", event);
  //     if (event.lengthComputable) {
  //       const progress = (event.loaded / event.total) * 100;
  //       if (setProgress)
  //         setProgress(progress);
  //     }
  //   });
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState === XMLHttpRequest.DONE) {
  //       if (xhr.status === 200) {
  //         resolve(JSON.parse(xhr.responseText));
  //       } else {
  //         reject(xhr.statusText);
  //       }
  //     }
  //   };
  //   xhr.onerror = () => {
  //     reject(new Error('Network error occurred'));
  //   };
  //   xhr.send(data);
  // });
}

export async function del(url, data) {
  return fetch(API_URL + url, {
    method: 'DELETE',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function uploadS3(folder, data, type, onProgress) {
  // console.log("process.env ", process.env.REACT_APP_AWS_ACCESS_KEY_ID, process.env.REACT_APP_AWS_SECRET_KEY, process.env.REACT_APP_AWS_REGION);
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Body: data,
    Key: process.env.REACT_APP_AWS_S3_FOLDER_NAME + folder + "/" + Date.now() + "." + type,
    ACL: 'public-read'
  };
  console.log("bucket params", params);
  const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
  const managedUpload = s3.upload(params, options);
  managedUpload.on('httpUploadProgress', (progressData) => {
    const percentUploaded = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    onProgress(percentUploaded);
  });
  const resp = await managedUpload.promise();
  console.log('response from s3', resp);
  if (resp)
    return {
      statusCode: 200,
      data: resp.Location,
      message: "SUCCESS"
    }
  else throw "error with img";
}
