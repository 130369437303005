import React, { useState, useEffect } from "react"
import { Doughnut } from "react-chartjs-2"

const DountChart = ({ data }) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (data?.length) {
      let tempLab = [], tempVal = [];
      data?.forEach(element => {
        tempLab.push(element?.title + ` (${element?.trans})`);
        tempVal.push(element?.trans);
      });
      setLabels(tempLab);
      setValues(tempVal);
    }
  }, [data])

  const series = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#556ee6", "#ff715b"],
        hoverBackgroundColor: ["#556ee6", "#ff715b"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const options = {
    legend: {
      display: true,
      position: 'right', // Positioning the legend to the right
      labels: {
        boxWidth: 20,
        padding: 15,
      },
    },
  };

  return <Doughnut width={474} height={260} data={series} options={options} />
}

export default DountChart
