import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment'

const Moods = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);


  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/harmony/cat/list", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200)
            setData(res?.data);
          else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        })
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        name: 'Sounds',
        cell: (row) => <>
          <span title={row?.sound?.length}>{row?.sound?.length ? row?.sound?.length : "0"}</span>
        </>,
        // selector: row => row.body.length > 30 ? row.body.substring(0, 30) + '...' : row.body,
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '120px'
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          {user?.role == 'Admin' ?
            <>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[6]?.harmony?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[6]?.harmony?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
    ])
  }, [data]);

  const handleValidSubmit = (e, v) => {
    let body = {
      ...v,
      alterLang: selectedLang,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, catId: currentData?._id }
      put("/harmony/cat/update", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      post("/harmony/cat/add", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    }
  }

  const handleUpdateProj = (row) => {
    setCurrentData(row);
    if (row?.alterLang?.length)
      setSelectedLang(row?.alterLang);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...row, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      catId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/harmony/cat/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      catId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    let url = `/harmony/cat/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/harmony/cat/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleSelectLang = (code, lang) => {
    if (code != "en") {
      let temp = Object.assign([], selectedLang);
      let already = temp.findIndex(x => x.code == code);
      if (already == -1) {
        temp.push({ code: code, title: "", lang });
      } else {
        temp.splice(already, 1);
      }
      // console.log("temp", temp);
      setSelectedLang(temp);
    }
  }

  const handleAlterTitle = (index, key, val) => {
    let temp = Object.assign([], selectedLang);
    temp[index][key] = val;
    setSelectedLang(temp);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Harmony Sound Categories" />
        <Modal isOpen={isAddForm} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData ? `Update Category!` : `Add New Category!`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v, 'bank')
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label="Title"
                        className="form-control"
                        placeholder="Enter category title"
                        type="text"
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                </Row>
                <Label>Alternative Languages</Label>
                <Row>
                  <div className='lang-checks mb-3'>
                    {user?.languages?.map((item) => (
                      <div className='check-group'>
                        <label for={item?.title} class="container">
                          {item?.title}
                          <input type="checkbox" name={item?.title} id={item?.title} checked={selectedLang.findIndex(x => x?.code == item?.code) != -1 || item?.code == "en"} onChange={(e) => handleSelectLang(item?.code, item?.title)} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  {selectedLang?.map((item, index) => (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name={"title" + item?.lang}
                            label={"Title in " + item?.lang}
                            className="form-control"
                            placeholder={"Enter title in " + item?.lang}
                            type="text"
                            onChange={(e) => handleAlterTitle(index, "title", e.target.value)}
                            required
                            value={item?.title}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setIsAddForm(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">

                <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">Category</CardTitle>
                    <CardSubtitle className="mb-3">
                      All sound categories you have added before.
                    </CardSubtitle>
                  </div>
                  <div className="group_btn_box">
                    {user?.role == 'Admin' ?
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                      :
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        {user?.permissions?.length && user?.permissions[6]?.harmony?.write &&
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        }
                      </div>
                    }
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={100}
                          paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                maxWidth: 80
                              },
                            },
                            cells: {
                              style: {
                                maxWidth: 50
                              }
                            }
                          }}
                          conditionalRowStyles={[{
                            when: row => row?.seen?.length < 1,
                            style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                          }]}
                        // onRowClicked={(r, e) => seenNoti(r)}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Moods)
)