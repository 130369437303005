import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { Spinner } from 'reactstrap';
const loading = require('../../assets/images/loading.gif');

const Loader = (props) => {
    if (props.visible)
    // if (true)
        return (
            <div style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff', zIndex: 1051, top: 0, display: 'flex', position: 'fixed', left: 0 }}>
                {/* <BallTriangle
                height={100}
                width={150}
                radius={5}
                color="#324fc4"
                secondaryColor= '#4fa94d'
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle={{}}
                visible={true}
            /> */}
                <Spinner className="m-1 loader_spiner" color="success" style={{color:'#fe6303'}} />
                {/* <img src={loading} style={{ height: 100, width: 100, backgroundColor: 'transparent' }} /> */}
            </div>
        )
    else
        return null;
}

export default Loader;