import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, uploadS3 } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, setLoading } = props;
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [mainStatus, setMainStatus] = useState(false);
  const [error, setError] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState("");
  const [dropData, setDropData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [cats, setCats] = useState([]);

  useEffect(() => {
    // console.log('props on user table', props)
    if (user?.token) {
      getData();
      get("/category", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setCats(res?.data);
        })
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setImg(null);
    }
  }, [isAddForm]);

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 30, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: '150px'
      },
      {
        name: 'Navigate To',
        selector: row => row?.isNavigate == 'Yes' ? row?.navigationType == 'internalPage' ? row?.screenName : row?.link : "No",
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          {user?.role == 'Admin' ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[1]?.popup?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[1]?.popup?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  useEffect(() => {
    if (selectedScreen == "kahaniDetail") {
      get("/story/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
    if (selectedScreen == "VratDetail") {
      get("/calendar/event/drop", { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200)
            setDropData(res?.data);
        })
    }
  }, [selectedScreen])

  const getData = () => {
    let url = "/popup/list/admin";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
          setMainStatus(json?.status);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
    get("/screen/list", { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200)
          setScreens(res?.data);
      })
  }

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsNavigate(proj?.isNavigate);
    setNavigationType(proj?.navigationType)
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      popupId: currentData?._id,
      token: user?.token
    }
    let url = "/popup/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
      let image = img;
      if (file.substring(0, 4) == "blob") {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
        const imageRes = await uploadS3("popup", obj, "webp", onProgress);
        setProgress(0);
        // const temp = await resizeFile(obj);
        // const form = new FormData();
        // form.append("image", obj);
        // const imageRes = await upload("/popup/image_upload", form);
        image = imageRes?.data;
      }
      const body = {
        ...v,
        token: user?.token,
        image,
        popupId: currentData?._id
      }
      put("/popup/update", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setIsNavigate("");
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      if (file) {
        let image = "";
        setLoading(true);
        try {
          const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
          const obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
          // const temp = await resizeFile(obj);
          // const form = new FormData();
          // form.append("image", obj);
          const imageRes = await uploadS3("popup", obj, "webp", onProgress);
          setProgress(0);
          image = imageRes?.data;
        } catch (error) {
          console.log('error while uploading image', error);
          setLoading(false);
          return;
        }
        const body = {
          ...v,
          image,
          token: user?.token
        }
        post("/popup/add", body)
          .then(res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.messgae);
              setIsAddForm(false);
              getData();
            } else {
              toast.error(res?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            toast.error('Something Went Wrong!');
            console.log('error while adding banner', error);
          })
      } else
        toast.error('Please choose a banner image!')
    }
  }

  const handleMainStatus = (val) => {
    put("/setting/update", { isPopup: val, token: user?.token })
      .then(res => {
        console.log('res from banner update', res);
        setMainStatus(res?.data?.isPopup);
      })
      .catch(err => {
        console.log('error while updating banner height', err);
      })
  }

  const handleStatusProj = (item) => {
    props?.setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/popup/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const onProgress = (p) => {
    setProgress(p);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} toggle={() => setIsAddForm(!isAddForm)} centered={true} style={{ maxWidth: 540 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                {file ?
                  <>
                    {img ?
                      <>
                        <img
                          src={img}
                          alt=""
                          style={{ width: 360, height: 'auto' }}
                        // onClick={(e) => imageInput.current.click(e)}
                        />
                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                          onClick={() => { setFile(null); setImg("") }}
                        >
                          <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                        </div>
                      </>
                      :
                      <Cropper
                        style={{ height: 'auto', width: 500 }}
                        // aspectRatio={712 / 1011}
                        preview=".img-preview"
                        guides={true}
                        src={file}
                        ref={cropper}
                      />
                    }
                  </>
                  :
                  <div style={{
                    position: 'relative',
                    width: 500,
                    height: 320,
                    border: '1px dashed ' + Utils.themeColor,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Utils.themeColor,
                    fontSize: 22
                  }} onClick={(e) => imageInput.current.click(e)}>
                    <span
                      style={{
                        padding: 5,
                        paddingLeft: 15, paddingRight: 15,
                        border: '1px solid ' + Utils.themeColor,
                        borderRadius: 5
                      }}
                    >Choose Image</span>
                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                    >
                      <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                    </div>
                  </div>
                }
                <input type="file" id="file"
                  ref={imageInput}
                  onChange={onChangeFile}
                  style={{ display: "none" }} />
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvField
                          name="isNavigate"
                          label={"Navigation"}
                          value={isNavigate}
                          className="form-control"
                          type="select"
                          required
                          onChange={e => setIsNavigate(e.target.value)}
                        >
                          <option value="">Select Navigation</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </AvField>
                      </div>
                    </Col>
                    {isNavigate == 'Yes' &&
                      <Col md={12}>
                        <div className="form-group mb-4">
                          <AvField
                            name="navigationType"
                            label={"Select Navigation Type"}
                            value={currentData?.navigationType}
                            className="form-control"
                            type="select"
                            onChange={(e) => setNavigationType(e.target.value)}
                            required
                          >
                            <option value="">Select Navigation Type</option>
                            <option value="internalPage">Internal App Screen</option>
                            <option value="inApp">In App Link</option>
                            <option value="outApp">External Web Link</option>
                          </AvField>
                        </div>
                      </Col>
                    }
                    {isNavigate == 'Yes' &&
                      <>
                        {navigationType == 'internalPage' ?
                          <>
                            <Col md={12}>
                              <div className="form-group mb-4">
                                <AvField
                                  name="screenName"
                                  label={"Select Navigation Type"}
                                  value={currentData?.screenName}
                                  className="form-control"
                                  type="select"
                                  required
                                  onChange={(e) => setSelectedScreen(e.target.value)}
                                >
                                  <option value="">Select App Screen</option>
                                  {screens?.map((item) => (
                                    <option value={item?.for}>{item?.title}</option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                            {selectedScreen == "ContentList" ?
                              <Col md={12}>
                                <div className="form-group mb-4">
                                  <AvField
                                    name="link"
                                    label={"Select Category"}
                                    value={data?.link}
                                    className="form-control"
                                    type="select"
                                    required
                                  >
                                    <option value="">Select Category</option>
                                    {cats?.map((item) => (
                                      <option value={item?._id}>{item?.title}</option>
                                    ))}
                                  </AvField>
                                </div>
                              </Col>
                              : null}
                          </>
                          :
                          <Col lg={12} md={12}>
                            <div className="form-group mb-4">
                              <AvField
                                name="link"
                                label={"Web URL"}
                                value={currentData?.link}
                                className="form-control"
                                placeholder={`Enter Url you need to open`}
                                type="url"
                                required
                              />
                            </div>
                          </Col>
                        }
                      </>
                    }
                    <div className="text-center mt-4">
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update {role}
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add {role}
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  {role} you have added before, All the <code>{role} </code>will reflect on your <code>Website and App.</code>
                </CardSubtitle>
              </div>
              <div className="group_btn_box">
                {user?.role == "Admin" ?
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                      Add
                    </Button>
                  </div>
                  :
                  <>
                    {user?.permissions?.length && user?.permissions[1]?.popup?.write ?
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                      : null}
                  </>
                }
              </div>
            </div>
            <Row>
              {/* {data?.map((item, index) => (
                    <Col lg={6} className="banner">
                      <div style={{ width: 'auto', height: bannerHeight ? bannerHeight : 360, display: 'flex' }}>
                        <img src={item?.image} style={{ width: 'auto', height: '100%' }} />
                        <div style={{ padding: 20, flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <p>Is Navigate : {item?.isNavigate}</p>
                            {item?.navigationType &&
                              <p>Navigation Type : {item?.navigationType}</p>
                            }
                            {item?.screenName &&
                              <p>Screen Name : {item?.screenName}</p>
                            }
                            {item?.link &&
                              <p>Link : {item?.link}</p>
                            }
                          </div>
                          <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div>
                              <div className="toggle-switch">
                                <input
                                  type="checkbox"
                                  className="toggle-switch-checkbox"
                                  name="toggleSwitch"
                                  id={"popup" + index}
                                  onChange={(e) => handleStatusProj(item)}
                                  checked={item.isActive}
                                />
                                <label className="toggle-switch-label" htmlFor={"popup" + index}>
                                  <span className="toggle-switch-inner" />
                                  <span className="toggle-switch-switch" />
                                </label>
                              </div>
                            </div>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => handleUpdateProj(item)}>
                              <i className="mdi mdi-pencil" style={{ color: 'white', fontSize: 20, cursor: 'pointer' }} />
                            </Button>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => handleDeleteProj(item)}>
                              <i className="mdi mdi-delete" style={{ color: 'white', fontSize: 20, marginTop: 5, cursor: 'pointer' }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))} */}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{
                          headCells: {
                            style: {
                              color: 'black',
                              fontWeight: 'bold',
                              fontSize: 15,
                              maxWidth: 80
                            },
                          },
                          cells: {
                            style: {
                              maxWidth: 50
                            }
                          }
                        }}
                        conditionalRowStyles={[{
                          when: row => row?.seen?.length < 1,
                          style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                        }]}
                      // onRowClicked={(r, e) => seenNoti(r)}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>

          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
