import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
import Utils from '../Utility';
import { post, get, put, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment/moment";
import { CSVLink } from 'react-csv';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading, loading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [positionModal, setPositionModal] = useState(false);
  const [cats, setCats] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [currentDate, setCurrentDate] = useState(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  });
  const [selectedCat, setSelectedCat] = useState("");
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (user) {
      if (cats?.length < 1)
        getCat();
      else {
        // let id = localStorage.getItem("cat");
        // if (id)
        //   setSelectedCat(id);
      }
    }
  }, [user]);

  const getCat = () => {
    get("/category", { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setCats(res?.data);
          if (res?.data?.length) {
            // let id = localStorage.getItem("cat");
            // if (id)
            //   setSelectedCat(id);
            // else
            //   setSelectedCat(res?.data[0]?._id);
          }
        }
      })
  }

  useEffect(() => {
    // if (selectedCat)
    getData();
  }, [selectedCat, user]);

  const getData = () => {
    if (!user?.token)
      return;
    setLoading(true);
    get(`/content/list?catId=${selectedCat}&search=${search}`, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        setLoading(false);
        if (json?.statusCode == 200) {
          setData(json?.data);
          if (selectedCat)
            localStorage.setItem("cat", selectedCat);
        } else {
          setData([]);
        }
      })
      .catch(error => {
        setData([]);
        setLoading(false);
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      productId: id,
      isPopular: val == "true" ? false : true,
      token: user?.token
    }
    put("/product/update", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "S.N",
        selector: (row, index) => index + 1,
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: "Position",
        selector: row => row?.position,
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 15, color: Utils.themeColor, marginRight: 10 }}></i>
              </div>
            )}
          </Draggable>
          <span style={{ cursor: 'pointer' }} onClick={() => { setCurrentData(row); setPositionModal(true) }}>{row?.position}</span>
        </>,
        sortable: true,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'banner'} src={row?.image} style={{ width: 50, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        selector: row => row._id,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        cell: (row) => <>
          <span>{row?.title}</span>
        </>,
        selector: row => row?.title,
      },
      {
        name: '$ type',
        cell: (row) => <>
          <span>{row?.isPaid ? "Paid" : "Free"}</span>
        </>,
        selector: row => row?.isPaid,
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: 'Created At',
        selector: row => row?.createdAt,
        cell: (row) => <>
          <span>{moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a")}</span>
        </>,
        sortable: true,
        maxWidth: "170px"
      },
      {
        name: 'Tag',
        selector: row => row?.tag,
        cell: (row) => <>
          <Button onClick={() => handleUpdateTag(row)}
            title={row?.tag}
            style={{ backgroundColor: 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.tag ? row?.tag : " - "}
            </span>
          </Button>
        </>,
        sortable: true,
        maxWidth: '120px'
      },
      {
        name: 'Type',
        selector: row => row?.type,
        sortable: true,
        maxWidth: '50px'
      },
      {
        name: 'Category',
        selector: row => row?.category[0]?.title,
        sortable: true,
        maxWidth: '100px'
      },
      {
        name: 'Open',
        selector: row => 0,
        sortable: true,
        maxWidth: '50px'
      },
      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        selector: row => row?.isActive,
        ignoreRowClick: true,
        sortable: true,
        maxWidth: '108px'
      },
      {
        cell: (row) => <>
          <Button
            className="table_action_btn"
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
            onClick={() => window.open(`https://thebeyondlifestyle.com/app/${parseTitle(row?.category[0]?.title)}/${parseTitle(row?.title)}`)}>
            <i className="mdi mdi-eye" />
          </Button>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handlePaid(row)}
                title={"Paid"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[10]?.content?.update &&
                <Button className="table_action_btn" onClick={() => handlePaid(row)}
                  title={"Paid"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className={row?.isPaid ? "mdi mdi-currency-usd" : "mdi mdi-currency-usd-off"} style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[10]?.content?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[10]?.content?.delete &&
                <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '145px'
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  const handlePaid = (row) => {
    setCurrentData({ ...row, actionType: "Paid" });
    setDeleteModal(true);
  }

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...item, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      contentId: currentData?._id,
      token: user?.token
    }
    let url = "/content";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/content/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleValidSubmit = (e, v) => {
    let swapId = data?.find(x => x?.position == Number(v?.position));
    let ids = [currentData?._id, swapId?._id];
    let position = [Number(v?.position), currentData?.position];
    console.log("values", ids, position);
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/content/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        getData();
        setPositionModal(false);
      })
  }

  const handleValidSubmitTag = (e, v) => {
    let body = {
      contentId: currentData?._id,
      tag: v?.tag,
      token: user?.token
    }
    put("/content", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          getData();
          setTagModal(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log("error while updating tag", err);
      })
  }

  const handleUpdateTag = (row) => {
    setCurrentData(row);
    setTagModal(true);
  }

  const handleValidFilter = (e, v) => {
    console.log("values on filter", v);
    if (!loading) {
      setLoading(true)
      get(`/content/list?type=${v?.type}&dateFrom=${v?.dateFrom}&dateTo=${v?.dateTo}&cats=${v?.cats}&payment=${v?.payment}`, { token: user?.token })
        .then(json => {
          // console.log('response from get project list', json);
          setLoading(false);
          if (json?.statusCode == 200) {
            let filt = [];
            if (v?.type && v?.type?.length)
              filt.push({ title: "Content Type", items: v?.type });
            if (v?.payment && v?.payment?.length)
              filt.push({ title: "Payment Status", items: v?.payment });
            if (v?.cats && v?.cats?.length) {
              let catsName = [];
              v?.cats?.forEach(element => {
                let found = cats.find(x => x?._id == element);
                if (found)
                  catsName.push(found?.title);
              });
              filt.push({ title: "Categories", items: catsName });
            }
            if (v?.dateFrom)
              filt.push({ title: "Dates", items: [v?.dateFrom] });
            if (v?.dateTo) {
              let already = filt.findIndex(x => x?.title == "Dates");
              if (already == -1)
                filt.push({ title: "Dates", items: [v?.dateTo] });
              else
                filt[already].items.push(v?.dateTo);
            }
            setFilters(filt);
            setData(json?.data);
            setSearch("");
            setSelectedCat("");
            setSearchModal(false);
            setSearchApplied(true);
          } else {
            setData([]);
          }
        })
        .catch(error => {
          setData([]);
          setLoading(false);
          console.log('error while getting project list', error);
        })
    }
  }

  const parseTitle = (str) => {
    if (str) {
      str = str.trim();
      str = str.split(" ").join("-");
      return str
    }
    else
      return "";
  }

  const exportToCSV = () => {
    let csvData = [];
    csvData = data.map((row) => ({
      Title: row?.title,
      Paid: row?.isPaid ? "Paid" : "Free",
      "Created At": moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
      Tag: row?.tag ? row?.tag : " - ",
      Type: row?.type,
      Category: row?.category[0]?.title,
      Status: row?.isActive ? "Active" : "Inactive"
    }));
    return csvData;
  };
  // console.log("selected Cat changed", selectedCat);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={positionModal} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Update position</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleValidSubmit}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="position"
                          label="Position *"
                          className="form-control"
                          placeholder="Enter position"
                          type="number"
                          required
                          value={currentData?.position}
                          validate={{ min: { value: 0 } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setPositionModal(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={tagModal} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Update Tag</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleValidSubmitTag}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="tag"
                          label="Tag *"
                          className="form-control"
                          placeholder="Enter tag"
                          type="text"
                          value={currentData?.tag}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setTagModal(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Modal className="modal_form" isOpen={searchModal} toggle={() => { setSearchModal(false); setCurrentData(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Advance Search</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidFilter(e, v)
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <Label>Content Type</Label>
                        <AvCheckboxGroup inline name="type">
                          <AvCheckbox label="Info" value="Info" />
                          <AvCheckbox label="Text" value="Text" />
                          <AvCheckbox label="Audio" value="Audio" />
                          <AvCheckbox label="Video" value="Video" />
                        </AvCheckboxGroup>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <Label>Payment Status</Label>
                        <AvCheckboxGroup inline name="payment">
                          <AvCheckbox label="Paid" value="Paid" />
                          <AvCheckbox label="Unpaid" value="Unpaid" />
                        </AvCheckboxGroup>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <Label>Categories</Label>
                        <AvCheckboxGroup inline name="cats">
                          {cats?.map((item) => (
                            <AvCheckbox label={item?.title} value={item?._id} />
                          ))}
                          {/* <AvCheckbox label="Info" value="Info" />
                          <AvCheckbox label="Text" value="Text" />
                          <AvCheckbox label="Audio" value="Audio" />
                          <AvCheckbox label="Video" value="Video" /> */}
                        </AvCheckboxGroup>
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="dateFrom"
                          label="Date From"
                          className="form-control"
                          // placeholder="Enter something about your notification"
                          type="date"
                          // required
                          validate={{ max: { value: currentDate } }}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="dateTo"
                          label="Date To"
                          className="form-control"
                          // placeholder="Enter detailed notification"
                          type="date"
                          // required
                          validate={{ max: { value: currentDate } }}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              onClick={() => { setSearchModal(false); setCurrentData(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            >
                              Apply
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Row>
              <Col md={8} className="px-0">
                <div>
                  <CardTitle className="h4">{props.role} ({data?.length})</CardTitle>
                  <CardSubtitle className="mb-3">
                    {role} you have added before,<code> Anybody</code> can <code>check</code> and <code>visit</code> on these. if <code>Status</code> is Active.
                  </CardSubtitle>
                </div>
              </Col>
              <Col md={4} className="px-0">
                <div className="group_btn_box">
                  <Button
                  >
                    <CSVLink
                      data={exportToCSV(data)}
                      filename={"content.csv"}
                      className="text-white"
                      target="_blank"
                    >
                      Export Contents ({data?.length})
                    </CSVLink>
                  </Button>
                  <Button
                    onClick={() => setSearchModal(true)}
                  >
                    Advance Search
                  </Button>
                  {user?.role == "Admin" ?
                    <div className="text-center" >
                      <Button className='btn-text-btn'
                        onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <div className="text-center" >
                      {user?.permissions?.length && user?.permissions[10]?.content?.write &&
                        <Button
                          onClick={() => props?.add(true)}>
                          Add
                        </Button>
                      }
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <AvForm className="mb-4">
                  <Row>
                    <Col md={2} className="px-0">
                      <div className="text-center" >
                        <AvField
                          name="category"
                          type="select"
                          onChange={(e) => setSelectedCat(e?.target?.value)}
                          value={selectedCat}
                        >
                          <option value="">All</option>
                          {cats?.map((item) => (
                            <option value={item?._id}>{item?.title}</option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col md={9}>
                      <AvField
                        name="search"
                        placeholder="Search"
                        onChange={e => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col md={1}>
                      <Button type="button" style={{ backgroundColor: Utils.themeColor, border: 'none', width: "100%" }} onClick={() => getData()}>
                        Search
                      </Button>

                    </Col>
                    {
                      filters?.length ?

                        <div className="mt-3">
                          <Row className="filter-box-section">
                            {filters.map((item) => (
                              <Col md={3}>
                                <Label>{item?.title}</Label>
                                <AvCheckboxGroup inline name="contentType" className="lable-text" value={item?.items}>
                                  {item?.items?.map((type) => (
                                    <AvCheckbox label={type} value={type} checked />
                                  ))}
                                </AvCheckboxGroup>
                              </Col>
                            ))}
                            {filters?.length ?
                              <div className="filter-reset-button">
                                <Button title=" Reset Filters" style={{ marginLeft: 10, backgroundColor: Utils.themeColor, marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}
                                  onClick={() => { setFilters([]); getData() }}
                                >
                                  <span class="mdi mdi-lock-reset"></span>
                                </Button>
                              </div>
                              : null}
                          </Row>
                        </div>
                        :
                        null
                    }
                  </Row>
                </AvForm>
              </Col>
            </Row>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      paginationPerPage={100}
                      paginationRowsPerPageOptions={[100, 200, 300, 400, 500]}
                      conditionalRowStyles={[{
                        when: row => row?.style,
                        style: row => ({ width: row?.style?.width }),
                      },
                      ]}
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            width: 0
                          },
                        },
                        cells: {
                          style: {
                            width: 0
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <audio id="audio" src={currentAudio} />
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
