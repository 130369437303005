import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions";
import { post } from '../../helpers/api_helper';
import { POST_LOGIN } from '../../helpers/url_helper';

// import images
import logo from "../../assets/images/logo-color.png";
import Loader from '../../components/Loader';
import Utils from '../Utility';
import { toast } from 'react-toastify';

const Login = (props) => {

  const { loading } = props;

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history);
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <Loader
        visible={loading}
      />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay">
                    <p style={{color:"#fff", fontSize:20, marginTop:30}}>The {Utils.projectName}</p>
                  </div>
                  <div className="position-relative">
                    <div style={{minHeight:80}}></div>
                    {/* <h5 className="text-black font-size-20"><b>Welcome Back !</b></h5> */}
                    {/* <p className="text-black mb-0"><b>Sign in to continue to {Utils.projectName}.</b></p> */}
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" style={{height:"29px"}} />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{backgroundColor:Utils.themeColor, border:'none'}}
                          type="submit"
                        >
                         Log In
                        </button>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i
                          className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                      </div> */}
                    </AvForm>
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center" style={{ backgroundColor: '#ffffff99', padding: 5, alignItems:'center' }}>
                {/* <p>Don't have an account ? <Link onClick={() => props.history.replace("/register", {})} */}
                  {/* className="fw-medium text-primary"> Signup now </Link> </p> */}
                <p>© {new Date().getFullYear()} The {Utils.projectName}
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}