import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import {
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import { profileSuccess } from "../profile/actions"

function* loginUser({ payload: { user, history } }) {

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password
      })
      console.log('response from login jwt', response);
      if (response.statusCode == 200) {
        localStorage.setItem("authUser", JSON.stringify(response?.data))
        yield put(loginSuccess(response?.data))
        yield put(apiError(null))
      } else
        throw (response?.error)
    }
    history.push("/dashboard")
  } catch (error) {
    console.error('error on login user', error);
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    yield put(loginSuccess(null));
    yield put(profileSuccess({ user: null }));
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
