import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, del, download } from "../../helpers/api_helper";
import DeleteModal from '../../components/ConfirmModal/DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
import defaultImage from '../../assets/images/brands/slack.png';
import moment from "moment";
// import './product.scss';

const Table = (props) => {
  const { user, role, from, setLoading, loading, currentData, setCurrentData, setFile, setThumb, setTag } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);


  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/blog", { token: user?.token })
        .then(json => {
          setLoading(false)
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setData(json?.data);
          } else {
            toast.error(json?.error);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
          toast.error('Something Went Wrong!');
        })
    }
  }

  useEffect(() => {
    setColumns([

      {
        name: "Thumb",
        cell: (row) => <>
          {/* <img src={row?.thumb} style={{ width: 70, height: 70, padding: 10 }} /> */}
          <img src={row?.thumb} style={{ width: 25, height: 25, padding: 0 }} />

        </>,
        maxWidth: '100px',
        // minWidth: '90px'
      },
      // {
      //   name: "Image",
      //   cell: (row) => <>
      //     {/* <img src={row?.images?.length ? row?.images[0] : ""} style={{ width: 60 }} /> */}
      //     <img src={row?.images?.length ? row?.images[0] : ""} style={{ width: 100, height: 70, padding: 10 }} />
      //   </>,
      //   maxWidth: '150px',
      //   minWidth: '100px'
      // },
      // {
      //   name: 'Image',
      //   cell: (row) => <>
      //     {/* <img title={'logo'} src={row?.image} style={{ width: 70, height: 'auto' }} /> */}
      //     <img src={row?.images !== "" ? row.images[0] : defaultProfilePic} style={{ width: 70, height: 70, padding: 10 }} />

      //   </>,
      //   maxWidth: '150px'
      // },
      {
        name: 'Images',
        cell: (row) => (
          <>
            <img
              src={row?.images?.length ? row.images[0] : defaultImage}
              alt=""
              style={{ width: 25, height: 25, padding: 0 }}
            />
          </>
        ),
        maxWidth: '110px',
        // minWidth: '95px'
      },
      {
        name: 'Title',
        // selector: row => row?.title,
        cell: (row) => <>
          <p>{row?.title}</p>
        </>
      },

      {
        cell: (row) => <>
          <Button className="status_btn" onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        maxWidth: '120px',
        ignoreRowClick: true,
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD MMM, YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              <Button className="table_action_btn" onClick={() => handleDelete(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[22]?.blog?.update &&
                <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[22]?.blog?.delete &&
                <Button className="table_action_btn" onClick={() => handleDelete(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        maxWidth: '100px',
        ignoreRowClick: true,
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (row) => {
    if (user?.role == "Admin" || user?.permissions?.length && user?.permissions[22]?.blog?.update) {
      setCurrentData({ ...row, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const handleDelete = (row) => {
    setCurrentData({ ...row, actionType: "Delete" });
    setDeleteModal(true);
  }

  const onStatus = () => {
    console.log('status', currentData);
    setLoading(true);
    let body = {
      blogId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/blog`;
    put(url, body)
      .then(json => {
        console.log('response from update status', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          setDeleteModal(false);
          getData();
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      blogId: currentData?._id,
      token: user?.token
    }
    del(`/blog`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json?.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const ExpandedComponent = ({ data }) =>
    <div className='expending_box'>
      <Row>
        <Col lg={4}>
          <div className='expend_detail'>
            <h5>Tags</h5>
          </div>
          <div className='expend_detail'>
            <h5>Sort Title</h5>
          </div>
        </Col>
        <Col lg={8}>
          <div className='expend_detail'>
            <p>{data?.allTags?.map((tag, index) => (
              <span key={index}>
                {tag.name}
                {index !== data.allTags.length - 1 && ", "}
              </span>
            ))}</p>
          </div>
          <div className="expend_detail">
            <p>{data?.titlesort}</p>
          </div>
        </Col>
      </Row>
    </div>

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
            // onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
            actionType={currentData?.actionType}
          />
          <Col className="col-12">

            <div className="d-flex justify-content-between" style={{ marginLeft: 'auto', marginBottom: 30 }}>
              <div>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  {/* {role} you have added before,<code> Anybody</code> can <code>check</code> and <code>visit</code> on these. if <code>Status</code> is Active. */}
                </CardSubtitle>
              </div>
              <div className="group_btn_box">
                {user?.role == "Admin" ?
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none', color: Utils.textColor }} onClick={() => { props?.add(true); setTag([]) }}>
                      Add
                    </button>
                  </div>
                  :
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {user?.permissions?.length && user?.permissions[22]?.blog?.write &&
                      <Button className='btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    }
                  </div>
                }
              </div>
            </div>
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={100}
              paginationRowsPerPageOptions={[100, 200, 300, 400]}
              expandableRows={true}
              expandOnRowClicked={false}
              expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={ExpandedComponent}
              expandableRowsComponentProps={row => row.Action}
              conditionalRowStyles={[{
                when: row => row?.style,
                style: row => ({ width: row?.style?.width }),
              },
              ]}
              customStyles={{
                headCells: {
                  style: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 15,
                    width: 0
                  },
                },
                cells: {
                  style: {
                    width: 0
                  }
                }
              }}
            />

          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
